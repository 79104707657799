import { animated } from "@react-spring/web";
import styled from "styled-components";

export const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
`;

export const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid var(--color-orange);
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: salaryInsuranceLoader 1s linear infinite;

  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid var(--color-orange);
    border-left: 4px solid transparent;
  }
`;

export const Title = styled.h3`
  text-transform: initial;
  margin-bottom: 45px;
  font-size: 28px;
  text-align: center;
  color: var(--color-orange);
`;
