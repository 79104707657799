import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background-color: var(--color-orange);
    margin-right: 5px;
    font-size: var(--font-size-body);
    font-weight: 700;
  }

  p {
    margin: 0;
    text-decoration: underline;
  }
`;
