import { Store } from "../store";
import { GetState, SetState, StateCreator } from "zustand";

export type CompanionFormEnrollmentSlice = {
  currentlyAseMember: boolean;
  hasUnemploymentbenifit: boolean;
  setHasUnemploymentbenifit: (hasUnemploymentbenifit: boolean) => void;
  setCurrentlyAseMember: (currentlyAseMember: boolean) => void;
};

export const companionFormEnrollmentInitialState = {
  currentlyAseMember: false,
  hasUnemploymentbenifit: false,
} as CompanionFormEnrollmentSlice;

export const createCompanionFormEnrollmentSlice: StateCreator<CompanionFormEnrollmentSlice, [], [], CompanionFormEnrollmentSlice> = (set) =>
  ({
    ...companionFormEnrollmentInitialState,
    setHasUnemploymentbenifit: (hasUnemploymentbenifit: boolean) => {
      set(() => ({
        hasUnemploymentbenifit,
      }));
    },
    setCurrentlyAseMember: (currentlyAseMember: boolean) => {
      set(() => ({
        currentlyAseMember,
      }));
    },
  } as CompanionFormEnrollmentSlice);
