import { useStore } from "../../../../store/store";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";
import Button from "../../../../components/Button";
import "dayjs/locale/da";
import dayjs from "dayjs";
import { Wrapper, Inner, Date, TrackTraceWrapper } from "./CompletedStep.styles";
import { useMemo } from "react";

export default function CompletedStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const trackAndTraceUrl = useStore.useTrackAndTraceUrl();

  const getProducts = () => {
    let products = [];

    if (payload.wantsUnemploymentBenefits) {
      products.push(t?.productsStep.unemploymentInsurance.title);
    }

    if (payload.wantsUnion) {
      products.push(t?.productsStep.union.title);
    }

    if (payload.wantsFamilyUnion) {
      products.push(t?.productsStep.familyUnion.title);
    }

    if (payload.wantsSalaryInsurance) {
      products.push("Lønsikring");
    }

    if (payload.wantsBasic) {
      products.push(t?.productsStep.basic.title);
    }

    if (payload.wantsHRJura) {
      products.push(t?.productsStep.hrjura.title);
    }

    return products.reduce((acc, cur, i) => {
      if (i + 1 === products.length && products.length > 1) {
        return acc + " og " + cur;
      } else {
        const seperator = acc ? ", " : "";
        return acc + seperator + cur;
      }
    }, "");
  };

  const showTrackAndTraceButton = useMemo(() => {
    return !!trackAndTraceUrl && (payload.occupation === "Employee" || payload.occupation === "Unemployed");
  }, [payload]);

  return (
    <Wrapper>
      <Inner>
        <h2>{t?.completedStep.title}</h2>
        <div style={{ textAlign: "center" }} />

        <p>Hej {payload.firstName}</p>
        <p className="WithMargin">{`Vi er glade, for du har valgt at blive medlem af vores ${getProducts()}.`}</p>
        <p>
          <b>Betalingsaftale</b>
        </p>
        <p className="WithMargin">{payload.paymentType === PaymentTypeEnum.BSPAYMENT ? `Tilmelding til Betalingsservice sker automatisk. Inden for to arbejdsdage kan du se aftalen i din Netbank eller i din Betalingsservice app. Du modtager bekræftelse og kvittering af dit medlemskab på ${payload.mailAddress}` : `Du har valgt betaling via MobilePay. Indenfor 2 arbejdsdage modtager du information til opsætning af din betalingsaftale, bekræftelse og kvittering af dit medlemskab på ${payload.mailAddress}`}</p>

        {payload.wantsUnemploymentBenefits && (
          <>
            <p>
              <b>Husk din første betaling.</b>
            </p>
            <p>Når vi har oprettet dit medlemskab, modtager du en notifikation om betaling af første rate i din MobilePay App.</p>
          </>
        )}

        <p>Dit medlemskab er aktivt fra:</p>
        <Date>
          <p>
            <b>{t?.summaryStep.enrollmentDate}</b>
          </p>
          <p style={{ textTransform: "capitalize" }}>{dayjs(payload.signupDate).isValid() && dayjs(payload.signupDate).locale("da").format("DD. MMM YYYY")}</p>
        </Date>

        {payload.wantsSalaryInsurance && (
          <>
            <p>
              <b>Ase Lønsikring</b>
            </p>
            <p className="WithMargin">Vi ringer dig op indenfor to arbejdsdage for at sammensætte den bedste lønsikring til dig.</p>
          </>
        )}

        {showTrackAndTraceButton && (
          <TrackTraceWrapper>
            <p style={{ paddingBottom: 20 }}>
              <b>På linket kan du se, hvor langt vi er med at behandle din indmeldelse.</b>
            </p>
            <Button href={trackAndTraceUrl}>Følg din indmeldelse</Button>
          </TrackTraceWrapper>
        )}

        <p>Venlig hilsen</p>
        <p>
          <b>Ase</b>
        </p>
      </Inner>
    </Wrapper>
  );
}
