
import gsap, { Power3, Power1 } from 'gsap'
import ScrollMagic from '../../libs/scrollmagic/uncompressed/ScrollMagic'

export default class YoutubeVideo {
    constructor(element) {
        this.video = element
        this.controller = new ScrollMagic.Controller({ loglevel: 2 });
        this.init()
    }

    init() {
        const elm = this.video
        const playButton = elm.querySelectorAll('.video-container__play');
        const videoID = elm.dataset.videoid;
        const videoWrapper = elm.querySelectorAll('.videoWrapper')[0];
        let youtubeVideo;
        this.loadPlayerApi();
        
        playButton[0].addEventListener('keypress', (e) => {
            let key = e.which || e.keyCode || 0;
            if (key === 13) {
                elm.className += " open";
                youtubeVideo = this.playVideo(elm, videoID, videoWrapper, youtubeVideo);
            }
        })

        playButton[0].onclick = () => {
            elm.className += " open";
            youtubeVideo = this.playVideo(elm, videoID, videoWrapper, youtubeVideo);
        };

        const videoSceen = new ScrollMagic.Scene({
            triggerElement: elm,
            duration: '80%'
        })
            .on('leave', function (event) {
                if (typeof youtubeVideo != "undefined") {
                    youtubeVideo.pauseVideo();
                }
            })
            .addTo(this.controller);
    }

    loadPlayerApi() {
        var tag = document.createElement('script');
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        tag.dataset.consentSrc = "https://www.youtube.com/iframe_api";
        tag.dataset.categoryConsent = "cookie_cat_marketing"
    }

    playVideo(videoParent, videoID, videoContainer, youtubeVideo) {
        let newYoutubePlayer;

        if (videoContainer.classList.contains('activevideo')) {

            this.openVideo(videoParent);
            youtubeVideo.playVideo();
            return youtubeVideo;
        } else {
            if (typeof YT === 'undefined') {
                return;  
            }
            newYoutubePlayer = new YT.Player(videoID, {
                height: '390',
                width: '640',
                autoplay: 1,
                videoId: videoID,
                events: {
                    'onReady': onPlayerReady
                }
            });

            function onPlayerReady(event) {
                event.target.playVideo();
            }

            videoContainer.className += " activevideo";
            this.openVideo(videoParent, videoContainer);
            return newYoutubePlayer;
        }
    }

    stopVideo(videoParent, youtubeVideo) {
        youtubeVideo.pauseVideo();
        this.closeVideo(videoParent);
    }

    openVideo(videoParent, videoWrapper) {
        const playButton = videoParent.querySelectorAll('.video-container__play')[0];
        const closeButton = videoParent.querySelectorAll('.video-container__close')[0];
        const videoPoster = videoParent.querySelectorAll('img')[0];
        let tl = gsap.timeline();

        tl.set(videoWrapper, {
            autoAlpha: 0
        }).to(playButton, {
            duration: 0.2,
            autoAlpha: 0,
            ease: Power3.easeOut
        }).to(videoPoster, {
            duration: 0.4,
            autoAlpha: 0,
            ease: Power3.easeOut
        }, '-=0.2').to(videoWrapper, {
            duration: 0.6,
            autoAlpha: 1,
            ease: Power1.easeIn
        }).to(closeButton, {
            duration: 0.3,
            autoAlpha: 0,
            ease: Power3.easeIn
        });
    }

    closeVideo(videoContainer) {
        var closeButton = videoParent.querySelectorAll('.video-container__close')[0],
            playButton = videoParent.querySelectorAll('.video-container__play')[0],
            videoPoster = videoParent.querySelectorAll('img')[0],
            videoWrapper = videoParent.querySelectorAll('.videoWrapper')[0],
            tl = gsap.timeline();

        tl
            .to(closeButton, {
                duration: 0.2,
                autoAlpha: 0,
                ease: Power3.easeOut
            })
            .to(videoWrapper, {
                duration: 0.4,
                autoAlpha: 0,
                ease: Power3.easeOut
            })
            .to(videoPoster, {
                duration: 0.6,
                autoAlpha: 1,
                ease: Power3.easeIn
            })
            .to(playButton, {
                duration: 0.2,
                autoAlpha: 1,
                ease: Power3.easeIn
            });

        if (videoContainer.hasClass('header')) {
            var headerTl = gsap.timeline(),
                breadcrumb = document.querySelector('.breadcrumb-container'),
                headline = document.querySelector('.header-container, .header-cta-banner');

            headerTl
                .to(breadcrumb, {
                    duration: 0.2,
                    autoAlpha: 1,
                    ease: Power3.easeIn
                })
                .to(headline, {
                    duration: 0.2,
                    autoAlpha: 1,
                    ease: Power3.easeIn
                }, '-=0.2');
        }
    }
}