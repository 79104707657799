import Spinner from "../Spinner";
import { LinkWrapper, ButtonWrapper } from "./Button.styles";

interface Props {
  children: React.ReactNode | string;
  onClick?: () => void;
  style?: any;
  disabled?: boolean;
  apperance?: "default" | "complete";
  href?: string;
  loading?: boolean;
  target?: string;
}

export default function Button({ onClick, children, style = {}, disabled = false, apperance = "default", href, loading, target = "_self" }: Props) {
  return href ? (
    <LinkWrapper href={href} style={style} apperance={apperance} target={target}>
      {loading && <Spinner isCentered />}
      <span style={{ visibility: loading ? "hidden" : "visible", textDecoration: "none" }}>{children}</span>
    </LinkWrapper>
  ) : (
    <ButtonWrapper onClick={onClick} style={style} disabled={disabled} apperance={apperance}>
      {loading && <Spinner isCentered />}
      <span style={{ visibility: loading ? "hidden" : "visible", display: "flex", alignItems: "center" }}>{children}</span>
    </ButtonWrapper>
  );
}
