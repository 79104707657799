import { Actions, ToggleButton, Row, RowQuestion, Wrapper } from "./UnionMemberScreen.styles";
import { useMemo, useState } from "react";
import unionOptions from "../../../../../../utils/unionOptions";
import SimpleSelect from "../../../../../../components/SimpleSelect";
import Checkbox from "../../../../../../components/Checkbox";
import { useStore } from "../../../../../../store/store";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import Button from "../../../../../../components/Button";
import Analytics from "../../../../../../utils/analytics";
import { TrackingFlowTypeEnum } from "../../../../../../types/TrackingFlowTypeEnum";

interface UnionMemberScreenProps {
  onHeightChange: (height: number) => void;
}

export default function UnionMemberScreen({ onHeightChange }: UnionMemberScreenProps) {
  const route = useStore.useUnionFlowRoute();
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const setUnionFlowRoute = useStore.useSetUnionFlowRoute();
  const payload = useStore.useUnionFlowPayload();
  const setUnionFlowPayload = useStore.useSetUnionFlowPayload();

  const canUnsubscribe = useMemo(() => {
    const relatedUnion = unionOptions.find((o) => o.id === payload.currentUnion);

    return Boolean(relatedUnion?.userUnsubscribe);
  }, [payload.currentUnion, unionOptions]);

  const disabled = useMemo(() => {
    return payload.currentlyMemberOfAUnion && !Boolean(payload.currentUnion);
  }, [payload]);

  const animationPosition = useMemo(() => {
    switch (route) {
      case UnionFlowRouteEnum.MEMBER:
        return AnimatedSlidePosition.MIDDLE;
      case UnionFlowRouteEnum.CONSENT:
        return AnimatedSlidePosition.LEFT;
      case UnionFlowRouteEnum.DEFAULT:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.LEFT;
    }
  }, [route]);

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} title="Er du medlem af en fagforening i dag?" onClose={() => setUnionFlowVisible(false)} onBack={() => setUnionFlowRoute(UnionFlowRouteEnum.DEFAULT)}>
      <Wrapper>
        <ToggleButton selected={payload.currentlyMemberOfAUnion} onClick={() => setUnionFlowPayload({ currentlyMemberOfAUnion: true })}>
          Ja
        </ToggleButton>
        <ToggleButton
          selected={!payload.currentlyMemberOfAUnion}
          onClick={() =>
            setUnionFlowPayload({
              currentlyMemberOfAUnion: false,
              unsubscribeCurrentUnion: false,
              currentUnion: undefined,
            })
          }
        >
          Nej
        </ToggleButton>
      </Wrapper>

      {payload.currentlyMemberOfAUnion && (
        <SimpleSelect
          id="member_fagforening_which"
          options={unionOptions}
          label="Hvilken"
          defaultOption={"Vælg nuværede fagforening"}
          selectedOption={unionOptions.find((p) => p.id === payload.currentUnion)}
          onSelect={(id) => {
            setUnionFlowPayload({ currentUnion: id });
          }}
        />
      )}

      {canUnsubscribe && payload.currentUnion && (
        <Row>
          <RowQuestion>Skal vi opsige fagforeningen for dig?</RowQuestion>
          <div style={{ flex: 1 }} />
          <Checkbox checked={payload.unsubscribeCurrentUnion} onChange={() => setUnionFlowPayload({ unsubscribeCurrentUnion: true })} text="Ja" />
          <Checkbox checked={!payload.unsubscribeCurrentUnion} onChange={() => setUnionFlowPayload({ unsubscribeCurrentUnion: false })} text="Nej" />
        </Row>
      )}

      <Actions>
        <Button
          disabled={disabled}
          onClick={() => {
            if (payload.unsubscribeCurrentUnion) {
              Analytics.getInstance().trackTerminateUnion(TrackingFlowTypeEnum.TrackAndTrace);
            }
            setUnionFlowRoute(UnionFlowRouteEnum.CONSENT);
          }}
        >
          Næste
        </Button>
      </Actions>
    </AnimatedSlideContainer>
  );
}
