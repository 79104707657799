import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
`;

export const Inner = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;

  p {
    margin: 0;
    text-align: center;
  }

  h2 {
    text-align: center;
    text-transform: initial;
  }

  a {
    text-decoration: underline;
  }

  .WithMargin {
    margin-bottom: 1rem;
  }
`;
