import { FaSpinner } from "react-icons/fa";
import { Wrapper } from "./Spinner.styles";
import React from "react";

interface SpinnerProps {
  show?: boolean;
  isCentered?: boolean;
  style?: React.CSSProperties;
}

export default function Spinner({ show = true, isCentered = false, style }: SpinnerProps) {
  return show ? (
    <Wrapper isCentered={isCentered} style={style}>
      <FaSpinner size={20} />
    </Wrapper>
  ) : null;
}
