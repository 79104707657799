import styled from "styled-components";
import { ButtonWrapper } from "../Button/Button.styles";

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 5px solid transparent;

  ${({ active }) =>
    active &&
    `
  border: 5px solid var(--color-petrol);

  > div {
    border-radius: 0px;
  }
  `}
`;

export const Inner = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.1875rem 1rem rgba(0, 0, 0, 0.161);
  background-color: var(--color-white);
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  background-color: var(--color-coal-25);

  ${({ active }) =>
    active &&
    `
    background-color: var(--color-white);
`}
`;

export const Header = styled.div`
  background-color: var(--color-orange);
  padding: 16px;
  min-height: 70px;
`;

export const Title = styled.p<{ active: boolean }>`
  color: var(--color-coal-50);
  font-size: 14px;
  font-weight: 700;
  margin: 0;

  ${({ active }) =>
    active &&
    `
    color: var(--color-white);
`}
`;

export const Name = styled.p`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const NumberOfPeople = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  padding: 8px 0;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Footer = styled.div`
  padding: 16px;
`;

export const Price = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`;
