import React from "react";
import { Input, Label, Wrapper } from "./RadioButton.styles";

interface RadioButtonProps {
  children?: string;
  onChange?: () => void;
  id?: string;
  checked?: boolean;
  type?: "radio" | "checkbox";
  prefix?: React.ReactNode;
  disabled?: boolean;
}

export default function RadioButton({ children, onChange, id, checked, type = "radio", prefix, disabled }: RadioButtonProps) {
  return (
    <Wrapper onClick={onChange}>
      <Input type={type} id={id} onChange={onChange} checked={checked} disabled={disabled} />
      <Label htmlFor={id}>
        {prefix}
        <span>{children}</span>
      </Label>
    </Wrapper>
  );
}
