import styled from "styled-components";

export const Select = styled.select`
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='none'/%3E%3Cpath d='M2 6 L10 14 L18 6' stroke='%233A3A39' stroke-width='2' fill='none'/%3E%3C/svg%3E");
  background-color: white;
  background-size: 20px 20px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  padding: 0 var(--form-indentation) 0 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  padding: 16px;
  padding-right: 3rem;
  border: 1px solid #4f4a45;
  appearance: none;
`;

export const Label = styled.label`
  display: block;
  font-weight: 700;
  margin-bottom: 0.5em;
  pointer-events: auto;
  user-select: none;
  text-transform: initial;
  font-size: 14px;
`;

export const Option = styled.option`
  max-width: 100%;
`;
