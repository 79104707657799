import { useEffect, useMemo, useRef, useState } from "react";
import { AnimatedContent, Content, Header, HeaderImage, HeaderInput, HeaderInputError, HeaderInputPostfix, HeaderInputWrapper, HeaderLabel, HeaderTitle, Inner, Wrapper } from "./SalaryInsuranceCalculator.styles";

import { useSpringRef, useTransition } from "@react-spring/web";
import CalculatorScreen from "./screens/CalculatorScreen/CalculatorScreen";
import FormScreen from "./screens/FormScreen/FormScreen";
import SuccessScreen from "./screens/SuccessScreen/SuccessScreen";
import ErrorScreen from "./screens/ErrorScreen/ErrorScreen";
import LoadingScreen from "./screens/LoadingScreen/LoadingScreen";
import { useSize } from "../../hooks/useSize";
import StartScreen from "./screens/StartScreen/StartScreen";
import { SalaryInsuranceStateEnum } from "../../types/SalaryInsuranceStateEnum";
import { useStore } from "../../store/store";
import { scrollTo } from "../../utils/scrollTo";
import { SalaryInsuranceItemModel } from "../../types/SalaryInsuranceItemModel";
import Analytics from "../../utils/analytics";

export default function SalaryInsuranceCalculator() {
  const salaryInsuranceState = useStore.useSalaryInsuranceState();
  const salaryInsuranceData = useStore.useSalaryInsuranceData();
  const minGrossPay = useStore.useMinGrossPay();
  const maxGrossPay = useStore.useMaxGrossPay();
  const thresholdGrossPay = useStore.useThresholdGrossPay();
  const basicOptions = useStore.useBasicOptions();
  const extendedOptions = useStore.useExtendedOptions();
  const setSalaryInsuranceState = useStore.useSetSalaryInsuranceState();
  const setSalaryInsuranceData = useStore.useSetSalaryInsuranceData();
  const setSalaryInsuranceConfig = useStore.useSetSalaryInsuranceConfig();
  const setBasicOptions = useStore.useSetBasicOptions();
  const setExtendedOptions = useStore.useSetExtendedOptions();
  const contentElmRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [grosspayNumber, setGrosspayNumber] = useState(0);
  const transRef = useSpringRef();
  const size = useSize(contentElmRef);
  const transitions = useTransition(salaryInsuranceState, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: "scale3d(0.95, 0.95, 0.95)" },
    enter: { opacity: 1, transform: "scale3d(1,1,1)" },
    leave: { opacity: 0, transform: "scale3d(0.95, 0.95, 0.95)" },
    exitBeforeEnter: true,
    config: {
      duration: 300,
    },
    onDestroyed: () => {
      if (salaryInsuranceState !== SalaryInsuranceStateEnum.START_SCREEN) {
        scrollTo("ASESalaryInsuranceCalculator");
      }
    },
  });

  const fetchDataSet = (url: string, setter: (value: SalaryInsuranceItemModel[]) => void) => {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setter(data);
      });
  };

  useEffect(() => {
    if (grosspayNumber < 10000) {
      setSalaryInsuranceState(SalaryInsuranceStateEnum.START_SCREEN);

      return;
    } else if (grosspayNumber < minGrossPay) {
      setErrorMessage("Lønsikring kan ikke betale sig, når man tjener under 27.949");
    } else if (grosspayNumber > maxGrossPay) {
      setErrorMessage("Ases Lønsikring har et loft på 87.949");
    } else {
      setErrorMessage("");
      if (SalaryInsuranceStateEnum.LOADING_SCREEN !== salaryInsuranceState) {
        setSalaryInsuranceState(SalaryInsuranceStateEnum.LOADING_SCREEN);
      }
    }
  }, [grosspayNumber, minGrossPay, maxGrossPay]);

  useEffect(() => {
    transRef.start();

    return () => {
      transRef.stop();
    };
  }, [salaryInsuranceState]);

  useEffect(() => {
    const elm = document.querySelector(`[data-component='salary-insurance-calculator']`) as HTMLElement;
    const minGrossPay = elm?.dataset.minGrossPay ? parseInt(elm?.dataset.minGrossPay) : 0;
    const maxGrossPay = elm?.dataset.maxGrossPay ? parseInt(elm?.dataset.maxGrossPay) : 0;
    const thresholdGrossPay = elm?.dataset.thresholdForBasicPrices ? parseInt(elm?.dataset.thresholdForBasicPrices) : 0;
    const unemploymentInsuranceAmount = elm?.dataset.uneploymentBenefitAmount ? parseInt(elm?.dataset.uneploymentBenefitAmount) : 0;
    const contentId = elm?.dataset.nodeid ?? "";
    const urlBasic = elm?.dataset.urlBasic ?? "";
    const urlExtended = elm?.dataset.urlExtended ?? "";

    // Setting the data sets from json files served from Umbraco
    fetchDataSet(urlBasic, setBasicOptions);
    fetchDataSet(urlExtended, setExtendedOptions);

    // Setting the data from the root element
    setSalaryInsuranceConfig(minGrossPay, maxGrossPay, thresholdGrossPay, unemploymentInsuranceAmount, contentId);
  }, []);

  return (
    <Wrapper>
      <HeaderImage src="/assets/images/salaryInsuranceImage.png" />
      <Inner>
        <Header id="ASESalaryInsuranceCalculator">
          <HeaderTitle>Beregn din pris</HeaderTitle>
          <HeaderLabel>Din løn (før skat)</HeaderLabel>
          <HeaderInputWrapper>
            <HeaderInput
              value={salaryInsuranceData.grosspay}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "") || "0";
                const salary = parseInt(value);

                let saleryString = new Intl.NumberFormat("da-dk").format(salary);
                saleryString = saleryString === "0" ? "" : saleryString;

                setGrosspayNumber(salary);
                setSalaryInsuranceData({
                  grosspay: saleryString,
                });

                if (salary > minGrossPay && salary < maxGrossPay) {
                  const isBasic = salary < thresholdGrossPay;
                  const options = isBasic ? basicOptions : extendedOptions;
                  Analytics.getInstance().trackSalaryInsuranceAction("recommended", salary, options[0].maksimal, 3, options[0].three_mnd, isBasic ? "basis" : "udvidet");
                }
              }}
            />
            <HeaderInputPostfix>kr./md.</HeaderInputPostfix>
            {errorMessage && <HeaderInputError>{errorMessage}</HeaderInputError>}
          </HeaderInputWrapper>
        </Header>

        <AnimatedContent height={size?.height ?? 150}>
          <Content ref={contentElmRef}>
            {transitions((style, state) => {
              switch (state) {
                case SalaryInsuranceStateEnum.START_SCREEN:
                  return <StartScreen style={style} />;
                case SalaryInsuranceStateEnum.CALCULATOR_SCREEN:
                  return <CalculatorScreen style={style} />;
                case SalaryInsuranceStateEnum.FORM_SCREEN:
                  return <FormScreen style={style} />;
                case SalaryInsuranceStateEnum.SUCCESS_SCREEN:
                  return <SuccessScreen style={style} />;
                case SalaryInsuranceStateEnum.ERROR_SCREEN:
                  return <ErrorScreen style={style} />;
                case SalaryInsuranceStateEnum.LOADING_SCREEN:
                  return <LoadingScreen style={style} />;
              }
            })}
          </Content>
        </AnimatedContent>
      </Inner>
    </Wrapper>
  );
}
