export const getCookie = (key: string) => {
  const cookies = document.cookie;
  if (!cookies) return null;
  const cookieArray = cookies.split(";");
  const value = cookieArray
    .filter((cookie) => {
      while (cookie.charAt(0) === " ") cookie = cookie.substring(1);
      return cookie.split("=")[0] === key;
    })
    .map((cookie) => {
      while (cookie.charAt(0) === " ") cookie = cookie.substring(1);
      return cookie.split("=")[1];
    })[0];

  if (value) {
    return decodeURIComponent(value);
  } else {
    return null;
  }
};

export const setCookie = (key: string, value: string, exdays = 365) => {
  const cookieValueString = value ? encodeURIComponent(value) : "";
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${key}=${cookieValueString};${expires};path=/;SameSite=Lax`;
};

export const setSessionCookie = (key: string, value: string) => {
  const cookieValueString = value ? encodeURIComponent(value) : "";
  const expires = "expires=0";
  document.cookie = `${key}=${cookieValueString};${expires};path=/;SameSite=Lax`;
};
