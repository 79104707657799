import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../../store/store";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import { Wrapper } from "./ReceiptScreen.styles";
import Spinner from "../../../../../../components/Spinner";
import Lottie from "react-lottie";
import * as SuccessAnimation from "./SuccessAnimation.json";
import Button from "../../../../../../components/Button";
import { postTrackAndTraceBuySalaryInsurance } from "../../../../../../utils/api";
import { SalaryInsuranceFlowRouteEnum } from "../../../../../../types/SalaryInsuranceFlowRouteEnum";

interface ReceiptScreenProps {
  onHeightChange: (height: number) => void;
}

enum LoadingState {
  Initial,
  Loading,
  Success,
  Error,
}

export default function ReceiptScreen({ onHeightChange }: ReceiptScreenProps) {
  const setSalaryInsuranceFlowVisible = useStore.useSetSalaryInsuranceFlowVisible();
  const route = useStore.useSalaryInsuranceFlowRoute();
  const response = useStore.useTrackTraceResponse();
  const [loading, setLoading] = useState(LoadingState.Initial);

  const onSendRequest = async () => {
    try {
      setLoading(LoadingState.Loading);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id") ?? "";
      await postTrackAndTraceBuySalaryInsurance({ id });
      setLoading(LoadingState.Success);
    } catch (err) {
      setLoading(LoadingState.Error);
    }
  };

  useEffect(() => {
    if (loading === LoadingState.Initial && route === SalaryInsuranceFlowRouteEnum.RECEIPT) {
      onSendRequest();
    }
  }, [route, loading]);

  const animationPosition = useMemo(() => {
    switch (route) {
      case SalaryInsuranceFlowRouteEnum.RECEIPT:
        return AnimatedSlidePosition.MIDDLE;
      case SalaryInsuranceFlowRouteEnum.CONFIRM:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.RIGHT_NO_ANIMATION;
    }
  }, [route]);

  const onClose = async () => {
    setSalaryInsuranceFlowVisible(false);
    window.location.reload();
  };

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} title="" onClose={() => setSalaryInsuranceFlowVisible(false)}>
      {loading === LoadingState.Loading && (
        <Wrapper style={{ height: 250 }}>
          <Spinner isCentered />
        </Wrapper>
      )}
      {loading === LoadingState.Error && (
        <Wrapper style={{ height: 250 }}>
          <h3 style={{ paddingBottom: 30 }}>
            <b>Der opstod en fejl, prøv venligst senere!</b>
          </h3>

          <Button style={{ width: 350, marginTop: 20 }} onClick={onClose}>
            Luk
          </Button>
        </Wrapper>
      )}
      {loading === LoadingState.Success && (
        <Wrapper>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: SuccessAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={130}
            width={130}
            style={{ marginBottom: 20 }}
          />
          <h3>
            <b>Tak {response?.name}</b>
          </h3>
          <p>Vi ringer til dig inden for to hverdage for at gennemgå dine muligheder med Ase Lønsikring og sammensætte den bedste lønsikring til dig. Vi glæder os til at snakke med dig.</p>

          <p>Venlig hilsen</p>
          <p>
            <b>Ase</b>
          </p>

          <Button style={{ width: 350, marginTop: 20 }} onClick={onClose}>
            Luk
          </Button>
        </Wrapper>
      )}
    </AnimatedSlideContainer>
  );
}
