import { useEffect, useMemo, useRef, useState } from "react";
import TextInput from "../../components/TextInput";
import dayjs from "dayjs";
import "dayjs/locale/da";
import { postEmailValidation, submitPartnerInvite } from "../../utils/api";
import Modal from "../../components/Modal";
import Analytics from "../../utils/analytics";
import { Form, FormHeader, FormInputs, FormItem, FormTitle, InnerWrapper, SubmitButton, Wrapper } from "./CompanionInviteForm.styles";
import debounce from "lodash.debounce";
import { EmailValidationResponse } from "../../types/EmailValidationResponse";
import Spinner from "../../components/Spinner";
import { PartnerInviteRequest } from "../../types/PartnerInviteRequest";
import { AseSpinner } from "../../utils/global.styles";

dayjs.locale("da");

interface FormData {
  [key: string]: { value: string; error: boolean };
}

const defaultState = { firstName: { value: "", error: true }, lastName: { value: "", error: true }, email: { value: "", error: true } } as FormData;

const errorHtml = "<h2><b>Der opstod en fejl</b></h2><br /><p>Prøv igen om et par sekunder. Hvis fejlen varer ved, kan du ringe til os på <a href='tel:004570137013'  target='_blank'>7013 7013</a> og få hjælp. </p><br /><p>Vi sidder klar ved telefonerne:</p><br /><p><b>Mandag - torsdag: 8.00 - 17.00</b></p><p><b>Fredag: 8.00 - 16.00 </b></p><br /><br /><p>Venlig hilsen </p><p><b>Ase</b></p>";
let idToken = "";
let referralUrl = "";

export default function ChildrenEnrollment() {
  const [formData, setFormData] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [name, setName] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [emailValidationResponse, setEmailValidationResponse] = useState<EmailValidationResponse>({
    email: "",
    score: 0,
    suggestion: "",
    verdict: "",
  });

  useEffect(() => {
    const form = document.querySelector(`[data-component='companion-invite']`) as HTMLElement;
    if (form?.dataset.redirect && form?.dataset.redirect !== "null") {
      setRedirectUrl(form?.dataset.redirect);
    }

    if (form?.dataset.firstname && form?.dataset.firstname !== "null") {
      setName(form?.dataset.firstname);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    idToken = urlParams.get("id") ?? "";
    referralUrl = urlParams.get("ref") ?? "";

    if (referralUrl) {
      referralUrl = `${referralUrl}?id=${idToken}`;
    }
  }, []);

  const setValue = (field: string, value: string, error: boolean) => {
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value,
        error,
      },
    });
  };

  const onSendData = async () => {
    try {
      setLoading(true);
      Analytics.getInstance().trackSubmitCompanionForm();
      const request = {
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        email: formData.email.value,
        ownerId: idToken,
      } as PartnerInviteRequest;

      await submitPartnerInvite(request);

      if (referralUrl) {
        window.location.href = referralUrl;
      } else {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const formIsValid = useMemo(() => {
    return !(
      Object.values(formData)
        .map((v) => v.error)
        .includes(true) || isValidatingEmail
    );
  }, [formData, isValidatingEmail]);

  const validateEmail = async (email: string) => {
    if (!email) return;
    try {
      setIsValidatingEmail(true);
      const response = await postEmailValidation(email);
      setEmailValidationResponse(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsValidatingEmail(false);
    }
  };

  const debouncedEmailValidation = useRef(
    debounce(async (email: string) => {
      await validateEmail(email);
    }, 300)
  ).current;

  const handleEmailChange = async (email: string) => {
    debouncedEmailValidation(email);
  };

  useEffect(() => {
    setValue("email", formData.email.value, !emailValidationResponse.verdict || emailValidationResponse.verdict === "Invalid");
  }, [emailValidationResponse]);

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <h1>Invitér din partner</h1>

          {name && <h2>Hej {name}</h2>}
          <p>Her kan du invitere din partner til din familiefagforening. Når han eller hun har gennemført sin indmeldelse, bliver I automatisk knyttet på den samme familiefagforening. </p>

          <Form>
            <FormItem>
              <FormHeader>
                <FormTitle>Partner</FormTitle>
              </FormHeader>
              <FormInputs>
                <TextInput
                  label="Fornavn"
                  value={formData.firstName.value}
                  onChange={(e) => {
                    setValue("firstName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
                  }}
                  type="text"
                  autoComplete="given-name"
                  placeholder="Fornavn"
                  pattern=".{1,32}"
                  id="firstName"
                />
                <TextInput
                  label="Efternavn"
                  value={formData.lastName.value}
                  onChange={(e) => {
                    setValue("lastName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
                  }}
                  type="text"
                  autoComplete="Efternavn"
                  placeholder="Efternavn"
                  pattern=".{1,32}"
                  id="lastName"
                />
              </FormInputs>

              <FormInputs>
                <TextInput
                  label="Email"
                  type="email"
                  value={formData.email.value as string}
                  onChange={(e) => {
                    setValue("email", e.currentTarget.value, true);
                    handleEmailChange(e.currentTarget.value);
                  }}
                  hasError={emailValidationResponse.verdict === "Invalid"}
                  disableNativeError
                  autoComplete="email"
                  placeholder="E-mail"
                  id="email"
                  error={emailValidationResponse.verdict === "Invalid" ? "Hov! Den indtastede e-mail er ikke gyldig" : ""}
                  postfix={<Spinner show={isValidatingEmail} />}
                />

                {emailValidationResponse.suggestion && <p style={{ color: "var(--color-red)", fontSize: "0.8rem" }}>Mente du: {emailValidationResponse.suggestion}</p>}
              </FormInputs>
            </FormItem>

            <SubmitButton className="button ASEChildrenEnrollment__SubmitButton" disabled={!formIsValid || loading} onClick={onSendData}>
              {loading ? <AseSpinner /> : <span>Invitér partner</span>}
            </SubmitButton>
          </Form>
        </InnerWrapper>
      </Wrapper>

      <Modal modalDescriptionHtml={errorHtml} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
