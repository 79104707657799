const data = {
  wantBoth: {
    title: "Få mere ud af dit medlemskab",
    description: "Du kan til enhver tid trække dit samtykke tilbage på MitAse eller ved at kontakte os via e-mail: <a href='mailTo:ase@ase.dk'><u>ase@ase.dk</u></a>",
    box1Text: "For at hjælpe mig bedst muligt, <b>accepterer jeg, at fagforeningen og a-kasse må dele oplysninger om mit medlemskab</b>",
    box1ModalText: "<p><b><u>Fagforeningen deler oplysninger om dit medlemskab med a-kassen</u></b></p><p>Formålet er at gøre det muligt for a-kassen at kende til dit medlemskab, så den kan sende dig videre til fagforeningen, hvis der er behov for fagretlig rådgivning i forbindelse med en ansøgning om dagpenge eller ved øvrige henvendelser om din arbejdssituation.</p><p><b><u>A-kasse deler oplysninger om dit medlemskab med fagforeningen</u></b></p><p>Formålet er at gøre det muligt for fagforeningen at kende til dit medlemskab, så den kan sende dig videre til a-kassen, hvis der er behov for rådgivning om ret til dagpenge og andre ydelser eller ved øvrige henvendelser om din arbejdssituation.</p>",
    box2ModalText: "<p>Vi kontakter dig på enten mail, telefon, SMS eller via annoncer på sociale medier.</p><p>Du kan altid ændre, hvordan vi må kontakte dig - og om hvad - på Mitase.</p><p>For at kontakte dig behandler vi følgende oplysninger om dig:</p><ul><li>Dit navn</li><li>Dit mailadresse</li><li>Dit telefonnummer</li></ul>",
  },
  wantUnion: {
    title: "Få mere ud af din fagforening",
    description: "Du kan til enhver tid trække dit samtykke tilbage på MitAse eller ved at kontakte os via e-mail: <a href='mailTo:ase@ase.dk'><u>ase@ase.dk</u></a>",
    box1Text: "For at hjælpe mig bedst muligt, <b>accepterer jeg, at fagforeningen må dele oplysninger om mit medlemskab</b> med a-kasse - hvis jeg har begge hos Ase",
    box1ModalText: "<p><b><u>A-kassen deler oplysninger om dit medlemskab med fagforeningen</u></b></p><p>Formålet er at gøre det muligt for fagforeningen at kende til dit medlemskab så den kan sende dig videre til a-kassen, hvis der er behov for rådgivning om ret til dagpenge og andre ydelser eller ved øvrige henvendelser om din arbejdssituation.</p>",
    box2ModalText: "<p>Vi kontakter dig på enten mail, telefon, SMS eller via annoncer på sociale medier.</p><p>Du kan altid ændre, hvordan vi må kontakte dig - og om hvad - på Mitase.</p><p>For at kontakte dig behandler vi følgende oplysninger om dig:</p><ul><li>Dit navn</li><li>Dit mailadresse</li><li>Dit telefonnummer</li></ul>",
  },
  wantUnemploymentBenefits: {
    title: "Få mere ud af din a-kasse",
    description: "Du kan til enhver tid trække dit samtykke tilbage på MitAse eller ved at kontakte os via e-mail: <a href='mailTo:ase@ase.dk'><u>ase@ase.dk</u></a>",
    box1Text: "For at hjælpe mig bedst muligt, <b>accepterer jeg, at a-kassen må dele oplysninger om mit medlemskab</b> med fagforeningen - hvis jeg har begge hos Ase",
    box1ModalText: "<p><b><u>A-kassen deler oplysninger om dit medlemskab med fagforeningen</u></b></p><p>Formålet er at gøre det muligt for a-kassen at kende til dit medlemskab så den kan sende dig videre til fagforeningen, hvis der er behov for fagretlig rådgivning i forbindelse med din ansøgning om dagpenge eller ved øvrige henvendelser om din arbejdssituation.</p>",
    box2ModalText: "<p>Vi kontakter dig på enten mail, telefon, SMS eller via annoncer på sociale medier.</p><p>Du kan altid ændre, hvordan vi må kontakte dig - og om hvad - på Mitase.</p><p>For at kontakte dig behandler vi følgende oplysninger om dig:</p><ul><li>Dit navn</li><li>Dit mailadresse</li><li>Dit telefonnummer</li></ul>",
  },
  privacyPolicy: {
    title: "<h3>Cookie, privatliv og persondata</h3>",
    subtitle: "<p>Du kan her se vores betingelser for privatliv, cookie, persondata og handel. Kontakt os på <a href='tel:70137013'><u>7013 7013</u></a> eller på <a href='mailto:hej@ase.dk' target='_blank'><u>hej@ase.dk</u></a>, hvis du har yderligere spørgsmål.</p><br />",
    text1: "<h4><b>Privatlivsbetingelser</b></h4><p>Hos Ase respekterer vi dit privatliv, og beskyttelsen af dine oplysninger er vigtigt for os. <a href='https://www.ase.dk/cookie-privatliv-og-persondata/privatlivsbetingelser' target='_blank'><u>Læs mere her.</u></a></p>",
  },
  partnerPolicy: {
    title: "<h3>Ases samarbejdspartnere</h3>",
    subtitle: "<p>Vi samarbejder med en række leverandører og virksomheder, for at kunne give dig den bedste rådgivning og service, samt de bedste produkter og tilbud. Disse samarbejdspartnere kan ændre sig over tid.</p>",
    text1: "<br /><p>Vores samarbejdspartnere:</p>",
    text2: "<ul><li><p><b>Tryg Forsikring</b> (lønsikring, bilforsikring, indboforsikring, rejseforsikring, husforsikring, ulykkesforsikring)</p></li><li><p><b>Finansrådgiverne A/S</b> (uvildig pensionrådgivning)</p></li><li><p><b>Toptutors</b> (lektie- og eksamenshjælp)</p></li><li><p><b>Taxhelper</b> (skattefradragstjek)</p></li><li><p><b>Juice</b> (jobportal)</p></li></ul>",
    text3: "<br /><p>Provision fra samarbejdspartnere:</p>",
    text4: "<p>Vi modtager provision for formidling af nogle af vores produkter fra nogle af vores samarbejdspartnere.</p>",
  },
};

export default data;
