import { Inner, Wrapper } from "./ProgressBar.styles";

interface ProgressBarProps {
  progress: number;
}

export default function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <Wrapper>
      <Inner style={{ width: `${100 * Math.min(Math.max(progress, 0), 1)}%` }} />
    </Wrapper>
  );
}
