import { SpringValue } from "@react-spring/web";
import { BackButton, Label, Summary, SummaryEntry, SummaryValue, TextInput, TextInputWrapper, Title, ValidIcon, Wrapper } from "./FormScreen.styles";
import Button from "../../../../components/Button";
import { useStore } from "../../../../store/store";
import { useMemo, useRef, useState } from "react";
import { postEmailValidation, postSalaryInsuranceData } from "../../../../utils/api";
import debounce from "lodash.debounce";
import Spinner from "../../../../components/Spinner";
import { SalaryInsuranceStateEnum } from "../../../../types/SalaryInsuranceStateEnum";
import { IoArrowBack } from "react-icons/io5";
import Analytics from "../../../../utils/analytics";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function FormScreen({ style }: Props) {
  const setSalarySetInsuranceData = useStore.useSetSalaryInsuranceData();
  const salaryInsuranceData = useStore.useSalaryInsuranceData();
  const unemploymentInsuranceAmount = useStore.useUnemploymentInsuranceAmount();
  const thresholdGrossPay = useStore.useThresholdGrossPay();
  const setSalaryInsuranceState = useStore.useSetSalaryInsuranceState();
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [errorMap, setErrorMap] = useState({
    name: {
      error: false,
      dirty: false,
    },
    phone: {
      error: false,
      dirty: false,
    },
    email: {
      error: false,
      dirty: false,
    },
  });

  const validateEmail = async (email: string) => {
    if (!email) return;
    try {
      setIsValidatingEmail(true);
      const response = await postEmailValidation(email);
      setErrorMap((prev) => ({ ...prev, email: { ...prev.email, error: !response.data.verdict || response.data.verdict === "Invalid", dirty: true } }));
    } catch (err) {
      console.log(err);
    } finally {
      setIsValidatingEmail(false);
    }
  };

  const submit = async () => {
    try {
      setIsSubmittingData(true);
      const grosspayNumber = parseInt(salaryInsuranceData.grosspay.replace(/[^0-9]/g, "") || "0");
      const payoutNumber = parseInt(salaryInsuranceData.payout.replace(/[^0-9]/g, "") || "0");
      const priceNumber = parseInt(salaryInsuranceData.dinpris.replace(/[^0-9]/g, "") || "0");
      const isBasic = grosspayNumber < thresholdGrossPay;
      Analytics.getInstance().trackSalaryInsuranceAction("form_completed", grosspayNumber, payoutNumber, salaryInsuranceData.payoutduration, priceNumber, isBasic ? "basis" : "udvidet");
      await postSalaryInsuranceData(salaryInsuranceData);
      setTimeout(() => {
        setSalaryInsuranceState(SalaryInsuranceStateEnum.SUCCESS_SCREEN);
      }, 1000);
    } catch (err) {
      setSalaryInsuranceState(SalaryInsuranceStateEnum.ERROR_SCREEN);
    }
  };

  const debouncedEmailValidation = useRef(
    debounce(async (email: string) => {
      await validateEmail(email);
    }, 300)
  ).current;

  const handleEmailChange = async (email: string) => {
    debouncedEmailValidation(email);
  };

  const isDisabled = useMemo(() => {
    return Object.values(errorMap)
      .map((item) => !item.error && item.dirty)
      .includes(false);
  }, [errorMap]);

  const totalPrice = useMemo(() => {
    return parseInt(salaryInsuranceData.payout.replace(/[^0-9]/g, "") || "0") + unemploymentInsuranceAmount;
  }, [salaryInsuranceData]);

  return (
    <Wrapper style={style}>
      <BackButton onClick={() => setSalaryInsuranceState(SalaryInsuranceStateEnum.CALCULATOR_SCREEN)}>
        <IoArrowBack />
      </BackButton>
      <Summary>
        <SummaryEntry>
          <SummaryValue>Din pris: </SummaryValue>
          <SummaryValue style={{ fontSize: 20, fontWeight: 700, color: "var(--color-orange)" }}>{new Intl.NumberFormat("da-dk").format(parseInt(salaryInsuranceData.dinpris.replace(/[^0-9]/g, "") || "0"))} kr./md.</SummaryValue>
        </SummaryEntry>
        <SummaryEntry>
          <SummaryValue style={{ fontWeight: 700 }}>Med Ase Lønsikring får du:</SummaryValue>
        </SummaryEntry>
        <SummaryEntry>
          <SummaryValue>Dagpenge: </SummaryValue>
          <SummaryValue>{new Intl.NumberFormat("da-dk").format(unemploymentInsuranceAmount)} kr./md.</SummaryValue>
        </SummaryEntry>
        <SummaryEntry>
          <SummaryValue>Lønsikring: </SummaryValue>
          <SummaryValue>{new Intl.NumberFormat("da-dk").format(parseInt(salaryInsuranceData.payout.replace(/[^0-9]/g, "") || "0"))} kr./md.</SummaryValue>
        </SummaryEntry>
        <SummaryEntry style={{ fontWeight: 700 }} withBorder>
          <SummaryValue>I alt: </SummaryValue>
          <SummaryValue>{new Intl.NumberFormat("da-dk").format(totalPrice)} kr./md.</SummaryValue>
        </SummaryEntry>
        <SummaryEntry>
          <SummaryValue>Du har valgt lønsikring i {salaryInsuranceData.payoutduration} mdr.</SummaryValue>
        </SummaryEntry>
      </Summary>

      <Title>Er ovenstående korrekt? Udfyld dine oplysninger, så ringer vi og færdiggør din bestilling sammen med dig.</Title>

      <Label>Navn: *</Label>
      <TextInputWrapper>
        <TextInput
          type="text"
          value={salaryInsuranceData.name}
          autoComplete="full-name"
          pattern=".{1,32}"
          id="full-name"
          onChange={(e) => {
            setSalarySetInsuranceData({ name: e.currentTarget.value });
            setErrorMap({ ...errorMap, name: { ...errorMap.name, error: e.currentTarget.validity.patternMismatch || !e.currentTarget.value } });
          }}
          onBlur={() => {
            setErrorMap({ ...errorMap, name: { ...errorMap.name, dirty: true } });
          }}
          className={errorMap.name.error && errorMap.name.dirty ? "error" : ""}
        />
        {!errorMap.name.error && errorMap.name.dirty ? <ValidIcon /> : null}
      </TextInputWrapper>

      <Label>Telefonnummer: *</Label>
      <TextInputWrapper>
        <TextInput
          type="text"
          value={salaryInsuranceData.phone}
          onChange={(e) => {
            setSalarySetInsuranceData({ phone: e.currentTarget.value });
            setErrorMap({ ...errorMap, phone: { ...errorMap.phone, error: e.currentTarget.validity.patternMismatch || !e.currentTarget.value } });
          }}
          onBlur={() => {
            setErrorMap({ ...errorMap, phone: { ...errorMap.phone, dirty: true } });
          }}
          className={errorMap.phone.error && errorMap.phone.dirty ? "error" : ""}
          pattern=".{8,32}"
          id="phone"
          autoComplete="phone"
        />
        {!errorMap.phone.error && errorMap.phone.dirty ? <ValidIcon /> : null}
      </TextInputWrapper>

      <Label>E-mail: *</Label>
      <TextInputWrapper style={{ marginBottom: 16 }}>
        <TextInput
          type="email"
          value={salaryInsuranceData.email}
          autoComplete="email"
          id="email"
          onChange={(e) => {
            setSalarySetInsuranceData({ email: e.currentTarget.value });
            handleEmailChange(e.currentTarget.value);
          }}
          className={errorMap.email.error && errorMap.email.dirty ? "error" : ""}
        />
        {isValidatingEmail ? <Spinner style={{ top: -14, right: -12 }} /> : !errorMap.email.error && errorMap.email.dirty ? <ValidIcon /> : null}
      </TextInputWrapper>

      <Button onClick={submit} disabled={isDisabled} loading={isSubmittingData}>
        Ring mig op
      </Button>
    </Wrapper>
  );
}
