import styled from "styled-components";

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Date = styled.div`
  margin: 20px 0;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f1ee;
  color: black;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
`;
