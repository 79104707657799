import React from "react";
import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { AseButtonActions, AseFieldset } from "../../../../utils/global.styles";
import GiftCardPopup from "../../../../components/GiftCardPopup";

export default function WorkingDenmarkStep() {
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const t = useStore.useTranslations();

  return (
    <StepContainer
      stepTitle={t?.workingDenmarkStep.title}
      progress={0.35}
      buttonText={t?.workingDenmarkStep.buttonLabel}
      helpText={t?.workingDenmarkStep.helpText}
      onNext={() => {
        if (payload.areYouPrimarilyWorkingInDenmark) {
          setRouteStack(StepRoutes.ConsentStep);
        } else {
          setPayload({
            cause: "Arbejder ikke Primært i Danmark",
          });
          setRouteStack(StepRoutes.NeedToContactYouStep);
        }
      }}
      nextDisabled={payload.areYouPrimarilyWorkingInDenmark === undefined}
    >
      <AseFieldset>
        <AseButtonActions>
          <RadioButton onChange={() => setPayload({ areYouPrimarilyWorkingInDenmark: true })} id="working_in_denmark_yes" checked={payload.areYouPrimarilyWorkingInDenmark === undefined ? false : payload.areYouPrimarilyWorkingInDenmark}>
            {t?.workingDenmarkStep.answerYes}
          </RadioButton>
          <RadioButton
            onChange={() =>
              setPayload({
                areYouPrimarilyWorkingInDenmark: false,
              })
            }
            id="working_in_denmark_no"
            checked={payload.areYouPrimarilyWorkingInDenmark === undefined ? false : !payload.areYouPrimarilyWorkingInDenmark}
          >
            {t?.workingDenmarkStep.answerNo}
          </RadioButton>
        </AseButtonActions>
        <p>{t?.workingDenmarkStep.description}</p>
      </AseFieldset>
    </StepContainer>
  );
}
