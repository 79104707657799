import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  div {
    position: relative;
  }
`;

export const Input = styled.input<{ isOutlined: boolean; nativeErrorsEnabled: boolean; hasError: boolean }>`
  width: 100%;
  outline: none;
  padding: 1.4rem;
  border-radius: 5px;
  border: 5px solid var(--color-white);

  &:focus {
    border: 5px solid rgba(0, 142, 159, 0.4);
  }

  &:checked {
    border: 5px solid var(--color-petrol);
  }

  ${({ isOutlined }) =>
    isOutlined &&
    `
  border: 5px solid var(--color-grey);
  `}

  ${({ nativeErrorsEnabled }) =>
    nativeErrorsEnabled &&
    `
    &:invalid {
        border: 5px solid var(--color-alert-color);
    }
`}

  ${({ hasError }) => hasError && "border: 5px solid var(--color-alert-color);"}
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Label = styled.label`
  display: block;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.5em;
  pointer-events: auto;
  user-select: none;
  font-size: 1.2rem;
  text-transform: initial;
`;

export const RequiredIndicator = styled.span`
  color: var(--color-alert-color);
  margin-left: 0.25em;
`;

export const ErrorMessage = styled.div`
  color: var(--color-error);
  padding: 0.6rem 0;
  text-transform: initial;
`;
