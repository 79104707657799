import { StateCreator } from "zustand";
import { UnionFlowRouteEnum } from "../../types/UnionFlowRouteEnum";
import { TrackTraceResponseModel } from "../../types/TrackTraceResponseModel";
import { TrackTraceUnionRequestModel } from "../../types/TrackTraceUnionRequestModel";
import { SalaryInsuranceFlowRouteEnum } from "../../types/SalaryInsuranceFlowRouteEnum";

export type TrackTraceSlice = {
  unionFlowRoute: UnionFlowRouteEnum;
  unionFlowVisible: boolean;
  salaryInsuranceFlowVisible: boolean;
  salaryInsuranceFlowRoute: SalaryInsuranceFlowRouteEnum;
  trackTraceResponse: TrackTraceResponseModel | undefined;
  unionFlowPayload: TrackTraceUnionRequestModel;
  setUnionFlowRoute: (unionFlowRoute: UnionFlowRouteEnum) => void;
  setSalaryInsuranceFlowRoute: (salaryInsuranceFlowRoute: SalaryInsuranceFlowRouteEnum) => void;
  setUnionFlowVisible: (visible: boolean) => void;
  setSalaryInsuranceFlowVisible: (visible: boolean) => void;
  setUnionFlowPayload: (data: { [key: string]: string | boolean | undefined | object }) => void;
  setTrackTraceResponse: (data: TrackTraceResponseModel) => void;
};

export const trackTraceInitialState = {
  unionFlowRoute: UnionFlowRouteEnum.DEFAULT,
  unionFlowVisible: false,
  salaryInsuranceFlowRoute: SalaryInsuranceFlowRouteEnum.DEFAULT,
  salaryInsuranceFlowVisible: false,
  trackTraceResponse: undefined,
  unionFlowPayload: {
    userId: "",
    wantsUnion: false,
    wantsFamilyUnion: false,
    currentlyMemberOfAUnion: false,
    unsubscribeCurrentUnion: false,
    currentUnion: undefined,
    consents: {
      unemploymentBenefits: {
        aseCooperation: false,
        member: false,
        partner: false,
      },
      union: {
        aseCooperation: false,
        member: false,
        partner: false,
      },
    },
  },
} as TrackTraceSlice;

export const createTrackTraceSlice: StateCreator<TrackTraceSlice, [], [], TrackTraceSlice> = (set) =>
  ({
    ...trackTraceInitialState,
    setUnionFlowRoute: (unionFlowRoute: UnionFlowRouteEnum) => {
      set(() => ({
        unionFlowRoute,
      }));
    },
    setSalaryInsuranceFlowRoute: (salaryInsuranceFlowRoute: SalaryInsuranceFlowRouteEnum) => {
      set(() => ({
        salaryInsuranceFlowRoute,
      }));
    },
    setUnionFlowVisible: (visible: boolean) => {
      set(() => ({
        unionFlowVisible: visible,
      }));
    },
    setSalaryInsuranceFlowVisible: (visible: boolean) => {
      set(() => ({
        salaryInsuranceFlowVisible: visible,
      }));
    },
    setUnionFlowPayload: (data: { [key: string]: string | boolean | undefined | object }) => {
      set((state) => {
        return {
          ...state,
          unionFlowPayload: {
            ...state.unionFlowPayload,
            ...data,
          },
        };
      });
    },
    setTrackTraceResponse: (data: TrackTraceResponseModel) => {
      set(() => ({
        trackTraceResponse: data,
      }));
    },
  } as TrackTraceSlice);
