import moment from 'moment'

export default class EnrollmentForm {
    constructor(element) {
        this.el = element;
        this.init()
    }
    init() {
        const form = this.el


        // elements
        var sections = form.querySelectorAll("section");
        var helpBoxes = form.querySelectorAll(".help-checkbox");
        var nextButtons = form.querySelectorAll(".navigation .next-button");
        var prevButtons = form.querySelectorAll(".navigation .prev-button");
        var callButton = form.querySelector("[data-button-call]");
        var unemploymentBenefitsContainer = form.querySelector("[data-select-unemploymentbenefits]");
        var unionsContainer = form.querySelector("[data-select-unions]");
        var groupSelectors = form.querySelectorAll("[data-group-selector]");
        var timeline = form.querySelector(".timeline-meter");

        // Membership types
        var chkbxAkasse = form.querySelector("#chkbxAkasse");
        var chkbxFagforening = form.querySelector("#chkbxFagforening");
        var chkbxSalaryEnsurance = form.querySelector("#chkbxSalaryEnsurance");

        // Personal info
        var tbxName = form.querySelector("#tbxName");
        var tbxCpr = form.querySelector("#tbxCpr");
        var tbxPhone = form.querySelector("#tbxPhone");
        var tbxMail = form.querySelector("#tbxMail");
        var chkbxSecretAdress = form.querySelector("#chkbxSecretAdress");
        var tbxSecretAdress = form.querySelector("#tbxSecretAdress");
        var tbxSecretPostalCodeCity = form.querySelector("#tbxSecretPostalCodeCity");
        var memberSignup = form.querySelector(".member_signup");

        // Occupation
        var chkbxFuldtidsjob = form.querySelector("#chkbxFuldtidsjob");
        var chkbxIkkeFuldtidsjob = form.querySelector("#chkbxIkkeFuldtidsjob");
        var chkbxLedig = form.querySelector("#chkbxLedig");
        var chkbxStuderende = form.querySelector("#chkbxStuderende");
        var chkbxNyuddannet = form.querySelector("#chkbxNyuddannet");
        var chkbxOwnCompany = form.querySelector("#chkbxOwnCompany");
        var chkbxPartnersVirksomhed = form.querySelector("#chkbxPartnersVirksomhed");

        var ownCompanyOptions = form.querySelector("input[name='OwnCompanyOptions']");
        var radioWorkFulltimeInOwnCompany = form.querySelector("#radioWorkFulltimeInOwnCompany");
        var radioWorkParttimeInOwnCompany = form.querySelector("#radioWorkParttimeInOwnCompany");


        // Unemployment benefits and union membership
        var currentUnemploymentMembership = form.querySelector("input[name='currentUnemploymentMembership']");
        var radioCurrentUnemploymentMembershipNo = form.querySelector("#radioCurrentUnemploymentMembershipNo");


        var currentUnionMembership = form.querySelector("input[name='currentUnionMembership']");

        // Package
        var chkbxPackageWorkingPrimaryInDK = form.querySelector("#chkbxPackageWorkingPrimaryInDK");
        var chkbxPackageTransferASAPToASE = form.querySelector("#chkbxPackageTransferASAPToASE");
        var chkbxPackageNotMemberOfUnemploymentBenefitsBefore = form.querySelector("#chkbxPackageNotMemberOfUnemploymentBenefitsBefore");
        var chkbxPackageNotWorkedInOtherCountry = form.querySelector("#chkbxPackageNotWorkedInOtherCountry");

        var radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslands = form.querySelector("input[name='PackageWorkedInOtherCountryEOSCountryOrFaroeIslands']");
        var radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslandsYes = form.querySelector("#radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslandsYes");

        var chkbxPackageWantMembershipASAP = form.querySelector("#chkbxPackageWantMembershipASAP");

        const slideConsentsAA = form.querySelector('[data-slide="ConsentsAA"]')
        const slideConsentsAF = form.querySelector('[data-slide="ConsentsAF"]')
        slideConsentsAA.addEventListener('change', onConsentsStarted)
        slideConsentsAF.addEventListener('change', onConsentsStarted)

        const nextConsentAA = form.querySelector('[data-next-consent-aa]')
        const nextConsentAF = form.querySelector('[data-next-consent-af]')
        nextConsentAA.addEventListener('click', onConsentNextAA)
        nextConsentAF.addEventListener('click', onConsentNextAF)

        function onConsentsStarted(event) {
            slideConsentsAA.removeEventListener('change', onConsentsStarted)
            slideConsentsAF.removeEventListener('change', onConsentsStarted)
            trackEvent('permissions', 'started', event.target.getAttribute("data-trackingid"))
        }

        function onConsentNextAA() {
            const ids = [...slideConsentsAA.querySelectorAll('input[type="checkbox"]:checked')].map(cb => cb.getAttribute("data-trackingid")).join(',')
            trackEvent('permissions', 'selected', ids)
        }

        function onConsentNextAF() {
            const ids = [...slideConsentsAF.querySelectorAll('input[type="checkbox"]:checked')].map(cb => cb.getAttribute("data-trackingid")).join(',')
            trackEvent('permissions', 'added', ids)
        }

        function onConsentsSubmitted() {
            const ids = [
                ...slideConsentsAA.querySelectorAll('input[type="checkbox"]:checked'),
                ...slideConsentsAF.querySelectorAll('input[type="checkbox"]:checked'),
            ].map(cb => cb.getAttribute("data-trackingid")).join(',')
            trackEvent('permissions', 'collected', ids)
        }


        // Consents Akasse
        var chkbxConsentAkasseCollabWithFagforening = form.querySelector("#chkbxConsentAkasseCollabWithFagforening");
        var chkbxConsentMemberOffersAkasse = form.querySelector("#chkbxConsentMemberOffersAkasse");
        var chkbxConsentPartnersAkasse = form.querySelector("#chkbxConsentPartnersAkasse");
        //var chkbxConsentSameForUnion = form.querySelector("#chkbxConsentSameForUnion");

        // Consents Fagforening
        var chkbxConsentFagforeningCollabWithAkasse = form.querySelector("#chkbxConsentFagforeningCollabWithAkasse");
        var chkbxConsentMemberOffersFagforening = form.querySelector("#chkbxConsentMemberOffersFagforening");
        var chkbxConsentPartnersFagforening = form.querySelector("#chkbxConsentPartnersFagforening");

        // Summary            
        var memberStartContainer = form.querySelector("[data-member-start]");

        // Payment
        var tbxPaymentServiceBankRegistrationNumber = form.querySelector("#tbxPaymentServiceBankRegistrationNumber");
        var tbxPaymentServiceBankAccountNumber = form.querySelector("#tbxPaymentServiceBankAccountNumber");

        // Receipt
        var receiptSalaryEnsuranceInfo = form.querySelector("[data-salary-ensurance-extra]");
        var receiptUnionInfo = form.querySelector("[data-union-extra]");
        var receiptAkasseInfo = form.querySelector("[data-akasse-extra]");

        const state = {
            slides: [],
            currentSlideIndex: -1,
            currentSlideName: "",
            buttonReady: true
        }

        var breadcrumb = [];

        // Init functions
        init();

        if (nextButtons) {
            nextButtons.forEach(b => {
                b.addEventListener('click', e => {
                    var validationResult = validateRequiredFields(true);

                    if (validationResult && state.buttonReady) {
                        state.buttonReady = false;

                        UpdateSlideFlow(e.target);

                        const nextSlideName = state.slides[(state.currentSlideIndex + 1)] ? state.slides[(state.currentSlideIndex + 1)].name : "";

                        if (nextSlideName == "Receipt") {
                            MoveToReceipt();
                        }
                        else {
                            MoveToSlide("forward");

                            onConsentsSubmitted()
                        }

                        setTimeout(() => {
                            state.buttonReady = true;
                        }, 200);
                    }
                });
            });
        }


        if (prevButtons) {
            prevButtons.forEach(b => {
                b.addEventListener('click', e => {

                    if (state.buttonReady) {
                        state.buttonReady = false;

                        clearCurrentSlideSelections();
                        MoveToSlide("backward");

                        setTimeout(() => { state.buttonReady = true; }, 200);
                    }
                });
            });
        }

        if (unionsContainer) {
            unionsContainer.addEventListener('change', e => {
                var selectedOption = e.target.options[e.target.selectedIndex];
                ToggleUnionsUnsubscribeContainer(selectedOption)
            });
        }


        if (helpBoxes) {
            helpBoxes.forEach(h => {
                h.addEventListener('click', e => {

                    var helpBox = e.target.parentElement;
                    var helpExplanation = helpBox.querySelector(".help-explanation");

                    var activeSlide = form.querySelector("[data-slide].active");

                    if (e.target.checked) {
                        var hHeight = helpExplanation.querySelector(".richtext-module").offsetHeight;
                        helpExplanation.style.height = hHeight + "px";

                        activeSlide.classList.add("help-open");
                    }
                    else {
                        helpExplanation.style.height = "0px";
                        activeSlide.classList.remove("help-open");
                    }
                });
            });
        }

        if (callButton) {
            callButton.addEventListener('click', e => {
                var validationResult = validateRequiredFields(true);
                if (validationResult) {
                    var query = createResult();

                    if (query) {
                        const url = window.location.origin + form.getAttribute("data-endpoint");

                        toggleLoadingSpinner(true);

                        fetch(url, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(query)
                        })
                            .then(data => {

                                if (data.ok) {
                                    form.querySelector(".after-send").classList.remove("collapse");
                                    form.querySelector(".before-send").classList.add("collapse");

                                    // tracking
                                    var activeSlideAnalytics = form.querySelector("[data-slide].active").getAttribute("data-analytics");
                                    if (activeSlideAnalytics) {
                                        sendVirtualPageviewTracking(activeSlideAnalytics + "-succes");
                                    }
                                }
                                else {
                                    console.log("error", data);
                                    alert("Der opstod en fejl. Kontakt venligst ASE telefonisk for at færdiggøre din tilmeldelse");
                                }

                                toggleLoadingSpinner(false);

                            })
                            .catch(err => { toggleLoadingSpinner(false); throw err; });



                    }
                }

            });
        }



        if (groupSelectors) {
            groupSelectors.forEach((groupSelector) => {
                const groupID = groupSelector.getAttribute("data-group-selector");

                // Add listener
                groupSelector.addEventListener('click', (e) => {
                    var input = e.target;

                    if (input.type == "radio") {
                        toggleShowHideGroup(groupID, input.value === "true" ? true : false);
                    }
                    else {
                        multipleGroupSelectors(input);
                    }
                });

                // Setup first
                if (groupSelector.checked) {
                    multipleGroupSelectors(groupSelector);
                }

            });

            function multipleGroupSelectors(groupSelector) {
                const groupID = groupSelector.getAttribute("data-group-selector");

                // Checkbox
                var isChecked;

                // First check if there are multiple group selectors or only one
                const multipleGroupSelectors = form.querySelectorAll(`[data-group-selector='${groupID}']`);


                if (multipleGroupSelectors && multipleGroupSelectors.length > 1) {
                    // Multiple selectors, if just one is checked, then no matter if others are unchecked, it will override as "checked"
                    const anyChecked = form.querySelectorAll(`[data-group-selector='${groupID}']:checked`);

                    isChecked = groupSelector.getAttribute("data-group-selector-reverse-show") ? (groupSelector.checked ? false : true) : (anyChecked.length > 0 ? true : false);
                }
                else {
                    // Only 1 selector
                    // Check for reverse show setting
                    isChecked = groupSelector.getAttribute("data-group-selector-reverse-show") ? (groupSelector.checked ? false : true) : groupSelector.checked;
                }


                toggleShowHideGroup(groupID, isChecked);
            }
        }

        function toggleShowHideGroup(groupID, isChecked) {
            var activeSlide = form.querySelector("[data-slide].active");

            var selectorGroup = activeSlide.querySelectorAll("[data-group-receiver]");

            if (selectorGroup) {
                selectorGroup.forEach((group) => {
                    const groupIDs = group.getAttribute("data-group-receiver");

                    if (groupIDs) {
                        const IDs = groupIDs.split(',');

                        for (let i = 0; i < IDs.length; i++) {
                            if (isChecked) {
                                if (groupID === IDs[i]) {
                                    group.classList.add("show");

                                    break;
                                }
                                else {
                                    group.classList.remove("show");
                                }
                            }
                            else {
                                // Remove
                                group.classList.remove("show");
                            }
                        }
                    }
                    else {
                        group.classList.add("show");
                    }
                });


                validateRequiredFields(false) == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");
            }
        }

        function init() {
            setupSlides();

            // Load data
            loadJsonData("UnemploymentBenefits");
            loadJsonData("Unions");

            // Validation for slides
            setupSlidesValidation();


            // reset fields
            setTimeout(() => { resetAllFields() }, 1000);
        }


        function UpdatePackage() {
            var anyCurrentUnemploymentSelected = form.querySelector("input[name='currentUnemploymentMembership']:checked");

            // Send folk videre til MitAse hvis de ønsker Fagforening og allerede har A-kasse.
            chkbxFagforening.checked && !chkbxAkasse.checked && !chkbxSalaryEnsurance.checked ? memberSignup.classList.remove("hide") : memberSignup.classList.add("hide");

            // Kun hvis ledig er valgt og ingen andre
            if (chkbxLedig.checked &&
                (!chkbxFuldtidsjob.checked && !chkbxIkkeFuldtidsjob.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked)) {


                // Ja tak til akasse og er ledig
                if (anyCurrentUnemploymentSelected && currentUnemploymentMembership.checked) {
                    // Show
                    chkbxPackageTransferASAPToASE.classList.remove("hide");

                    // Hide
                    chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.add("hide");
                    chkbxPackageNotWorkedInOtherCountry.classList.add("hide");
                    chkbxPackageWorkingPrimaryInDK.classList.add("hide");
                    chkbxPackageWantMembershipASAP.classList.add("hide");
                }
                else {
                    // Nej tak til en nuværende akasse og er ledig

                    // Show                  
                    chkbxPackageWantMembershipASAP.classList.remove("hide");


                    // Hvis akasse ikke er valgt, skal akasse relateret ikke vises
                    chkbxAkasse.checked ? chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.remove("hide") : chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.add("hide")
                    chkbxAkasse.checked ? chkbxPackageNotWorkedInOtherCountry.classList.remove("hide") : chkbxPackageNotWorkedInOtherCountry.classList.add("hide")


                    // Hide
                    chkbxPackageWorkingPrimaryInDK.classList.add("hide");
                    chkbxPackageTransferASAPToASE.classList.add("hide");
                }
            }
            else {
                // Hvis du ikke er ledig. 
                // Dvs (i job, selvstændig, arbejder i partners virksomhed eller i en kombination)
                if (anyCurrentUnemploymentSelected && currentUnemploymentMembership.checked) {
                    // show
                    chkbxPackageTransferASAPToASE.classList.remove("hide");

                    chkbxLedig.checked ? chkbxPackageWorkingPrimaryInDK.classList.add("hide") : chkbxPackageWorkingPrimaryInDK.classList.remove("hide")


                    // Hide                       
                    chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.add("hide");
                    chkbxPackageNotWorkedInOtherCountry.classList.add("hide");
                    chkbxPackageWantMembershipASAP.classList.add("hide");

                }
                else {
                    // Alt andet end ledig og nej til akasse

                    chkbxLedig.checked ? chkbxPackageWorkingPrimaryInDK.classList.add("hide") : chkbxPackageWorkingPrimaryInDK.classList.remove("hide")


                    chkbxPackageWantMembershipASAP.classList.remove("hide");

                    // Show all 

                    // If user has passed by Unemployment - Show/Hide depending on if it is selected
                    anyCurrentUnemploymentSelected ? chkbxPackageNotWorkedInOtherCountry.classList.remove("hide") : chkbxPackageNotWorkedInOtherCountry.classList.add("hide");
                    anyCurrentUnemploymentSelected ? chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.remove("hide") : chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.add("hide");

                    chkbxPackageTransferASAPToASE.classList.add("hide");
                }
            }
        }

        function UpdateSummary() {

            var unionUnsubscribeChoice = form.querySelector("[data-unions-user-unsubscribe]:not(.hide) [name='UnsubscribeCurrentUnion']:checked");

            const template = `                
            <div class="row">
                <div class="col-sm-12 col-md-6 column">Jeg vil gerne have</div>
                <div class="col-sm-12 col-md-6 column">
                    ${chkbxAkasse.checked ? 'dagpenge (a-kasse)<br />' : ''}
                    ${chkbxFagforening.checked ? 'juridisk hjælp (fagforening)<br />' : ''}
                    ${chkbxSalaryEnsurance.checked ? 'lønsikring<br />' : ''}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 column">Navn</div>
                <div class="col-sm-12 col-md-6 column">${tbxName.value}</div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 column">CPR-nummer</div>
                <div class="col-sm-12 col-md-6 column">${tbxCpr.value}</div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 column">Telefon</div>
                <div class="col-sm-12 col-md-6 column">${tbxPhone.value}</div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 column">Mail</div>
                <div class="col-sm-12 col-md-6 column">${tbxMail.value}</div>
            </div>

            ${chkbxSecretAdress.checked ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">Hemmelig adresse</div>
                    <div class="col-sm-12 col-md-6 column">
                        ${tbxSecretAdress.value ? `${tbxSecretAdress.value} <br />` : ``}
                        ${tbxSecretPostalCodeCity.value ? `${tbxSecretPostalCodeCity.value} <br />` : ``}
                    </div>
                </div>                    
                ` : ``}

            <div class="row">
                <div class="col-sm-12 col-md-6 column">Hvad laver du til dagligt?</div>
                <div class="col-sm-12 col-md-6 column">
                    ${chkbxFuldtidsjob.checked ? `${chkbxFuldtidsjob.getAttribute('data-point')}<br />` : ''}
                    ${chkbxIkkeFuldtidsjob.checked ? `${chkbxIkkeFuldtidsjob.getAttribute('data-point')}<br />` : ''}
                    ${chkbxLedig.checked ? `${chkbxLedig.getAttribute('data-point')}<br />` : ''}
                    ${chkbxStuderende.checked ? `${chkbxStuderende.getAttribute('data-point')}<br />` : ''}
                    ${chkbxNyuddannet.checked ? `${chkbxNyuddannet.getAttribute('data-point')}<br />` : ''}

                    ${chkbxOwnCompany.checked ? `${radioWorkFulltimeInOwnCompany.checked ? `${radioWorkFulltimeInOwnCompany.getAttribute('data-point')}<br />` : `${radioWorkParttimeInOwnCompany.getAttribute('data-point')}<br />`}` : ``} 

                    ${chkbxPartnersVirksomhed.checked ? `${chkbxPartnersVirksomhed.getAttribute('data-point')}<br />` : ''}     
                </div>
            </div>


            ${isSlideVisible("CurrentUnemploymentMembership") == true ? `
            
                ${form.querySelector(`[name='${currentUnemploymentMembership.name}']:checked`) ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">er du medlem af en a-kasse i dag?</div>
                    <div class="col-sm-12 col-md-6 column">${currentUnemploymentMembership.checked ? 'Ja' : 'Nej'}</div>
                </div>                  
                ` : ``} 
        
                ${currentUnemploymentMembership.checked && unemploymentBenefitsContainer && unemploymentBenefitsContainer.options ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">Nuværende a-kasse</div>
                    <div class="col-sm-12 col-md-6 column">
                        ${unemploymentBenefitsContainer.options[unemploymentBenefitsContainer.selectedIndex].value ? unemploymentBenefitsContainer.options[unemploymentBenefitsContainer.selectedIndex].value : ``}
                    </div>
                </div>                    
                ` : ``} 
            `: ``}      

            
            ${isSlideVisible("CurrentUnionMembership") == true ? `
                ${form.querySelector(`[name='${currentUnionMembership.name}']:checked`) ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">er du medlem af en fagforening i dag?</div>
                    <div class="col-sm-12 col-md-6 column">${currentUnionMembership.checked ? 'Ja' : 'Nej'}</div>
                </div>                   
                ` : ``}

        
                ${currentUnionMembership.checked && unionsContainer && unionsContainer.options ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">Nuværende fagforening</div>
                    <div class="col-sm-12 col-md-6 column">
                        ${unionsContainer.options[unionsContainer.selectedIndex].value ? unionsContainer.options[unionsContainer.selectedIndex].value : ``}
                    </div>

                    ${unionUnsubscribeChoice ? `   
                    <div class="col-sm-12 col-md-6 column">- Skal vi opsige den for dig?</div>
                    <div class="col-sm-12 col-md-6 column">${unionUnsubscribeChoice.getAttribute('data-point')}</div>                
                    ` : ``}
                </div>                    
                ` : ``}
            `: ``}      
   

            ${isSlideVisible("PartnersCVR") == true ? `
                ${chkbxOwnCompany.checked && tbxPartnersCVR.value ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">Firmas CVR-nummer</div>
                    <div class="col-sm-12 col-md-6 column">
                        ${tbxPartnersCVR.value ? `${tbxPartnersCVR.value}` : ``}
                    </div>
                </div>                    
                ` : ``}
            `: ``}      


            
            ${isSlideVisible("Package") == true ? `            
    
                ${!chkbxPackageWorkingPrimaryInDK.classList.contains("hide") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageWorkingPrimaryInDK.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageWorkingPrimaryInDK.checked ? 'Ja' : 'Nej'}</div>
                </div>                  
                ` : ``}

                ${!chkbxPackageTransferASAPToASE.classList.contains("hide") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageTransferASAPToASE.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageTransferASAPToASE.checked ? 'Ja' : 'Nej'}</div>
                </div>                  
                ` : ``}

                
                ${!chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.contains("hide") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageNotMemberOfUnemploymentBenefitsBefore.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageNotMemberOfUnemploymentBenefitsBefore.checked ? 'Ja' : 'Nej'}</div>
                </div>                  
                ` : ``}

                            
                ${!chkbxPackageNotWorkedInOtherCountry.classList.contains("hide") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageNotWorkedInOtherCountry.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageNotWorkedInOtherCountry.checked ? 'Ja' : 'Nej'}</div>

                    ${!chkbxPackageNotWorkedInOtherCountry.checked ? `   
                        <div class="col-sm-12 col-md-6 column">- ${radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslands.getAttribute('data-point')}</div>
                        <div class="col-sm-12 col-md-6 column">${radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslandsYes.checked ? 'Ja' : 'Nej'}</div>                
                    ` : ``}

                </div>                  
                ` : ``}

                ${!chkbxPackageWantMembershipASAP.classList.contains("hide") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageWantMembershipASAP.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxPackageWantMembershipASAP.checked ? 'Ja' : 'Nej'}</div>
                </div>                  
                ` : ``}
            `: ``}    

            ${isSlideVisible("ConsentsAA") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentAkasseCollabWithFagforening.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentAkasseCollabWithFagforening.checked ? 'Ja' : 'Nej'}</div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentMemberOffersAkasse.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentMemberOffersAkasse.checked ? 'Ja' : 'Nej'}</div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentPartnersAkasse.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentPartnersAkasse.checked ? 'Ja' : 'Nej'}</div>
                </div>
            `: ``}

            ${isSlideVisible("ConsentsAF") ? `
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentFagforeningCollabWithAkasse.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentFagforeningCollabWithAkasse.checked ? 'Ja' : 'Nej'}</div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentMemberOffersFagforening.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentMemberOffersFagforening.checked ? 'Ja' : 'Nej'}</div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentPartnersFagforening.getAttribute('data-point')}</div>
                    <div class="col-sm-12 col-md-6 column">${chkbxConsentPartnersFagforening.checked ? 'Ja' : 'Nej'}</div>
                </div>
            `: ``}


            `;

            const summary = form.querySelector("[data-summary]");
            summary.innerHTML = template;
        }

        function setupSlidesValidation() {
            state.slides.forEach(slide => {

                var activeSlide = form.querySelector(`[data-slide='${slide.name}']`);

                // "Tilmeld mig?"
                if (slide.name == "SignMeUp") {
                    // Setup validation so its valid if at least one is checked
                    const checkboxes = activeSlide.querySelectorAll(".checkboxlist.required input[type='checkbox']");

                    if (checkboxes) {
                        // Set events on checkboxes
                        checkboxes.forEach(c => {
                            c.addEventListener('click', e => {
                                validateCheckboxes();
                            });

                            // If one of them is already filled out
                            if (c.checked) {
                                validateCheckboxes();
                            }
                        });

                        // Update next button
                        function validateCheckboxes() {
                            activeSlide.querySelector(".checkboxlist").classList.remove("valid");
                            activeSlide.classList.remove("slide-validated");

                            checkboxes.forEach(checkbox => {

                                if (checkbox.checked) {
                                    activeSlide.querySelector(".checkboxlist").classList.add("valid");
                                    activeSlide.classList.add("slide-validated");
                                }
                            });
                        }
                    }
                }

                // "Lidt om dig?"
                if (slide.name == "AboutYou") {
                    addInputValidationToSelectedSlide(activeSlide);
                }

                // "Hvad laver du til daglig?"
                if (slide.name == "Occupation") {
                    // Setup validation so its valid if at least one is checked
                    const checkboxes = activeSlide.querySelectorAll(".checkboxlist.required input[type='checkbox']");


                    if (checkboxes) {
                        // Set events on checkboxes
                        checkboxes.forEach(c => {
                            c.addEventListener('click', e => {
                                validateCheckboxes();
                            });

                            // If one of them is already filled out
                            if (c.checked) {
                                validateCheckboxes();
                            }
                        });

                        // Setup validation so its valid if at least one is checked
                        const radioButtons = activeSlide.querySelectorAll(".checkboxlist.required input[type='radio']");

                        if (radioButtons) {
                            // Set events on checkboxes
                            radioButtons.forEach(r => {

                                r.addEventListener('click', e => {
                                    validateCheckboxes();
                                });

                                // If one of them is already filled out
                                if (r.checked) {
                                    validateCheckboxes();
                                }
                            });
                        }


                        // Update next button
                        function validateCheckboxes() {
                            var checkboxList = activeSlide.querySelector(".checkboxlist.required");
                            var isValid = false;


                            // First check if any checkboxes are selected
                            var anyCheckboxesSelected = checkboxList.querySelectorAll("input[type='checkbox']:checked");

                            anyCheckboxesSelected && anyCheckboxesSelected.length > 0 ? isValid = true : isValid = false;


                            // Next if any of the selected checkedboxes are a group, then validate if any radio buttons are checked       
                            var selectedGroupCheckboxes = checkboxList.querySelectorAll("input[type='checkbox'][data-group-selector]:checked")

                            selectedGroupCheckboxes.forEach(groupCheckbox => {
                                var groupSelectorName = groupCheckbox.getAttribute("data-group-selector");

                                var anyRadioButtonsInGroupChecked = checkboxList.querySelectorAll(`[data-required-selector='${groupSelectorName}'] input[type='radio']:checked`);

                                anyRadioButtonsInGroupChecked && anyRadioButtonsInGroupChecked.length > 0 ? isValid = true : isValid = false;
                            });

                            // Set validation
                            isValid == true ? checkboxList.classList.add("valid") : checkboxList.classList.remove("valid");
                            isValid == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");

                        }
                    }
                }

                // Akasse
                if (slide.name == "CurrentUnemploymentMembership") {
                    // Setup validation so its valid if at least one is checked
                    const radioButtons = activeSlide.querySelectorAll(".radiobuttonlist.required input[type='radio']");
                    const select = activeSlide.querySelector(".radiobuttonlist.required [data-required-selector] select");

                    if (radioButtons) {
                        // Set events on checkboxes
                        radioButtons.forEach(r => {

                            r.addEventListener('click', e => {
                                validateRadioButtons();
                            });

                            // If one of them is already filled out
                            if (r.checked) {
                                validateRadioButtons();
                            }
                        });
                    }


                    if (select) {
                        select.addEventListener('change', e => {
                            validateRadioButtons();
                        });
                    }


                    function validateRadioButtons() {
                        var radioButtonList = activeSlide.querySelector(".radiobuttonlist.required");
                        var isValid = false;


                        // First check if any radiobuttons are selected
                        var anyRadioButtonsSelected = radioButtonList.querySelectorAll("input[type='radio']:checked");

                        anyRadioButtonsSelected && anyRadioButtonsSelected.length > 0 ? isValid = true : isValid = false;


                        // Next if any of the selected radiobuttons are a group and find the select dropdown and check for a value
                        var selectedGroupRadioButtons = radioButtonList.querySelectorAll("input[type='radio'][data-group-selector][value='true']:checked");

                        selectedGroupRadioButtons.forEach(groupRadioButton => {
                            var groupSelectorName = groupRadioButton.getAttribute("data-group-selector");

                            var dropdown = radioButtonList.querySelector(`[data-required-selector='${groupSelectorName}'] select`);

                            dropdown && dropdown.value.length > 0 ? isValid = true : isValid = false;
                        });

                        // Set validation

                        if (isValid) {
                            radioButtonList.classList.add("valid");
                            radioButtonList.classList.remove("error");
                            activeSlide.classList.add("slide-validated")
                        }
                        else {
                            radioButtonList.classList.remove("valid");
                            activeSlide.classList.remove("slide-validated");
                        }

                    }

                }


                // Fagforening
                if (slide.name == "CurrentUnionMembership") {
                    // Setup validation so its valid if at least one is checked
                    const radioButtons = activeSlide.querySelectorAll(".radiobuttonlist.required input[type='radio']");
                    const select = activeSlide.querySelector(".radiobuttonlist.required [data-required-selector] select");

                    if (radioButtons) {
                        // Set events on checkboxes
                        radioButtons.forEach(r => {

                            r.addEventListener('click', e => {
                                validateRadioButtons();
                            });

                            // If one of them is already filled out
                            if (r.checked) {
                                validateRadioButtons();
                            }
                        });
                    }


                    if (select) {
                        select.addEventListener('change', e => {
                            validateRadioButtons();
                        });
                    }


                    function validateRadioButtons() {
                        var isValid = false;
                        var radioButtonList = activeSlide.querySelector(".radiobuttonlist.required");


                        // First check if any radiobuttons are selected
                        var anyRadioButtonsSelected = radioButtonList.querySelectorAll("input[type='radio']:checked");

                        anyRadioButtonsSelected && anyRadioButtonsSelected.length > 0 ? isValid = true : isValid = false;


                        // Next if any of the selected radiobuttons are a group and find the select dropdown and check for a value
                        var selectedGroupRadioButtons = radioButtonList.querySelectorAll("input[type='radio'][data-group-selector][value='true']:checked");

                        selectedGroupRadioButtons.forEach(groupRadioButton => {
                            var groupSelectorName = groupRadioButton.getAttribute("data-group-selector");
                            var dropdown = radioButtonList.querySelector(`[data-required-selector='${groupSelectorName}'] select`);

                            dropdown && dropdown.value.length > 0 ? isValid = true : isValid = false;
                        });


                        // Check if user unsubscribe is open and any radio buttons checked
                        setTimeout(() => {
                            var unionsUnsubscribeRadios = radioButtonList.querySelectorAll("[data-unions-user-unsubscribe='true']:not(.hide) input[type='radio']");

                            if (unionsUnsubscribeRadios && unionsUnsubscribeRadios.length > 0) {
                                var anyUnionUnsubscribeRadiosChecked = radioButtonList.querySelectorAll("[data-unions-user-unsubscribe='true']:not(.hide) input[type='radio']:checked");
                                anyUnionUnsubscribeRadiosChecked && anyUnionUnsubscribeRadiosChecked.length > 0 ? isValid = true : isValid = false;


                            }

                            // Set validation                      
                            if (isValid) {
                                radioButtonList.classList.add("valid");
                                radioButtonList.classList.remove("error");
                                activeSlide.classList.add("slide-validated")
                            }
                            else {
                                radioButtonList.classList.remove("valid");
                                activeSlide.classList.remove("slide-validated");
                            }

                        }, 250);

                    }

                }


                // Package - Yderligere oplysninger
                if (slide.name == "Package") {
                    // Setup validation so its valid if at least one is checked
                    const radioButtons = activeSlide.querySelectorAll(".radiobuttonlist.required input[type='radio']");

                    if (radioButtons) {
                        // Set events on checkboxes
                        radioButtons.forEach(r => {

                            r.addEventListener('click', e => {
                                validateRadioButtons();
                            });

                            // If one of them is already filled out
                            if (r.checked) {
                                validateRadioButtons();
                            }
                        });
                    }


                    function validateRadioButtons() {
                        var radioButtonList = activeSlide.querySelector(".radiobuttonlist.required");
                        var isValid = false;


                        // First check if any radiobuttons are selected
                        var anyRadioButtonsSelected = radioButtonList.querySelectorAll("input[type='radio']:checked");

                        anyRadioButtonsSelected && anyRadioButtonsSelected.length > 0 ? isValid = true : isValid = false;


                        // Set validation
                        isValid == true ? radioButtonList.classList.add("valid") : radioButtonList.classList.remove("valid");
                        isValid == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");
                    }

                }


                // PartnersCVR - Yderligere oplysninger
                if (slide.name == "PartnersCVR") {
                    addInputValidationToSelectedSlide(activeSlide);

                }

                // Payment
                if (slide.name == "Payment") {
                    var isValid = false;
                    var paymentIntervalChosen = false;
                    let textFieldsValidated = false;
                    let bsChosen = false;
                    let failedFields = [];
                    // Add validation to input fields
                    addInputValidationToSelectedSlide(activeSlide);

                    // Setup validation so its valid if at least one radio button is checked
                    const radioButtons = activeSlide.querySelectorAll(".radiobuttonlist.required input[type='radio']");
                    const radioPaymentTypes = activeSlide.querySelectorAll(".paymentinterval");
                    const radioBS = activeSlide.querySelector("#radioPaymentService");
                    const radioMBP = activeSlide.querySelector("#radioPaymentMobilePay");
                    radioBS.addEventListener('click', () => {
                        bsChosen = true;
                        radioPaymentTypes.forEach(btn => {
                            btn.checked = false;
                        });
                        activeSlide.classList.remove("slide-validated");
                    });
                    radioMBP.addEventListener('click', () => {
                        bsChosen = false;
                        radioPaymentTypes.forEach(btn => {
                            btn.checked = false;
                        });
                        activeSlide.classList.remove("slide-validated");
                        inputFields.forEach(field => {
                            field.value = null;
                        })
                    })
                    let inputFields = activeSlide.querySelectorAll("input[type='text']");
                    inputFields.forEach(field => {
                        field.addEventListener('keyup', () => {
                            failedFields = [];
                            if (inputFields) {
                                inputFields.forEach(field => {
                                    if (field.parentElement.classList.contains("error") || !field.value) {
                                        failedFields.push(field);
                                    }
                                })
                            }
                            if (!failedFields.length) {
                                textFieldsValidated = true;
                            }
                            validateRadioButtons();
                        })
                    })

                    if (radioButtons) {
                        // Set events on checkboxes
                        radioButtons.forEach(r => {
                            r.addEventListener('click', e => {
                                failedFields = [];
                                if (inputFields) {
                                    inputFields.forEach(field => {
                                        if (field.parentElement.classList.contains("error") || !field.value) {
                                            failedFields.push(field);
                                        }
                                    })
                                }
                                if (!failedFields.length) {
                                    textFieldsValidated = true;
                                }
                                validateRadioButtons();

                            });

                            // If one of them is already filled out
                            if (r.checked) {
                                validateRadioButtons();
                            }
                        });
                    }


                    function validateRadioButtons() {
                        console.log("FIELDS FAILED:", failedFields);

                        var radioButtonList = activeSlide.querySelector(".radiobuttonlist.required");

                        // First check if any radiobuttons are selected
                        var anyRadioButtonsSelected = radioButtonList.querySelectorAll("input.paymenttype:checked");
                        var anyIntervalRadioButtonsSelected = radioButtonList.querySelectorAll("input.paymentinterval:checked");
                        anyRadioButtonsSelected && anyRadioButtonsSelected.length > 0 ? isValid = true : isValid = false;
                        anyIntervalRadioButtonsSelected && anyIntervalRadioButtonsSelected.length > 0 ? paymentIntervalChosen = true : paymentIntervalChosen = false;



                        // Set validation
                        if (bsChosen) {
                            isValid == true && paymentIntervalChosen == true && textFieldsValidated == true ? radioButtonList.classList.add("valid") : radioButtonList.classList.remove("valid");
                            isValid == true && paymentIntervalChosen == true && textFieldsValidated == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");
                        } else {
                            isValid == true && paymentIntervalChosen == true ? radioButtonList.classList.add("valid") : radioButtonList.classList.remove("valid");
                            isValid == true && paymentIntervalChosen == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");
                        }


                    }
                }


                // WeNeedToTalk
                if (slide.name == "WeNeedToTalk") {
                    // Setup validation so its valid if at least one is checked
                    const checkboxes = activeSlide.querySelectorAll(".checkboxlist.required input[type='checkbox']");

                    if (checkboxes) {
                        // Set events on checkboxes
                        checkboxes.forEach(c => {
                            c.addEventListener('click', e => {
                                validateCheckboxes();
                            });

                            // If one of them is already filled out
                            if (c.checked) {
                                validateCheckboxes();
                            }
                        });

                        // Update next button
                        function validateCheckboxes() {
                            var isValid = false;
                            var checkboxlist = activeSlide.querySelector(".checkboxlist");


                            checkboxes.forEach(checkbox => {

                                if (checkbox.checked) {
                                    isValid = true;
                                }
                            });

                            // Set validation
                            isValid == true ? checkboxlist.classList.add("valid") : checkboxlist.classList.remove("valid");
                            isValid == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");
                        }
                    }
                }

            })


        }


        function UpdateSlideFlow() {
            var currentSlide = form.querySelector("section.active");
            var currentSlideName = currentSlide.getAttribute("data-slide");

            UpdatePackage();

            updateMemberStartDate();
            //updateConsents();

            // Står på "Lidt om dig?"
            if (currentSlideName == "AboutYou") {
                if (chkbxSalaryEnsurance.checked && (chkbxAkasse.checked == false && chkbxFagforening.checked == false)) {
                    // Go to "Vi ringer dig op"
                    SkipFlowTo("WeNeedToTalk");


                    // Den skal kun kunne enten fjerne eller tilføje slides

                }


            }

            // Står på "Hvad laver du til daglig?"
            if (currentSlideName == "Occupation") {
                // chkbxFuldtidsjob
                // chkbxIkkeFuldtidsjob                
                // chkbxLedig
                // chkbxStuderende
                // chkbxNyuddannet
                // chkbxOwnCompany
                // chkbxPartnersVirksomhed    

                // Fjern akasse, hvis du vil have enten fagforening eller lønsikring
                if (!chkbxAkasse.checked && (chkbxFagforening.checked || chkbxSalaryEnsurance.checked)) {
                    SkipFlowTo("CurrentUnionMembership");
                }

                if (chkbxOwnCompany.checked && radioWorkFulltimeInOwnCompany.checked && chkbxFagforening.checked && !chkbxAkasse.checked) {
                    // Hvis selvstændig, arbejder fuld tid og vil have fagforening og ikke har valgt akasse
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxPartnersVirksomhed.checked &&
                    !chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxIkkeFuldtidsjob.checked) {
                    // Hvis man kun arbejder i partners virksomhed og intet andet
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxFuldtidsjob.checked && chkbxLedig.checked &&
                    (!chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {


                }
                else if (chkbxFuldtidsjob.checked && chkbxStuderende.checked &&
                    (!chkbxLedig.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Fuldtidsjob                                  

                }
                else if (chkbxFuldtidsjob.checked && chkbxNyuddannet.checked &&
                    (!chkbxLedig.checked && !chkbxStuderende.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // chkbxFuldtidsjob og Nyuddannet - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxFuldtidsjob.checked && chkbxOwnCompany.checked &&
                    (!chkbxLedig.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Fuldtidsjob
                }
                else if (chkbxFuldtidsjob.checked && chkbxPartnersVirksomhed.checked &&
                    (!chkbxLedig.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Arbejde i partners virksomhed
                }
                else if (chkbxLedig.checked && chkbxStuderende.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxOwnCompany.checked && !chkbxNyuddannet.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Studerende og ledig - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxLedig.checked && chkbxNyuddannet.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxStuderende.checked && !chkbxPartnersVirksomhed.checked && !chkbxOwnCompany.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Nyuddannet og studerende - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxLedig.checked && chkbxOwnCompany.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxStuderende.checked && !chkbxPartnersVirksomhed.checked && !chkbxNyuddannet.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Ledig 
                }
                else if (chkbxLedig.checked && chkbxPartnersVirksomhed.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxStuderende.checked && !chkbxOwnCompany.checked && !chkbxNyuddannet.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Arbejde i partners virksomhed
                }
                else if (chkbxStuderende.checked && chkbxOwnCompany.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxPartnersVirksomhed.checked && !chkbxNyuddannet.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Studerende og own company - Ring mig op                    
                    SkipFlowTo("WeNeedToTalk");

                }
                else if (chkbxStuderende.checked && chkbxPartnersVirksomhed.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxOwnCompany.checked && !chkbxNyuddannet.checked && !chkbxIkkeFuldtidsjob.checked)
                ) {
                    // Arbejde i partners virksomhed
                }
                else if (chkbxFuldtidsjob.checked &&
                    (!chkbxLedig.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Fuldtidsjob and no other selected
                }
                else if (chkbxLedig.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxStuderende.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Ledig and no other selected
                }
                else if (chkbxStuderende.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Studerende and no other selected - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxNyuddannet.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxStuderende.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Nyuddannet and no other selected - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxIkkeFuldtidsjob.checked && chkbxFagforening.checked &&
                    (!chkbxAkasse.checked && !chkbxSalaryEnsurance.checked && !chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxStuderende.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxNyuddannet.checked)) {
                    // Ikke fuldtidsjob and only union product selected - Proceed
                    SkipFlowTo("CurrentUnionMembership");
                }
                else if (chkbxIkkeFuldtidsjob.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxStuderende.checked && !chkbxOwnCompany.checked && !chkbxPartnersVirksomhed.checked && !chkbxNyuddannet.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Ikke fuldtidsjob and no other selected - Ring mig op
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (chkbxOwnCompany.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxNyuddannet.checked && !chkbxPartnersVirksomhed.checked && !chkbxStuderende.checked && !chkbxIkkeFuldtidsjob.checked)) {


                }
                else if (chkbxPartnersVirksomhed.checked &&
                    (!chkbxFuldtidsjob.checked && !chkbxLedig.checked && !chkbxNyuddannet.checked && !chkbxOwnCompany.checked && !chkbxStuderende.checked && !chkbxIkkeFuldtidsjob.checked)) {
                    // Arbejde i partners virksomhed - Selvstændig and no other selected
                }
                else {
                    // Alle andre kombinationner leder brugeren til “vi ringer dig på”- siden, når brugeren klikker på næste


                    SkipFlowTo("WeNeedToTalk");
                }

            }



            // Står på Akasse
            if (currentSlideName == "CurrentUnemploymentMembership") {
                // Har valgt fagforening - Nej
                if (chkbxFagforening.checked == false) {
                    // Skip CurrentUnionMembership
                    SkipFlowTo("Package")
                }

                if (radioWorkFulltimeInOwnCompany.checked && chkbxFagforening.checked && chkbxAkasse.checked) {
                    // Hvis fuldtid selvstændig og med både fagforening og akasse   
                    // Skip CurrentUnionMembership and go to 
                    SkipFlowTo("Package")
                }


                // Hvis ledig, og nej til eksisterende akasse
                if (chkbxLedig.checked && radioCurrentUnemploymentMembershipNo && radioCurrentUnemploymentMembershipNo.checked) {
                    SkipFlowTo("WeNeedToTalk")
                }
            }


            // Står på Fagforening
            if (currentSlideName == "CurrentUnionMembership") {

                // Ja - Har kun valgt fagforening                    
                if (chkbxFagforening.checked && chkbxAkasse.checked == false && chkbxSalaryEnsurance.checked == false) {

                    // Skip Package and CVR
                    SkipFlowTo("ConsentsAF")

                }
            }

            // Står på Package - Yderligere oplysninger
            if (currentSlideName == "Package") {

                // Hvis der fravælges nogle af dem her, så gå til ring op
                if (
                    (chkbxPackageWorkingPrimaryInDK.checked == false && !chkbxPackageWorkingPrimaryInDK.classList.contains("hide"))

                    || (chkbxPackageNotMemberOfUnemploymentBenefitsBefore.checked == false && !chkbxPackageNotMemberOfUnemploymentBenefitsBefore.classList.contains("hide"))

                    || (chkbxPackageWantMembershipASAP.checked == false && !chkbxPackageWantMembershipASAP.classList.contains("hide"))

                    || (chkbxPackageTransferASAPToASE.checked == false && !chkbxPackageTransferASAPToASE.classList.contains("hide"))

                    || (chkbxPackageNotWorkedInOtherCountry.checked == false && !chkbxPackageNotWorkedInOtherCountry.classList.contains("hide") && radioPackageWorkedInOtherCountryEOSCountryOrFaroeIslandsYes.checked)
                ) {
                    SkipFlowTo("WeNeedToTalk");
                }
                else if (
                    (chkbxOwnCompany.checked && radioWorkFulltimeInOwnCompany.checked)
                    || (chkbxPartnersVirksomhed.checked)
                ) {
                    // Dont delete - this will show CVR number
                }
                else {
                    // Go direct to Consents
                    if (chkbxFagforening.checked && chkbxAkasse.checked == false && chkbxSalaryEnsurance.checked == false) {
                        // if only Fagforening selected:
                        SkipFlowTo("ConsentsAF")
                    } else {
                        SkipFlowTo("ConsentsAA")
                    }

                }
            }

            if (currentSlideName === "ConsentsAA") {
                /*
                if (chkbxConsentSameForUnion.checked) {
                    chkbxConsentFagforeningCollabWithAkasse.checked = chkbxConsentAkasseCollabWithFagforening.checked
                    chkbxConsentMemberOffersFagforening.checked = chkbxConsentMemberOffersAkasse.checked
                    chkbxConsentPartnersFagforening.checked = chkbxConsentPartnersAkasse.checked
                }
                */

                if (!chkbxFagforening.checked) {
                    SkipFlowTo("Summary")
                }
            }

            // Står på Payment
            if (currentSlideName == "Payment") {

                // Vis ekstra tekst omkring lønsikring på kvittering, hvis den er valgt
                if (chkbxSalaryEnsurance.checked) {
                    receiptSalaryEnsuranceInfo.classList.remove("hide");
                }

                // Vis ekstra tekst omkring fagforening på kvittering, hvis den er valgt
                if (chkbxFagforening.checked && chkbxOwnCompany.checked && radioWorkFulltimeInOwnCompany.checked) {
                    receiptUnionInfo.classList.remove("hide");
                }


                // Fjern ekstra tekst om akasse, hvis den ikke er valgt
                if (chkbxAkasse.checked == false) {
                    receiptAkasseInfo.classList.add("hide");
                }
            }

        }


        function updateBreadcrumb(direction) {
            if (direction === "forward") {
                var bArray = [];

                state.slides.forEach((slide) => {
                    const cloneSlide = Object.assign({}, slide);
                    bArray.push(cloneSlide);
                });

                breadcrumb.push(bArray);
            }
            else {
                // backward
                var indexRemoveAt = breadcrumb.length - 1;
                breadcrumb.splice(indexRemoveAt, 1);
            }


            // Set state from breadcrumb
            state.slides = breadcrumb[breadcrumb.length - 1];
        }

        function SkipFlowTo(slideDestinationName) {
            var slideDestinationNameFound = false;

            state.slides.forEach((slide, index) => {
                if (index > state.currentSlideIndex && slideDestinationNameFound == false) {
                    slide.visibility = false;
                }

                if (slideDestinationName == slide.name) {
                    slide.visibility = true;
                    slideDestinationNameFound = true;
                }
            });
        }


        function setupSlides() {
            sections.forEach(s => {

                var slide = {
                    name: s.getAttribute("data-slide"),
                    visibility: true,
                    active: false
                }


                // Push slide to state
                state.slides.push(slide)
            });

            // If any slides exist, set the first one as active
            if (state.slides.length > 0) {
                MoveToSlide("forward");
            }
        }

        function MoveToSlide(direction) {
            // Set new active slide
            var newSlideFound = false;

            if (direction == "forward") {
                // Move to the next slide
                state.slides.forEach((slide, index) => {
                    if (newSlideFound == false) {
                        if (index > state.currentSlideIndex && slide.visibility == true) {
                            state.slides[state.currentSlideIndex == -1 ? 0 : state.currentSlideIndex].active = false;

                            slide.active = true;
                            state.currentSlideIndex = index;
                            newSlideFound = true;
                        }
                    }
                });
            }

            if (direction == "backward") {
                for (var i = state.slides.length - 1; i >= 0; i--) {
                    var slide = state.slides[i];
                    slide.active = false;

                    if (newSlideFound == false && slide && i < state.currentSlideIndex && slide.visibility == true) {
                        slide.active = true;
                        state.currentSlideIndex = i;
                        newSlideFound = true;
                    }
                }
            }


            // Update render
            sections.forEach((section, index) => {
                section.classList.remove("active");

                if (state.currentSlideIndex == index) {
                    section.classList.add("active");
                    state.currentSlideName = section.getAttribute("data-slide");

                    //updateSlideRenderings(direction);

                    sendVirtualPageviewTracking(section.getAttribute("data-analytics"));


                    // Scroll to top of module unless its the first page
                    if (state.currentSlideName != "SignMeUp") {
                        // Scroll to top unless receipt if because of video animation
                        let isMobile = window.matchMedia("(max-width: 767px)").matches;

                        if (state.currentSlideName != "Receipt" || isMobile) {
                            scrollToSlideTop();
                        }
                    }

                }
            });

            updateTimeline();


            updateBreadcrumb(direction);



            // Update summary
            UpdateSummary();

        }

        function updateTimeline() {
            var visibleSlides = [];

            var activeIndex = 0;


            state.slides.forEach((s, i) => {
                if (s.visibility == true) {
                    visibleSlides.push(s);

                    // Set where active is
                    if (s.active == true) {
                        activeIndex = i;
                    }
                }
            });



            var progress = activeIndex / visibleSlides.length;

            progress = progress.toFixed(2);

            if (progress <= 0) progress = 0.05;

            timeline.style.transform = `scaleX(${progress})`;
        }



        // Validation
        function addInputValidationToSelectedSlide(activeSlide) {
            const requiredFields = activeSlide.querySelectorAll(".required");

            if (requiredFields) {
                requiredFields.forEach(requiredField => {
                    var input = requiredField.querySelector("input");

                    if (input) {
                        // Alt undtaget checkbox og radio
                        if (input.type != "checkbox" || input.type != "radio") {
                            input.addEventListener('blur', (evt) => {
                                validateField(requiredField);


                                validateRequiredFields(false) == true ? activeSlide.classList.add("slide-validated") : activeSlide.classList.remove("slide-validated");

                            });
                        }

                    }
                });
            }
        }


        function validateField(requiredField) {
            var input = requiredField.querySelector("input");

            // if input is only visible as part of a group checkbox/radiobutton, then only validate it if it is visible
            // Not sure about this one
            input = requiredField.getAttribute("data-group-receiver") && requiredField.classList.contains("show") == false ? null : input;

            if (input) {
                const type = input.getAttribute("type");
                const customValdation = input.getAttribute("custom-validation");

                if (customValdation) {

                    var pattern = new RegExp(customValdation);
                    var isValid = pattern.test(input.value);

                    if (input.id === "tbxCpr") {
                        let cpr = input.value;
                        cpr = cpr.replace("-", "").trim();

                        if (isValid && cpr.length > 5) {
                            const day = cpr.substr(0, 2);
                            const month = cpr.substr(2, 2);
                            const year = cpr.substr(4, 2);
                            const date = new Date(year, (+month - 1), day);
                            isValid = (Boolean(+date) && date.getDate() == day && month >= 1 && month <= 12 && day >= 1 && day <= 31 && year > -1 && year <= 99);
                        }
                    }


                    if (!isValid) {
                        requiredField.classList.add("error");
                        requiredField.classList.remove("warning", "valid");
                        return false;
                    }
                    else if (input.value.length === 9) {
                        requiredField.classList.remove("error");
                        requiredField.classList.add("warning", "valid");
                    }
                    else {
                        requiredField.classList.remove("error", "warning");
                        requiredField.classList.add("valid");
                    }
                }
                else if (type === "text") {
                    if (input.value.length === 0) {
                        requiredField.classList.add("error");
                        requiredField.classList.remove("valid");
                        return false;
                    }
                    else {
                        requiredField.classList.remove("error");
                        requiredField.classList.add("valid");
                    }
                }

                else if (type === "email") {
                    var isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.value);

                    if (!isEmailValid) {
                        requiredField.classList.add("error");
                        requiredField.classList.remove("valid");

                        return false;
                    }
                    else {
                        requiredField.classList.remove("error");
                        requiredField.classList.add("valid");
                    }
                }
                else {
                    return true;
                }


            }

            return false;
        }

        function validateRequiredFields(scrollToError) {
            // Find out which required fields should be validated
            var activeSlide = form.querySelector("[data-slide].active");
            var requiredFields = activeSlide.querySelectorAll(".required");

            var result = true;
            var isChecked;

            requiredFields.forEach(requiredField => {

                var groupRequiredSelector = requiredField.getAttribute("data-required-selector");

                // Depending on group is visible
                if (groupRequiredSelector) {
                    var groupSelectorDependancy = form.querySelector(`[data-group-selector='${groupRequiredSelector}']`);

                    isChecked = groupSelectorDependancy.getAttribute("data-group-selector-reverse-show") ? (groupSelectorDependancy.checked ? false : true) : groupSelectorDependancy.checked;

                    // If group is visible, validate the field
                    if (groupSelectorDependancy && isChecked) {
                        if (requiredField.classList.contains("valid") != true) {
                            result = false;
                        }
                    }
                }
                else {
                    // Is a single field

                    if (requiredField.classList.contains("valid") != true) {
                        result = false;
                    }
                }


            });

            // Scroll to required
            if (result === false && scrollToError) {
                var firstError = activeSlide.querySelector(".required:not(.valid)");

                if (firstError) {
                    firstError.classList.add("error");
                    const yPos = getElementPosition(firstError).top - 120;

                    window.scrollTo({
                        top: yPos,
                        behavior: 'smooth'
                    })
                }

            }

            return result;
        }



        function updateMemberStartDate() {
            const danishMonths = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"]
            var m;







            if (chkbxLedig.checked) {
                /*  Ledige:  Den 1. efter der er gået mindst en måned (der skal altså gå en måned fra i dag og så er det den næste 1. efter det.  */
                m = moment().date(1).add(2, 'months');
            }
            else if (
                (form.querySelector(`[name='${currentUnemploymentMembership.name}']:checked`) && currentUnemploymentMembership.checked == false) ||
                (form.querySelector(`[name='${currentUnionMembership.name}']:checked`) && currentUnionMembership.checked == false)) {
                /*   I Job:  Ikke allerede medlem af fagforening/a-kasse:  Fra dags dato*/
                m = moment();
            }
            else {
                /*   I Job:  Allerede medlem af fagforening/a-kasse::  Fra den 1. i næste måned*/
                m = moment().date(1).add(1, 'months');
            }

            const monthName = danishMonths[m.month()];

            var startDate = `${m.format("D.")} ${monthName} ${m.format("YYYY")}`;

            if (memberStartContainer) {
                memberStartContainer.innerText = startDate;
            }
        }




        function loadJsonData(type) {
            if (type == "UnemploymentBenefits") {
                var url = '/assets/data/unemployment-benefits-list.json';

                fetch(url)
                    .then(res => res.json())
                    .then((data) => {
                        if (data && data.unemploymentBenefits && unionsContainer) {
                            renderUnemploymentBenefits(data);
                        }
                    })
                    .catch(err => { throw err });
            }

            if (type == "Unions") {
                var url = '/assets/data/unions_old.json';

                fetch(url)
                    .then(res => res.json())
                    .then((data) => {
                        if (data && data.unions && unionsContainer) {
                            renderUnions(data);
                        }
                    })
                    .catch(err => { throw err });
            }
        }

        function renderUnemploymentBenefits(data) {
            // Sort
            const myLocale = 'da-DK'; // danish locale
            const sortedData = data.unemploymentBenefits.sort((a, b) => a.name.localeCompare(b.name.toLowerCase(), myLocale));

            const template = ` 
                <option value="">Vælg nuværende a-kasse</option>                 
                ${sortedData.map(u => `<option value="${u.name}">${u.name}</option>`).join('')}           
            `

            unemploymentBenefitsContainer.innerHTML = template;
        }

        function renderUnions(data) {
            // Sort
            const myLocale = 'da-DK'; // danish locale
            const sortedUnions = data.unions.sort((a, b) => a.name.localeCompare(b.name.toLowerCase(), myLocale));

            const template = ` 
                <option value="">Vælg nuværende fagforening</option>                 
                ${sortedUnions.map(u => {
                return `<option value="${u.name}" data-user-unsubscribe="${u.userUnsubscribe ? 'true' : 'false'}">${u.name}</option>`
            }).join('')}           
            `

            unionsContainer.innerHTML = template;
        }



        function ToggleUnionsUnsubscribeContainer(selectedOption) {
            var unionsUnsubcribeContainerTrue = form.querySelector("[data-unions-user-unsubscribe='true']");
            var unionsUnsubcribeContainerFalse = form.querySelector("[data-unions-user-unsubscribe='false']");
            var unionsContainerEmptyValue = form.querySelector("[data-unions-empty]");

            // Hide all
            unionsUnsubcribeContainerTrue.classList.add("hide");
            unionsUnsubcribeContainerFalse.classList.add("hide");
            unionsContainerEmptyValue.classList.add("hide");

            if (selectedOption.getAttribute("data-user-unsubscribe") == "true") {
                unionsUnsubcribeContainerTrue.classList.remove("hide");
            }
            else if (selectedOption.getAttribute("data-user-unsubscribe") == "false") {
                unionsUnsubcribeContainerFalse.classList.remove("hide");
                unionsContainerEmptyValue.classList.remove("hide");
            }
            else if (selectedOption.value == "") {
                unionsContainerEmptyValue.classList.remove("hide");
            }
        }


        function createResult() {
            var data = {
                "type": state.currentSlideName === "WeNeedToTalk" ? "call" : "signup",
                "name": tbxName.value,
                "email": tbxMail.value,
                "phone": tbxPhone.value,
                "cpr": tbxCpr.value,
                "values": []
            };



            // Receipt - create object to show if call is needed for further information
            var isCallNeededForSalaryEnsuranceInfo = !receiptSalaryEnsuranceInfo.classList.contains("hide");
            var isCallNeededForUnionInfo = !receiptUnionInfo.classList.contains("hide");

            if (isCallNeededForSalaryEnsuranceInfo || isCallNeededForUnionInfo) {
                var valueArray = [];

                if (isCallNeededForSalaryEnsuranceInfo) {
                    valueArray.push("Lønsikring");
                }

                if (isCallNeededForUnionInfo) {
                    valueArray.push("Juridisk hjælp");
                }

                var callData = {
                    "name": "kræver opkald ang",
                    "id": "requiresCall",
                    "value": valueArray.join(", ")
                }

                data.values.push(callData);
            }


            // Get all visible slides
            const slidesResult = [];

            state.slides.forEach(slide => {
                if (slide.visibility) {
                    slidesResult.push(slide);
                }
            });


            // Find all inputs from each slide
            slidesResult.forEach(slide => {

                // Group point input on slide
                var groupPointInput = form.querySelector(`[data-slide='${slide.name}'] [data-point-group]:not(.hide)`);

                if (groupPointInput) {
                    var inputPointGroup = groupPointInput.getAttribute("data-point-group");

                    if (inputPointGroup) {
                        var inputPointGroupSpecific = form.querySelectorAll(`[data-slide=${slide.name}] [data-point-group='${inputPointGroup}']:not(.hide)`);

                        var valuesArr = [];

                        inputPointGroupSpecific.forEach(i => {
                            // If checkbox or radio and not a group selector
                            if (i.checked && i.getAttribute("data-point-group-exclude") == null) {
                                valuesArr.push(i.getAttribute("data-point"))
                            }
                        })

                        // Create common group point
                        var d = {
                            "name": inputPointGroup,
                            "id": inputPointGroup,
                            "value": valuesArr.join(", ")
                        }

                        data.values.push(d);
                    }
                }


                // Single points to be stored in "values"
                var singlePointInputs = form.querySelectorAll(`[data-slide=${slide.name}] [data-point]:not([data-point-group]):not(.hide)`);

                if (singlePointInputs) {
                    singlePointInputs.forEach(input => {
                        // single point in umbraco
                        // All have this
                        var d = {
                            "name": input.getAttribute("data-point") ? input.getAttribute("data-point") : "",
                            "id": input.id ? input.id : ""
                        }

                        if (input.type == "radio") {
                            if (input.checked) {
                                d.value = input.checked
                                data.values.push(d);
                            }
                        }
                        else if (input.type == "checkbox") {
                            d.value = input.checked; // true/false 
                            data.values.push(d);
                        }
                        else {
                            // text boxes and selected values
                            if (input.value) {
                                d.value = input.value ? input.value : null;
                                data.values.push(d);
                            }
                        }

                    });
                }
            });

            console.log("DATA:", data);
            return data;
        }


        function MoveToReceipt() {
            // Afventer "succes" resultat fra endpoint 
            var query = createResult();

            if (query) {
                const url = window.location.origin + form.getAttribute("data-endpoint");

                toggleLoadingSpinner(true);

                fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(query)
                })
                    .then(data => {
                        if (data.ok) {
                            // Change to slide
                            MoveToSlide("forward");
                        }
                        else {
                            console.log("error", data);
                            alert("Der opstod en fejl. Kontakt venligst ASE telefonisk for at færdiggøre din tilmeldelse");
                        }

                        toggleLoadingSpinner(false);
                    })
                    .catch(err => { toggleLoadingSpinner(true); throw err; });
            }
        }




        function getElementPosition(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function scrollToSlideTop(isSmoothScroll) {
            var currentSlide = form.querySelector("[data-slide].active");
            let pos = getElementPosition(currentSlide);

            var header = document.querySelector("header");

            if (header) {
                pos.top = pos.top - header.offsetHeight;
            }

            if (isSmoothScroll == null) {
                window.scrollTo({
                    top: pos.top
                })
            }
            else {
                window.scrollTo({
                    top: pos.top,
                    behavior: 'smooth'
                })
            }
        }

        function resetAllFields() {
            // Personal info
            if (tbxName) tbxName.value = "";
            if (tbxCpr) tbxCpr.value = "";
            if (tbxPhone) tbxPhone.value = "";
            if (tbxMail) tbxMail.value = "";
            if (tbxSecretAdress) tbxSecretAdress.value = "";
            if (tbxSecretPostalCodeCity) tbxSecretPostalCodeCity.value = "";

            // CVR
            if (tbxPartnersCVR) tbxPartnersCVR.value = "";

            // Payment
            if (tbxPaymentServiceBankRegistrationNumber) tbxPaymentServiceBankRegistrationNumber.value = "";
            if (tbxPaymentServiceBankAccountNumber) tbxPaymentServiceBankAccountNumber.value = "";
        }

        function clearCurrentSlideSelections() {
            var currentSlide = form.querySelector(`[data-slide='${state.currentSlideName}']`);

            // Akasse
            if (state.currentSlideName === "CurrentUnemploymentMembership") {
                // Clear inputs
                var inputs = currentSlide.querySelectorAll(`[name='currentUnemploymentMembership']`);
                inputs.forEach(i => { i.checked = false });

                unemploymentBenefitsContainer.selectedIndex = 0;

                // remove "valid" fields
                var requiredFields = currentSlide.querySelectorAll(".required");
                requiredFields.forEach(r => { r.classList.remove("valid") });
                currentSlide.classList.remove("slide-validated");
            }

            // Fagforening
            if (state.currentSlideName === "CurrentUnionMembership") {
                // Clear inputs
                var inputs = currentSlide.querySelectorAll(`[name='currentUnionMembership']`);
                inputs.forEach(i => { i.checked = false });

                unionsContainer.selectedIndex = 0;

                // remove "valid" fields
                var requiredFields = currentSlide.querySelectorAll(".required");
                requiredFields.forEach(r => { r.classList.remove("valid") });
                currentSlide.classList.remove("slide-validated");
            }


            // CVR
            if (state.currentSlideName === "PartnersCVR") {
                // Clear inputs
                var inputs = currentSlide.querySelectorAll(`input[data-point]`);
                inputs.forEach(i => { i.value = "" });

                // remove "valid" fields
                var requiredFields = currentSlide.querySelectorAll(".required");
                requiredFields.forEach(r => { r.classList.remove("valid") });
                currentSlide.classList.remove("slide-validated");
            }
        }


        function isSlideVisible(name) {
            var found = false;

            state.slides.forEach(slide => {
                if (slide.name === name && slide.visibility == true) {
                    found = true;
                }
            })

            return found;
        }

        function sendVirtualPageviewTracking(name) {
            if (window.dataLayer) {
                var nameTitle = name.replace(/-/g, ' ');

                window.dataLayer.push({
                    event: 'virtual-pageview',
                    virtualPageURL: `/virtual-pageview/indmeldingsformular/${name}`,
                    virtualPageTitle: `indmeldingsformular – ${nameTitle}`
                });
            }
        }

        // trackEvent('permissions', 'started', 'checkbox-ID')
        function trackEvent(eventId, contentName, contentType) {
            if (window.dataLayer && window.dataLayer.length) {
                const data = {
                    'event': eventId,
                    'content_name': contentName,
                    'content_type': contentType
                };
                window.dataLayer.push(data);
            }


        }


        function toggleLoadingSpinner(show) {
            var spinner = form.querySelector("[data-spinner]");

            if (spinner) {
                if (show == true) {
                    spinner.classList.add("show")
                    setTimeout(() => { spinner.classList.remove("show") }, 60000);
                }
                else {
                    spinner.classList.remove("show")
                }
            }
        }


    }
}

