import { SpringValue } from "@react-spring/web";
import { SubTitle, Title, Wrapper } from "./ErrorScreen.styles";
import * as SuccessAnimation from "./ErrorAnimation.json";
import Lottie from "react-lottie";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function ErrorScreen({ style }: Props) {
  return (
    <Wrapper style={style}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: SuccessAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={130}
        width={130}
        style={{ marginBottom: 20 }}
      />

      <Title>Der opstod en fejl!</Title>
      <SubTitle>Prøv venligst igen senere.</SubTitle>
    </Wrapper>
  );
}
