import { SpringValue } from "@react-spring/web";
import { Button, Label, ResultValue, ResultWrapper, Row, Title, Value, Wrapper } from "./CalculatorScreen.styles";
import { HiPlus, HiMinus } from "react-icons/hi";
import { SalaryInsuranceItemModel } from "../../../../types/SalaryInsuranceItemModel";
import MainButton from "../../../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../store/store";
import { SalaryInsuranceStateEnum } from "../../../../types/SalaryInsuranceStateEnum";
import SalaryBarChart from "../../components/SalaryBarChart";
import Analytics from "../../../../utils/analytics";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function CalculatorScreen({ style }: Props) {
  const [selectedSalaryInsuranceItem, setSelectedSalaryInsuranceItem] = useState<SalaryInsuranceItemModel | undefined>();
  const [selectedSalaryInsuranceItemIndex, setSelectedSalaryInsuranceItemIndex] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const salaryInsuranceData = useStore.useSalaryInsuranceData();
  const thresholdGrossPay = useStore.useThresholdGrossPay();
  const basicOptions = useStore.useBasicOptions();
  const extendedOptions = useStore.useExtendedOptions();
  const unemploymentInsuranceAmount = useStore.useUnemploymentInsuranceAmount();
  const setSalarySetInsuranceData = useStore.useSetSalaryInsuranceData();
  const setSalarySetInsuranceState = useStore.useSetSalaryInsuranceState();

  const grosspayNumber = parseInt(salaryInsuranceData.grosspay.replace(/[^0-9]/g, "") || "0");

  const { isBasic, options } = useMemo<{ options: SalaryInsuranceItemModel[]; isBasic: boolean }>(() => {
    let options = [];
    if (grosspayNumber < thresholdGrossPay) {
      options = basicOptions.filter((item) => item.loen < grosspayNumber);
    } else {
      options = extendedOptions.filter((item) => item.loen < grosspayNumber);
    }

    if (options.length > 0) {
      const index = Math.floor(options.length / 2);
      console.log(index);
      setSelectedSalaryInsuranceItemIndex(index);
      setSelectedDuration(0);
    }

    return {
      options,
      isBasic: grosspayNumber < thresholdGrossPay,
    };
  }, [grosspayNumber, basicOptions, extendedOptions, thresholdGrossPay]);

  useEffect(() => {
    setSelectedSalaryInsuranceItem(options[selectedSalaryInsuranceItemIndex]);
  }, [options, selectedSalaryInsuranceItemIndex]);

  const { duration, price } = useMemo(() => {
    switch (selectedDuration) {
      case 0:
        setSalarySetInsuranceData({ payoutduration: 3 });
        return {
          duration: 3,
          price: selectedSalaryInsuranceItem?.three_mnd ?? 0,
        };
      case 1:
        setSalarySetInsuranceData({ payoutduration: 6 });
        return {
          duration: 6,
          price: selectedSalaryInsuranceItem?.six_mnd ?? 0,
        };
      default:
        setSalarySetInsuranceData({ payoutduration: 12 });
        return {
          duration: 12,
          price: selectedSalaryInsuranceItem?.twelve_mnd ?? 0,
        };
    }
  }, [selectedDuration, selectedSalaryInsuranceItem]);

  useEffect(() => {
    setSalarySetInsuranceData({
      dinpris: price.toString(),
      payout: selectedSalaryInsuranceItem?.maksimal.toString() || "0",
      product: isBasic ? "Basis" : "Udvidet",
    });
  }, [selectedSalaryInsuranceItem, isBasic, price]);

  const maxDuration = isBasic ? 1 : 2;

  const onNextClick = () => {
    Analytics.getInstance().trackSalaryInsuranceAction("selected", grosspayNumber, selectedSalaryInsuranceItem?.maksimal || 0, duration, price, isBasic ? "basis" : "udvidet");
    setSalarySetInsuranceState(SalaryInsuranceStateEnum.FORM_SCREEN);
  };

  return (
    <Wrapper style={style}>
      <Title>Vores anbefaling til dig:</Title>

      <Label>Dagpenge</Label>
      <Row>
        <Value>{new Intl.NumberFormat("da-dk").format(unemploymentInsuranceAmount)} kr.</Value>
      </Row>

      <Label>Dækning</Label>
      <Row>
        <Button
          onClick={() => {
            if (selectedSalaryInsuranceItemIndex - 1 >= 0) {
              setSelectedSalaryInsuranceItemIndex(selectedSalaryInsuranceItemIndex - 1);
            }
          }}
          disabled={selectedSalaryInsuranceItemIndex === 0}
        >
          <HiMinus color="white" />
        </Button>
        <Value>{new Intl.NumberFormat("da-dk").format(selectedSalaryInsuranceItem?.maksimal || 0)} kr.</Value>
        <Button
          onClick={() => {
            if (selectedSalaryInsuranceItemIndex + 1 < options.length) {
              setSelectedSalaryInsuranceItemIndex(selectedSalaryInsuranceItemIndex + 1);
            }
          }}
          disabled={selectedSalaryInsuranceItemIndex + 1 === options.length}
        >
          <HiPlus color="white" />
        </Button>
      </Row>

      <Label>Samlet indkomst:</Label>
      <Row>
        <Value>{new Intl.NumberFormat("da-dk").format(selectedSalaryInsuranceItem?.dagpenge || 0)} kr.</Value>
      </Row>

      <Label>Udbetalingsperiode:</Label>
      <Row>
        <Button
          onClick={() => {
            if (selectedDuration - 1 >= 0) {
              setSelectedDuration(selectedDuration - 1);
            }
          }}
          disabled={selectedDuration === 0}
        >
          <HiMinus color="white" />
        </Button>
        <Value>{duration} mdr.</Value>
        <Button
          onClick={() => {
            if (selectedDuration + 1 <= maxDuration) {
              setSelectedDuration(selectedDuration + 1);
            }
          }}
          disabled={selectedDuration === maxDuration}
        >
          <HiPlus color="white" />
        </Button>
      </Row>

      <ResultWrapper>
        <span>Din pris:</span>
        <ResultValue>{price}</ResultValue>
        <span>kr./md.</span>
      </ResultWrapper>

      <SalaryBarChart baseCoverage={unemploymentInsuranceAmount} salaryInsurance={selectedSalaryInsuranceItem?.maksimal || 0} salary={grosspayNumber} />

      <MainButton style={{ width: "100%", maxWidth: 300 }} onClick={onNextClick}>
        Se tilbud
      </MainButton>
    </Wrapper>
  );
}
