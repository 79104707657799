import { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownItem, Wrapper } from "./AddressInput.styles";
import TextInput from "../TextInput";
import { useOnClickOutside } from "../../hooks/useClickOutside";
import { lookupAddress } from "../../utils/api";
import Spinner from "../Spinner";
import { AddressLookupResponse } from "../../types/AddressLookupResponse";
import { useKeyPress } from "../../hooks/useKeyPress";

export interface AddressData {
  houseNumber: string;
  floor: string;
  door: string;
  address: string;
  addressText: string;
  city: string;
  zipCode: string;
}

interface AddressInputProps {
  label?: string;
  initValue?: string;
  error?: string;
  placeholder?: string;
  onAddressSelected?: (address: AddressData) => void;
}

export default function AddressInput({ label, initValue, error, placeholder, onAddressSelected }: AddressInputProps) {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const [addressInputInFocus, setAddressInputInFocus] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [newSearch, setNewSerach] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [value, setValue] = useState(initValue ?? "");
  const [addressListResponse, setAddressListResponse] = useState<AddressLookupResponse[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterDownPressed = useKeyPress("Enter");

  useOnClickOutside(dropdownWrapperRef, () => {
    setAddressInputInFocus(false);
  });

  const fetchAddresses = async (address: string, caretpos: string, startFrom?: string, addressId?: string) => {
    if (!address) return;
    try {
      setIsLoadingAddress(true);
      const response = await lookupAddress(address, caretpos, startFrom, addressId);

      setAddressListResponse(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  const handleAddressChange = async (address: string, caretpos: string, startFrom?: string, addressId?: string) => {
    await fetchAddresses(address, caretpos, startFrom, addressId);
  };

  const onReset = () => {
    onAddressSelected &&
      onAddressSelected({
        houseNumber: "",
        floor: "",
        door: "",
        address: "",
        addressText: "",
        city: "",
        zipCode: "",
      });
    setIsInvalid(true);
  };

  const onSelectAddress = (address: AddressLookupResponse) => {
    if (address?.type === "adresse") {
      onAddressSelected &&
        onAddressSelected({
          houseNumber: address.data.husnr,
          floor: address.data.etage ?? "",
          door: address.data.dør ?? "",
          address: address.data.vejnavn,
          addressText: address.tekst,
          city: address.data.postnrnavn,
          zipCode: address.data.postnr,
        });
      setAddressInputInFocus(false);
      setIsInvalid(false);
    } else if (address?.data.id) {
      handleAddressChange(address.tekst, address.tekst.length.toString(), undefined, address.data.id);
    } else {
      handleAddressChange(address.tekst, address.tekst.length.toString(), "adgangsadresse", undefined);
    }

    setNewSerach(true);
    setValue(address.tekst);
  };

  useEffect(() => {
    if (newSearch && addressListResponse.length === 1 && addressListResponse[0].type === "adresse") {
      const addressData = addressListResponse[0];
      onAddressSelected &&
        onAddressSelected({
          houseNumber: addressData.data.husnr,
          floor: addressData.data.etage ?? "",
          door: addressData.data.dør ?? "",
          address: addressData.data.vejnavn,
          addressText: addressData.tekst,
          city: addressData.data.postnrnavn,
          zipCode: addressData.data.postnr,
        });
      setValue(addressData.tekst);
      setNewSerach(false);
      setIsInvalid(false);
      setAddressInputInFocus(false);
    }
  }, [addressListResponse, newSearch]);

  useEffect(() => {
    if (enterDownPressed && addressListResponse && selectedIndex >= 0) {
      const selectedAddress = addressListResponse[selectedIndex];
      onSelectAddress(selectedAddress);
    }
  }, [enterDownPressed, addressListResponse]);

  useEffect(() => {
    if (addressListResponse) {
      if (arrowDownPressed && selectedIndex + 1 < addressListResponse.length) {
        setSelectedIndex(selectedIndex + 1);
      }

      if (arrowUpPressed && selectedIndex - 1 >= 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  }, [arrowDownPressed, arrowUpPressed, addressListResponse]);

  return (
    <Wrapper ref={dropdownWrapperRef}>
      <TextInput
        label={label}
        value={value}
        onChange={(e) => {
          handleAddressChange(e.currentTarget.value, e.currentTarget.selectionStart?.toString() ?? "");
          setValue(e.currentTarget.value);
          onReset();
        }}
        onFocus={() => setAddressInputInFocus(true)}
        type="text"
        autoComplete="street-address"
        placeholder={placeholder}
        pattern=".{1,}"
        id="address"
        error={error}
        hasError={isInvalid && !addressInputInFocus}
        postfix={<Spinner show={isLoadingAddress} />}
      />

      {addressInputInFocus && addressListResponse.length > 0 && (
        <Dropdown>
          <div className="dropdown-content">
            {addressListResponse.map((address, index) => (
              <DropdownItem key={index} isFocused={selectedIndex === index} onClick={() => onSelectAddress(address)}>
                {address.forslagstekst}
              </DropdownItem>
            ))}
          </div>
        </Dropdown>
      )}
    </Wrapper>
  );
}
