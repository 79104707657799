import { useEffect, useMemo, useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import CheckCard from "../../../../components/CheckCard";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";
import Analytics from "../../../../utils/analytics";
import { formatNumber } from "../../../../utils/formatNumber";
import { SalaryInsurance, Subtitle } from "./ProductStep.styles";
import { ProductCardWrapper } from "./ProductStep.styles";
import ProductCard from "../../../../components/ProductCard";
import { ProductTypesEnum } from "../../../../types/ProductTypesEnum";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import { Value } from "../../../SalaryInsuranceCalculator/screens/CalculatorScreen/CalculatorScreen.styles";
import GiftCardPopup from "../../../../components/GiftCardPopup";
import { OfferText } from "../../../../components/CheckCard/CheckCard.styles";


export default function ProductsStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const offerEnabled = useStore.useOfferEnabled();
  const setRouteStack = useStore.useSetRouteStack();
  const [unemploymentPrice, setUnemploymentPrice] = useState<string>( );
  const [offerText, setOfferText] = useState("");

  useEffect(() => {
    if (t)  {
      let offetTextCombined = t?.general.offerHeaderIntro + t?.general.offerHeader
      setOfferText(offetTextCombined)
    }
  }, []); 

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get("produkt");
    const productList = product?.split("|");

    if (product) {
      setPayload({
        wantsUnemploymentBenefits: false,
        wantsSalaryInsurance: false,
        wantsUnion: false,
        wantsHRJura: false,
        wantsBasic: false,
        wantsFamilyUnion: false,
      });
    }

    if (productList?.includes("akasse")) {
      setPayload({
        wantsUnemploymentBenefits: true,
      });
    }

    if (productList?.includes("fagforening") && payload.occupation !== "SelfEmployed") {
      setPayload({
        wantsUnion: true,
      });
    }

    if (productList?.includes("hrjura") && payload.occupation === "SelfEmployed") {
      setPayload({
        wantsHRJura: true,
      });
    }

    if (productList?.includes("loensikring")) {
      setPayload({
        wantsSalaryInsurance: true,
      });
    }

    if (productList?.includes("basis") && payload.occupation === "SelfEmployed") {
      setPayload({
        wantsBasic: true,
      });
    }

    if (productList?.includes("familiefagforening") && (payload.occupation === "Employee" || payload.occupation === "Unemployed")) {
      setPayload({
        wantsFamilyUnion: true,
      });
    }
  }, []);


  const getUnemploymentPrice = useMemo(() => {
    if(payload.occupation === "Student") {
      return 0;
    } else if (payload.occupation === "SelfEmployed") {
      return t?.productsStep.unemploymentInsurance.price_selfemployed
    } else {
      return t?.productsStep.unemploymentInsurance.price
    }
  }, [payload, payload.occupation, t]);

  const choosenUnemploymentPrice = useMemo(() => {
    if (payload.wantsUnemploymentBenefits && payload.occupation === "SelfEmployed") {
      return t?.productsStep.unemploymentInsurance.price_selfemployed
    } else if(payload.wantsUnemploymentBenefits && payload.occupation !== "SelfEmployed") {
      return t?.productsStep.unemploymentInsurance.price
    } else {
      return 0
    }
  }, [payload, payload.occupation, t])
  
  const rows = useMemo(() => {
    const r = [];
    //Price for familyUnion when there is no offer
    /* const FamilyUnionPrice = payload.wantsFamilyUnion && payload.occupation !== "SelfEmployed" ? Number(t?.productsStep.familyUnion.price) : 0; */
    const FamilyUnionPrice = payload.wantsUnemploymentBenefits ? 0 : Number(t?.productsStep.familyUnion.price);
    const UnemploymentInsurancePrice = Number(choosenUnemploymentPrice);
    let UnionPrice = 0;

    if (payload.wantsUnion && (payload.occupation === "Unemployed" || payload.occupation === "Employee" || payload.occupation === "Student")) {
      if (offerEnabled && payload.wantsUnemploymentBenefits) {
        UnionPrice = 0;
      } else {
        UnionPrice = Number(t?.productsStep.union.price);
      }
    } else if (payload.wantsUnion && (payload.occupation === "SelfEmployed")) {
      UnionPrice = Number(t?.productsStep.union.price);
    }

    let totalAmount = UnemploymentInsurancePrice;

    if (payload.wantsUnion) {
      totalAmount = totalAmount + UnionPrice;
    }

    if (payload.wantsFamilyUnion) {
      totalAmount = totalAmount + FamilyUnionPrice;
    }

    if (payload.wantsUnemploymentBenefits && payload.occupation !== "Student") {
      r.push({
        label: t?.productsStep.unemploymentInsurance.title,
        value: `${formatNumber(UnemploymentInsurancePrice)} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsUnemploymentBenefits && payload.occupation === "Student") {
      r.push({
        label: t?.productsStep.unemploymentInsurance.title,
        value: "Kontingent fritaget",
      });
    }

    if (payload.wantsSalaryInsurance) {
      r.push({
        label: t?.summaryStep.salaryInsurance.title,
        value: t?.summaryStep.salaryInsurance.price,
      });
    }

    if (payload.wantsBasic && payload.occupation === "SelfEmployed") {
      r.push({
        label: t?.summaryStep.basic.title,
        value: t?.summaryStep.basic.price,
      });
    }

    if (payload.wantsHRJura && payload.occupation === "SelfEmployed") {
      r.push({
        label: t?.summaryStep.hrJura.title,
        value: t?.summaryStep.hrJura.price,
      });
    }

    if (payload.wantsUnion && (payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student")) {
      r.push({
        label: t?.productsStep.union.title,
        value: payload.wantsUnemploymentBenefits && offerEnabled ? t?.productsStep.offerRowText : `${formatNumber(UnionPrice)} ${t?.general.krPerMonth}`,
      });
    }

    //Students when Union offer is not enabled on them
    /* if (payload.wantsUnion && payload.occupation === "Student") {
      r.push({
        label: t?.productsStep.union.title,
        value: `${formatNumber(UnionPrice)} ${t?.general.krPerMonth}`,
      });
    } */

    if (payload.wantsFamilyUnion && payload.occupation !== "SelfEmployed") {
      r.push({
        label: t?.productsStep.familyUnion.title,
        //Price for familyUnion when there is no offer
        /* value: `${formatNumber(FamilyUnionPrice)} ${t?.general.krPerMonth}`, */
        value: payload.wantsUnemploymentBenefits ? t?.productsStep.offerRowText : `${formatNumber(FamilyUnionPrice)} ${t?.general.krPerMonth}`,
      });
    }

    r.push({
      label: t?.general.totalAmount,
      value: `${formatNumber(totalAmount)} ${t?.general.krPerMonth}`,
      bold: true,
    });
    
    return r as { label: string; value: string; bold?: boolean }[];
  }, [payload, t]);



  useEffect(() => {
    if (payload.wantsUnemploymentBenefits && payload.wantsUnion && offerEnabled) {
      setPayload({ combinationDiscount: true });
    } else if (payload.wantsUnemploymentBenefits && payload.wantsFamilyUnion && offerEnabled) {
      setPayload({ combinationDiscount: true });
    } else {
      setPayload({ combinationDiscount: false });
    }
  }, [payload.wantsUnemploymentBenefits, payload.wantsUnion, payload.wantsFamilyUnion]);


  return (
    <StepContainer
      stepTitle={t?.productsStep.title}
      progress={0.1}
      buttonText={t?.productsStep.buttonLabel}
      helpText={t?.productsStep.helpText}
      onNext={() => {
        // Add tracking
        Analytics.getInstance().trackProducts(payload, offerEnabled, t, TrackingFlowTypeEnum.STANDRARD);

        /* Mobilepay is removed from OI 
        if ((payload.wantsUnemploymentBenefits && (payload.wantsUnion || payload.wantsFamilyUnion)) || (payload.wantsSalaryInsurance && (payload.wantsUnemploymentBenefits || payload.wantsUnion || payload.wantsFamilyUnion))) {
          setPayload({
            paymentType: PaymentTypeEnum.BSPAYMENT,
          });
        } else {
          setPayload({
            paymentType: PaymentTypeEnum.MOBILE_PAY,
          });
        } */

        if (payload.wantsFamilyUnion && !payload.wantsSalaryInsurance && !payload.wantsHRJura && !payload.wantsUnemploymentBenefits && !payload.wantsUnion) {
          setPayload({ cause: "Kun familiefagforening" });
          setRouteStack(StepRoutes.NeedToContactYouStep);
          return;
        }

        if(payload.wantsFamilyUnion && payload.wantsSalaryInsurance) {
          setPayload({ cause: "Ønsker familiefagforening og lønsikring"});
          setRouteStack(StepRoutes.NeedToContactYouStep);
        }

        if (payload.occupation === "Student" && payload.wantsUnemploymentBenefits) {
          setPayload({ cause: "Student" });
          setRouteStack(StepRoutes.NeedToContactYouStep);
          return;
        }

        if (payload.occupation === "SelfEmployed") {
          if (payload.wantsUnemploymentBenefits) {
            setRouteStack(StepRoutes.SelfEmployedStep);
          } else {
            const cause = [];
            if (payload.wantsBasic) {
              cause.push("Ønsker selvstændig forening");
            }

            if (payload.wantsHRJura) {
              cause.push("Ønsker Hr jura");
            }

            if (payload.wantsSalaryInsurance) {
              cause.push("Ønsker Lønsikring");
            }

            setPayload({ cause: cause.join(", ") });

            setRouteStack(StepRoutes.NeedToContactYouStep);
          }
        } else {
          if (payload.wantsUnemploymentBenefits || payload.wantsUnion) {
            setRouteStack(StepRoutes.MembershipStep);
          } else {
            setPayload({ cause: "Ønsker kun lønsikring og ikke a-kasse og fagforening" });
            setRouteStack(StepRoutes.NeedToContactYouStep);
          }
        }
      }}
      nextDisabled={!payload.wantsFamilyUnion && !payload.wantsUnion && !payload.wantsHRJura && !payload.wantsUnemploymentBenefits && !payload.wantsSalaryInsurance && !payload.wantsBasic}
    >
      <AseFieldset>
        <AseList>
          <Subtitle>Til dig personligt:</Subtitle>
          <CheckCard
            id="UnemploymentInsurance"
            onChange={() =>
              setPayload({
                wantsUnemploymentBenefits: !payload.wantsUnemploymentBenefits,
              })
            }
            isChecked={payload.wantsUnemploymentBenefits}
            title={t?.productsStep.unemploymentInsurance.title}
            price={getUnemploymentPrice + ` ${t?.general.krPerMonth}`}
            description={t?.productsStep.unemploymentInsurance.description}
            modalDescriptionHtml={t?.productsStep.unemploymentInsurance.information}
          />

          {payload.occupation !== "Student" && (
            <CheckCard
              id="SalaryGuarantee"
              onChange={() =>
                setPayload({
                  wantsSalaryInsurance: !payload.wantsSalaryInsurance,
                })
              }
              isChecked={payload.wantsSalaryInsurance}
              title={t?.productsStep.salaryGuarantee.title}
              price={t?.productsStep.callMe}
              description={t?.productsStep.salaryGuarantee.description}
              modalDescriptionHtml={t?.productsStep.salaryGuarantee.information}
            />
          )}

          {payload.occupation === "Student" && (
            <CheckCard
              id="Union"
              onChange={() =>
                setPayload({
                  wantsUnion: !payload.wantsUnion,
                })
              }
              isChecked={payload.wantsUnion}
              title={t?.productsStep.union.title}
              price={`${t?.productsStep.union.price} ${t?.general.krPerMonth}`}
              description={t?.productsStep.union.description}
              modalDescriptionHtml={t?.productsStep.union.information}
            />
          )}

          {payload.wantsSalaryInsurance && (
            <SalaryInsurance>
              <IoArrowForward size={22} />
              <div>
                <b>Husk!</b> Tjener du mindre end 27.949 kr./md. før skat, kan du ikke tegne lønsikring, da du er dækket af dine dagpenge.
              </div>
            </SalaryInsurance>
          )}

          {(payload.occupation === "Employee" || payload.occupation === "Unemployed") && (
            <>
              <Subtitle>Fagforening:</Subtitle>
              <ProductCardWrapper>
                <ProductCard
                  active={payload.wantsUnion}
                  onActive={() => {
                    setPayload({
                      wantsUnion: !payload.wantsUnion,
                      wantsFamilyUnion: false,
                    });
                  }}
                  type={ProductTypesEnum.Union}
                  lead={offerText}
                  name="Fagforening"
                  numberOfPeople="(1 voksen)"
                  description="- få juridisk hjælp i dit arbejdsliv."
                  price={t?.productsStep.union.price}
                  modalDescriptionHtml={t?.productsStep.union.information}
                ></ProductCard>
                <ProductCard
                  active={payload.wantsFamilyUnion}
                  onActive={() => {
                    setPayload({
                      wantsUnion: false,
                      wantsFamilyUnion: !payload.wantsFamilyUnion,
                    });
                  }}
                  type={ProductTypesEnum.FamilyUnion}
                  lead={offerText}
                  name="Familiefagforening"
                  numberOfPeople="(2 voksne + børn op til 21 år)"
                  description="- få juridisk hjælp til hele familiens arbejdsliv."
                  price={t?.productsStep.familyUnion.price}
                  modalDescriptionHtml={t?.productsStep.familyUnion.information}
                />
              </ProductCardWrapper>
            </>
          )}

          {payload.occupation === "SelfEmployed" && (
            <>
              <Subtitle>Til din virksomhed:</Subtitle>
              <CheckCard
                id="AseBasic"
                onChange={() =>
                  setPayload({
                    wantsBasic: !payload.wantsBasic,
                  })
                }
                isChecked={payload.wantsBasic}
                title={t?.productsStep.basic.title}
                price={`${payload.wantsUnemploymentBenefits ? t?.productsStep.basic.price : "149"} ${t?.general.krPerMonth}`}
                description={t?.productsStep.basic.description}
                modalDescriptionHtml={t?.productsStep.basic.information}
              />

              <CheckCard
                id="HRJura"
                onChange={() =>
                  setPayload({
                    wantsHRJura: !payload.wantsHRJura,
                  })
                }
                isChecked={payload.wantsHRJura}
                title={t?.productsStep.hrjura.title}
                price={`fra ${t?.productsStep.hrjura.price} ${t?.general.krPerMonth}`}
                description={t?.productsStep.hrjura.description}
                modalDescriptionHtml={t?.productsStep.hrjura.information}
              />
            </>
          )}

          {(payload.wantsFamilyUnion || payload.wantsUnion || payload.wantsUnemploymentBenefits) && <TotalAmountBox hideHeader rows={rows} />}
        </AseList>
      </AseFieldset>
    </StepContainer>
  );
}
