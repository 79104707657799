const enum StepRoutes {
  OccupationStep = "OccupationStep",
  ProductStep = "ProductStep",
  MembershipStep = "MembershipStep",
  WorkingDenmarkStep = "WorkingDenmarkStep",
  SelfEmployedStep = "SelfEmployedStep",
  ReceivingUnemploymentBenefitsStep = "ReceivingUnemploymentBenefitsStep",
  FitWithYouStep = "FitWithYouStep",
  NeedToContactYouStep = "NeedToContactYouStep",
  InformationAboutYouStep = "InformationAboutYouStep",
  SummaryStep = "SummaryStep",
  PaymentStep = "PaymentStep",
  CompletedStep = "CompletedStep",
  CompletedContactYouStep = "CompletedContactYouStep",
  ConsentStep = "ConsentStep",
  CurrentStatusStep = "CurrentStatusStep",
}

export default StepRoutes;
