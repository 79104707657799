import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

export interface TooltipProps {
  children: React.ReactNode;
  tooltipPositions?: ("left" | "right" | "top" | "bottom")[];
  isBlockStyle?: boolean;
  active?: boolean;
  maxWidth?: number;
  content: string;
  disabled?: boolean;
  size?: "sm" | "md" | "lg";
  shade?: "dark" | "light";
}

const Container = styled.span<{ isBlockStyle: boolean }>`
  ${(p) =>
    p.isBlockStyle &&
    `
        display: flex;
        width: 100%;
    `}
`;

const TooltipContainer = styled.div<{
  maxWidth?: number;
  size: "sm" | "md" | "lg";
  shade: "dark" | "light";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  min-height: 32px;
  min-width: 76px;
  border-radius: 4px;
  padding: 30px;
  ${(p) => !!p.maxWidth && `max-width: ${p.maxWidth}px;`};
  box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
`;

export default function Tooltip({ tooltipPositions: positions = ["top", "bottom"], active = false, disabled = false, isBlockStyle = false, content, children, maxWidth, size = "md", shade = "dark" }: TooltipProps) {
  const [isOpen, setIsOpen] = useState(disabled ? false : active);

  useEffect(() => {
    if (!disabled) {
      setIsOpen(active);
    }
  }, [active]);

  return (
    <Popover
      isOpen={isOpen}
      positions={positions}
      align="center"
      containerStyle={{ padding: "10px", zIndex: "10000" }}
      content={() => (
        <TooltipContainer maxWidth={maxWidth} size={size} shade={shade}>
          <div style={{ textTransform: "initial" }} dangerouslySetInnerHTML={{ __html: content }}></div>
        </TooltipContainer>
      )}
      onClickOutside={() => setIsOpen(false)}
    >
      <Container onMouseEnter={() => !disabled && setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} isBlockStyle={isBlockStyle}>
        {children}
      </Container>
    </Popover>
  );
}
