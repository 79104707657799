const backgrounds = document.querySelectorAll("[data-bg]");
const images = document.querySelectorAll("[data-src]");
const config = {
    rootMargin: "0px 0px 50px 0px",
    threshold: 0
};

let loaded = 0;

let observer = new IntersectionObserver(function(entries, self) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            preloadImage(entry.target);
            // Stop watching and load the image
            self.unobserve(entry.target);
        }
    });
}, config);

let bgObserver = new IntersectionObserver(function(entries, self) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            preloadBackground(entry.target);
            // Stop watching and load the image
            self.unobserve(entry.target);
        }
    });
}, config);


function preloadImage(img) {
    const srcset = img.getAttribute("data-srcset");
    const src = img.getAttribute("data-src");
    if (!src) {
        return;
    }
    img.src = src;
    if (!srcset) {
        return;
    }
    img.srcset = srcset;
}

function preloadBackground(background) {
    const bgimage = background.getAttribute("data-bg");
    if (!bgimage) {
        return;
    }
    background.style.backgroundImage = "url('" + bgimage + "')";
}

const init = () => {
  images.forEach(image => {
      observer.observe(image);
  });

  backgrounds.forEach(background => {
      bgObserver.observe(background);
  });
}

export default {
  init
}