import StepRoutes from "../constants/Routes";
import { DynamicsApiEnrollRequestConsents, PayloadModel } from "../types/PayloadModel";
import { TrackingFlowTypeEnum } from "../types/TrackingFlowTypeEnum";
import { TranslationModel } from "../types/TranslationModel";
import { getCookie, setCookie } from "./cookieService";
const md5 = require("md5");

declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];

class Analytics {
  private static instance: Analytics;
  private products: any[] = [];

  constructor() {}

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  }

  private clearEcommerce() {
    window.dataLayer.push({
      event: "event_null",
      ecommerce: null,
    });
  }

  public trackProducts(payload: PayloadModel, offerEnabled: boolean, translations: TranslationModel | null, flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      this.products = [];
      const discountEnabled = offerEnabled && payload.wantsUnemploymentBenefits && payload.wantsUnion;

      if (payload.wantsUnemploymentBenefits) {
        this.products.push({
          item_name: "a-kasse",
          item_id: "A",
          price: translations?.productsStep.unemploymentInsurance.price,
          item_brand: "ase",
          item_category: "insurance",
          item_category2: "unemployment fund",
          item_variant: payload.occupation,
          quantity: 1,
        });
      }

      if (payload.wantsUnion && !payload.wantsFamilyUnion) {
        let event = {
          item_name: "fagforening",
          item_id: "AL",
          price: discountEnabled ? 0 : translations?.productsStep.union.price,
          item_brand: "ase lønmodtager",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "full",
          quantity: 1,
        } as any;

        if (discountEnabled) {
          event = {
            ...event,
            discount: translations?.productsStep.union.price,
            coupon: "rebate6mo",
          };
        }

        this.products.push(event);
      }

      if (payload.wantsSalaryInsurance) {
        this.products.push({
          item_name: "lønsikring",
          item_id: "L",
          price: 0,
          item_brand: "ase",
          item_category: "service",
          item_category2: "callback",
          quantity: 1,
          index: 1,
        });
      }

      if (payload.wantsHRJura) {
        this.products.push({
          item_name: "hr jura",
          item_id: "HR",
          price: translations?.productsStep.hrjura.price,
          item_brand: "ase selvstændig",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "full",
          quantity: 1,
        });
      }

      if (payload.wantsFamilyUnion) {
        this.products.push({
          item_name: "fagforening_familie",
          item_id: "AL",
          price: translations?.productsStep.familyUnion.price,
          item_brand: "ase_lønmodtager",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "family",
          quantity: 1,
        });
      }

      if (payload.wantsBasic) {
        this.products.push({
          item_name: "ase selvstændig",
          item_id: "SB",
          price: payload.wantsUnemploymentBenefits ? translations?.productsStep.basic.price : "149",
          item_brand: "ase selvstændig",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "family",
          quantity: 1,
        });
      }

      this.products = this.products.map((p, index) => ({
        ...p,
        index: index + 1,
      }));

      const ecommerce = {
        currency: "DKK",
        items: this.products,
      };

      this.clearEcommerce();

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce,
        flow,
      });
    }
  }

  public trackUnion(type: "familyUnion" | "union", translations: TranslationModel | null, flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      this.products = [];

      if (type === "union") {
        let event = {
          item_name: "fagforening",
          item_id: "AL",
          price: translations?.productsStep.union.price,
          item_brand: "ase lønmodtager",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "full",
          quantity: 1,
          discount: translations?.productsStep.union.price,
          coupon: "rebate6mo",
        } as any;

        this.products.push(event);
      }

      if (type === "familyUnion") {
        this.products.push({
          item_name: "fagforening_familie",
          item_id: "AL",
          price: translations?.productsStep.familyUnion.price,
          item_brand: "ase_lønmodtager",
          item_category: "consulting",
          item_category2: "union",
          item_variant: "family",
          quantity: 1,
        });
      }

      this.products = this.products.map((p, index) => ({
        ...p,
        index: index + 1,
      }));

      const ecommerce = {
        currency: "DKK",
        items: this.products,
      };

      this.clearEcommerce();

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce,
        flow,
      });
    }
  }

  public trackTerminateUnemploymentBenefits(flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      const item = {
        item_name: "a-kasse_move",
        item_id: "s1",
        price: 0,
        item_brand: "ase",
        item_category: "service",
        item_category2: "terminate",
        item_variant: "full",
        quantity: 1,
        index: this.products.length,
      };

      this.products.push(item);

      this.clearEcommerce();

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "DKK",
          items: [item],
        },
        flow,
      });
    }
  }

  public trackTerminateUnion(flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      const item = {
        item_name: "union_terminate",
        item_id: "s2",
        price: 0,
        item_brand: "ase",
        item_category: "service",
        item_category2: "terminate",
        item_variant: "full",
        quantity: 1,
        index: this.products.length,
      };

      this.products.push(item);

      this.clearEcommerce();

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "DKK",
          items: [item],
        },
        flow,
      });
    }
  }

  public trackStep(stepName: string, flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "checkout",
        content_name: stepName,
        flow,
      });
    }
  }

  public trackStepCount = (count: number, flow: TrackingFlowTypeEnum) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "checkout_completed",
        content_name: StepRoutes.PaymentStep,
        steps_completed: count,
        flow,
      });
    }
  };

  public trackNeedToContact = (payload: PayloadModel, flow: TrackingFlowTypeEnum) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "checkout_completed",
        content_name: "NeedToContactYouStepCompleted",
        cause: payload.cause,
        flow,
      });
    }
  };

  public trackPurchase(payload: PayloadModel, offerEnabled: boolean, totalPrice: number, flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      const ecommerce = {
        currency: "DKK",
        items: this.products,
        payment_type: `${payload.paymentType}_${payload.paymentFrequency}`,
        value: totalPrice,
        transaction_id: Date.now(),
      };

      if (payload.mailAddress) {
        (ecommerce as any).emd5 = md5(payload.mailAddress.toLowerCase().trim());
      }

      if (offerEnabled) {
        (ecommerce as any).offerEnabled = "rebate6mo";
      }

      this.clearEcommerce();

      window.dataLayer.push({
        event: "purchase",
        ecommerce,
        flow,
      });
    }
  }

  public trackAllConsent(name: "added" | "collected", consents: DynamicsApiEnrollRequestConsents | undefined, flow: TrackingFlowTypeEnum) {
    if (window.dataLayer) {
      let params = "";

      if (consents?.unemploymentBenefits?.aseCooperation) {
        params += "aa_collaboration,";
      }

      if (consents?.unemploymentBenefits?.member) {
        params += "aa_news,";
      }

      if (consents?.unemploymentBenefits?.partner) {
        params += "aa_partner,";
      }

      if (consents?.union?.aseCooperation) {
        params += "af_collaboration,";
      }

      if (consents?.union?.member) {
        params += "af_news,";
      }

      if (consents?.union?.partner) {
        params += "af_partner,";
      }

      window.dataLayer.push({
        event: "permissions",
        content_name: name,
        content_type: params,
        flow,
      });
    }
  }

  public trackContactLeanModalClosed() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "content_select",
        "tga-event": true,
        content_name: "button",
        content_type: "enrollment_abandoned_closed",
      });
    }
  }

  public trackContactLeanModalSubmitted() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "generate_lead",
        "tga-event": true,
        leadkampagne: "enrollment_abandoned",
        leadtype: "callback",
      });
    }
  }

  public trackContactLeanModalPopped() {
    if (window.dataLayer) {
      const eventEmitted = getCookie("ContactLeanModalPoppedEvent");
      if (!eventEmitted) {
        window.dataLayer.push({
          event: "popup",
          "tga-event": true,
          content_name: "popup_shown",
          content_type: "enrollment_abandoned",
        });
        setCookie("ContactLeanModalPoppedEvent", "true", 8);
      } else {
        setCookie("ContactLeanModalPoppedEvent", "", -1);
      }
    }
  }

  public trackSubmitChildrenEnrollment(numberOfChildren: number) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_submit",
        "tga-event": true,
        content_type: "family_enrollment",
        content_name: `add_children_${numberOfChildren}`,
      });
    }
  }

  public trackSubmitCompanionForm() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_submit",
        "tga-event": true,
        content_type: "family_enrollment",
        content_name: `send_invite`,
      });
    }
  }

  public trackSalaryInsuranceAction(type: "recommended" | "selected" | "form_completed", salary: number, insuranceAmount: number, duration: number, price: number, contentId: "basis" | "udvidet" | "super") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "calculator",
        content_type: "salary_insurance",
        content_name: type,
        content_id: contentId,
        salary,
        length: duration,
        sum: insuranceAmount,
        price,
      });
    }
  }
}

export default Analytics;
