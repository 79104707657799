import React from "react";
import ReactDOM from "react-dom/client";
import FormEnrollment from "./features/FormEnrollment";
import ChildrenEnrollment from "./features/ChildrenEnrollment";
import CompanionEnrollment from "./features/CompanionEnrollment";
import CompanionInviteForm from "./features/CompanionInviteForm";
import RatingForm from "./features/RatingForm";
import TrackAndTrace from "./features/TrackAndTrace";
import SalaryInsuranceCalculator from "./features/SalaryInsuranceCalculator";

const widgets = [
  { app: FormEnrollment, selectorName: "form-enrollment" },
  { app: RatingForm, selectorName: "rating-form" },
  { app: CompanionEnrollment, selectorName: "companion-enrollment" },
  { app: CompanionInviteForm, selectorName: "companion-invite" },
  { app: ChildrenEnrollment, selectorName: "form-childrenEnrollment" },
  { app: TrackAndTrace, selectorName: "trackandtrace" },
  { app: SalaryInsuranceCalculator, selectorName: "salary-insurance-calculator" },
];

function init() {
  widgets.forEach((widget) => {
    const nodeItems = [...document.querySelectorAll(`[data-component='${widget.selectorName}']`)] as HTMLElement[];

    nodeItems.forEach((node) => {
      const root = ReactDOM.createRoot(node);
      const App = widget.app;

      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    });
  });
}

init();
