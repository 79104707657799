import { FaCheck } from "react-icons/fa";
import { CardButton, Container, Discount, FeatureItem, FeatureTitle, Price, Spacer, SubTitle, Title } from "./SimpleProductCard.styles";

interface ProductCardProps {
  title: string;
  subtitle: string;
  price?: string;
  features?: { title: string; bold?: boolean }[];
  onClick?: () => void;
  active?: boolean;
  discountText?: string;
  maxWidth?: number;
}

export default function ProductCard({ title, subtitle, features = [], onClick, price, active = false, discountText, maxWidth }: ProductCardProps) {
  return (
    <Container active={active} maxWidth={maxWidth}>
      {discountText && <Discount>{discountText}</Discount>}
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      {price && (
        <Price>
          <span>{price} kr.</span>
          <span
            style={{
              paddingLeft: 10,
              fontSize: 20,
              color: "grey",
            }}
          >
            / md.
          </span>
        </Price>
      )}

      <FeatureTitle>Dét får du</FeatureTitle>
      {features.map((feature, index) => (
        <FeatureItem key={index} bold={!!feature.bold}>
          <FaCheck color="#479376" />
          <span>{feature.title}</span>
        </FeatureItem>
      ))}
      <Spacer />
      <CardButton onClick={onClick}>Vælg</CardButton>
    </Container>
  );
}
