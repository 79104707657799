import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { FormEnrollmentSlice, createFormEnrollmentSlice, formEnrollmentInitialState } from "./slices/formEnrollment";
import { CompanionFormEnrollmentSlice, createCompanionFormEnrollmentSlice, companionFormEnrollmentInitialState } from "./slices/companionEnrollment";
import { TrackTraceSlice, trackTraceInitialState, createTrackTraceSlice } from "./slices/trackTraceSlice";
import { SalaryInsuranceSlice, salaryInsuranceInitialState, createSalaryInsuranceSlice } from "./slices/salaryInsuranceSlice";

export type Store = FormEnrollmentSlice & CompanionFormEnrollmentSlice & TrackTraceSlice & SalaryInsuranceSlice;

export const initialState = {
  ...formEnrollmentInitialState,
  ...companionFormEnrollmentInitialState,
  ...trackTraceInitialState,
  ...salaryInsuranceInitialState,
};

export const useStoreBase = create<Store>((...a) => ({
  ...createFormEnrollmentSlice(...a),
  ...createCompanionFormEnrollmentSlice(...a),
  ...createTrackTraceSlice(...a),
  ...createSalaryInsuranceSlice(...a),
}));

export const useStore = createSelectorHooks(useStoreBase);
