export default class FormAdtractionHandler {
    constructor(element) {
        this.el = element
        this.init()
    }

    static init() {
        const umbracoForms = document.querySelectorAll(".umbraco-forms-form form");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const at_gd = urlParams.get('at_gd');

        umbracoForms.forEach(form => {
            const submitForm = form.querySelector("input[type='submit']");
            submitForm.addEventListener("click", (event) => {
                const failedFields = form.querySelectorAll(".input-validation-error");
                if (failedFields.length) {
                } else {
                    if (at_gd) {
                        fetch("/umbraco/api/AdtractionApi/PostAdtractionCampaign", {
                            method: "post",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
            
                            body: JSON.stringify({
                                atgd: at_gd,
                                orderid: ""
                            })
                        }).then(response => {
                            console.log("at_gd api response", response);
                        });
                    }
                }
            });
        })
    }
}
