const data = [
  {
    name: "Det Faglige Hus",
    id: "0f14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Andre foreninger CO 10",
    id: "1f13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Arkitektforbundet",
    id: "2513553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Akademikerne",
    id: "0b13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Attorfillit Kattuffiat",
    id: "2b13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Bibliotekarforbundet",
    id: "3d13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Blik og Rørarbejderforbundet",
    id: "4113553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "BUPL",
    id: "5113553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Brancheafdelingen Trafik & Jernbane",
    id: "4f13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Business Danmark",
    id: "5313553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Cabin Union Denmark",
    id: "5d13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Civiløkonomerne",
    id: "6313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danmarks Frie Fagforening",
    id: "7113553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Danmarks Kordegneforening CO 10",
    id: "7313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danmarks Lærerforening",
    id: "7713553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Dansk Artistforbund",
    id: "7913553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk El-Forbund",
    id: "49cd0df4-6ea7-e811-a963-000d3a246bb7",
  },
  {
    name: "Dansk Formands Forening",
    id: "7d13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Frisør- og kosmetikerforbund",
    id: "7f13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Jernbaneforbund",
    id: "8113553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Dansk Journalistforbund",
    id: "8313553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Dansk Kirkemusiker Forening CO 10",
    id: "8713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Metal",
    id: "8913553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Musiker Forbund",
    id: "8b13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Musikpædagogisk Forening",
    id: "8d13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Skuespillerforbund",
    id: "8f13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Socialrådgiverforening",
    id: "9113553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Dansk Sygeplejeråd",
    id: "9313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Tandplejerforening",
    id: "9513553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Teaterforbund",
    id: "9713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk teknisk lærerforbund",
    id: "9913553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Dansk Told- og Skatteforbund CO 10",
    id: "9b13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danske Afspændingspædagoger",
    id: "9d13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danske Bioanalytikere",
    id: "9f13553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
    remark:
      " OBS! Medlemmet skal logge på hjemmesiden og melde sig ud eller indscanne og sende dokument med ønske om udmeldelse med underskrift. En mail er ikke nok.",
  },
  {
    name: "Danske Forsikringsfunktionæreres Landsforening",
    id: "a113553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danske Fysioterapeuter",
    id: "a313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danske produktionshøjskolers Lærerforening",
    id: "a513553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Danske Skov- og Landskabsingeniører",
    id: "a713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "De offentlige tandlæger",
    id: "ab13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Den Danske Dyrlægeforening",
    id: "af13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Den Danske Landinspektørforening",
    id: "b113553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "DJØF",
    id: "b713553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Ejendomsfunktionærernes Fagforening",
    id: "d8599d84-41ee-e811-a96a-000d3a246bb7",
  },
  {
    name: "Ergoterapeutforeningen",
    id: "c313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Erhversskolelederne i Danmark CO 10",
    id: "c513553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Fængselsforbundet i Danmark",
    id: "c713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Fagforeningen Danmark",
    id: "c913553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "3F",
    id: "cb13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Farmakonomforeningen",
    id: "cf13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Film- og TV-arbejderforeningen",
    id: "d313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Finansforbundet",
    id: "d513553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "FOA - Fag og Arbejde",
    id: "e313553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
    remark: "ikke alle afdelinger skal man selv melde sig ud.",
  },
  {
    name: "Forbundet af Kirke- og Kirkegårdsansatte",
    id: "eb13553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Forbundet for Tjenestemænd ved Fødevare- og Undervisningsministeriet m.fl.",
    id: "ed13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Foreningen af Mejeriledere og Funktionærer",
    id: "f113553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Foreningen af Præliminære Organister CO 10",
    id: "f313553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Foreningen af Radiografer i Danmark",
    id: "f513553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Foreningen af Tekniske og Administrative Tjenestemænd (TAT) CO 10",
    id: "f713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Forsvarets Civil-Etat CO 10",
    id: "fb13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Forsvarsgruppen i AC",
    id: "fd13553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "FRIE Funktionærer",
    id: "0314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Frie Skolers Lærerforening",
    id: "0714553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Funktionærforeningen Carlsberg Tuborg",
    id: "1114553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Funktionærforeningen i F.D.B. og COOP Danmarks",
    id: "1314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Funktionærkartellet/Teknikersammenslutningen",
    id: "1514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Gymnasieskolernes Lærerforening",
    id: "2314553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Hærens Konstabel- og Korporalforening",
    id: "2514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Håndbold Spillerforeningen",
    id: "2714553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Handelsskolernes Lærerforening CO 10",
    id: "2914553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Hi - Organisation for ledende medarbejdere i idræt-kultur-fritid",
    id: "2f14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "HK/Danmark",
    id: "3114553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Ingeniørforeningen i Danmark",
    id: "3f14553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "JID",
    id: "4714553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Jordbrugsakademikerne",
    id: "4d14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Jordemoderforening",
    id: "4f14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Københavns Kommunale Embedsmandsforening",
    id: "5114553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Kommunikation og Sprog",
    id: "5714553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Konstruktørforeningen",
    id: "5d14553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Kort- og Landmålingsteknikernes Forening",
    id: "6514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Kost og Ernæringsforbundet",
    id: "6714553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Krifa",
    id: "6b14553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Lægeforeningen",
    id: "7314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Landinspektørforeningen",
    id: "7b14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Landsforbundet af Voksen- og Ungdomsundervisere",
    id: "7d14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Landsforeningen af Statsaut. Fodterapeuter",
    id: "7f14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Landsforeningen for socialpædagoger",
    id: "ae86600d-c189-e811-a960-000d3a246bb7",
  },
  {
    name: "Lederforeningen i TDC",
    id: "8114553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "LederForum - Social & Sundhedssektoren",
    id: "8314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Lederne",
    id: "8514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Lufthavnsfunktionærerne",
    id: "8d14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Magisterforeningen",
    id: "8f14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Malerforbundet i Danmark",
    id: "9314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Maskinmestrenes Forening",
    id: "9914553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Merkonomernes Hovedorganisation",
    id: "9f14553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "NNF",
    id: "e713553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Pharma-Danmark",
    id: "b314553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Politiforbundet i Danmark CO 10",
    id: "b514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Præsteforeningen",
    id: "b714553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "PROSA - EDB-fagets fagforening",
    id: "bd14553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Psykologforeningen",
    id: "bf14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "SAFU Sammenslutningen af Funktionærer",
    id: "cf14553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Service- og funktionærforbundet",
    id: "d114553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Socialpædagogisk Landsforbund",
    id: "e514553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Spillerforeningen",
    id: "e914553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Tandlægeforeningen",
    id: "f914553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Teknisk Landsforbund",
    id: "0115553c-1052-e811-a971-000d3a2cd0da",
    userUnsubscribe: true,
  },
  {
    name: "Trafikforbundet CO 10",
    id: "0b15553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "Uddannelsesforbundet",
    id: "1115553c-1052-e811-a971-000d3a2cd0da",
  },
  {
    name: "VSL",
    id: "e18e2d56-0679-e811-a965-000d3a247283",
  },
  {
    id: "0",
    name: "Ase fagforening",
  },
];

export default data;
