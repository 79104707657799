import { useMemo } from "react";
import { useStore } from "../../../../../../store/store";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import { Actions, Summary, SummaryRow } from "./ConfirmScreen.styles";
import Button from "../../../../../../components/Button";
import Analytics from "../../../../../../utils/analytics";
import { PaymentFrequencyTypeEnum } from "../../../../../../types/PaymentFrequencyTypeEnum";
import { TrackingFlowTypeEnum } from "../../../../../../types/TrackingFlowTypeEnum";
import { PayloadModel } from "../../../../../../types/PayloadModel";
import { PaymentTypeEnum } from "../../../../../../types/PaymentTypesEnum";
import { TrackTracePaymentType } from "../../../../../../types/TrackTraceResponseModel";
import { SalaryInsuranceFlowRouteEnum } from "../../../../../../types/SalaryInsuranceFlowRouteEnum";

interface ConfirmScreenProps {
  onHeightChange: (height: number) => void;
}

export default function ConfirmScreen({ onHeightChange }: ConfirmScreenProps) {
  const route = useStore.useSalaryInsuranceFlowRoute();
  const setSalaryInsuranceFlowVisible = useStore.useSetSalaryInsuranceFlowVisible();
  const setSalaryInsuranceFlowRoute = useStore.useSetSalaryInsuranceFlowRoute();

  const animationPosition = useMemo(() => {
    switch (route) {
      case SalaryInsuranceFlowRouteEnum.CONFIRM:
        return AnimatedSlidePosition.MIDDLE;
      case SalaryInsuranceFlowRouteEnum.DEFAULT:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.RIGHT_NO_ANIMATION;
    }
  }, [route]);

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} title="Bekræft" onClose={() => setSalaryInsuranceFlowVisible(false)} onBack={() => setSalaryInsuranceFlowRoute(SalaryInsuranceFlowRouteEnum.DEFAULT)}>
      <Summary>
        <SummaryRow style={{ fontWeight: 700 }}>
          <div>Med Ase Lønsikring kan du forsikre op til 80% af din nuværende løn. Det sikrer dig stabilitet i din økonomi og giver dig ekstra økonomisk tryghed, hvis du skulle blive ledig.</div>
        </SummaryRow>
        <SummaryRow style={{ fontWeight: 700 }}>
          <div>For at det kan betale sig for dig at tegne en lønsikring, skal du tjene mindst 27.160 kr./md. før skat.</div>
        </SummaryRow>
      </Summary>

      <Actions>
        <Button
          onClick={() => {
            Analytics.getInstance().trackNeedToContact({ cause: "Ønsker lønsikring" } as PayloadModel, TrackingFlowTypeEnum.TrackAndTrace);
            setSalaryInsuranceFlowRoute(SalaryInsuranceFlowRouteEnum.RECEIPT);
          }}
          apperance="complete"
        >
          Ring mig op
        </Button>
      </Actions>
    </AnimatedSlideContainer>
  );
}
