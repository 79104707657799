import { useMemo } from "react";
import { useStore } from "../../../../../../store/store";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import { Actions, Summary, SummaryRow } from "./ConfirmScreen.styles";
import Button from "../../../../../../components/Button";
import Analytics from "../../../../../../utils/analytics";
import { PaymentFrequencyTypeEnum } from "../../../../../../types/PaymentFrequencyTypeEnum";
import { TrackingFlowTypeEnum } from "../../../../../../types/TrackingFlowTypeEnum";
import { PayloadModel } from "../../../../../../types/PayloadModel";
import { PaymentTypeEnum } from "../../../../../../types/PaymentTypesEnum";
import { TrackTracePaymentType } from "../../../../../../types/TrackTraceResponseModel";

interface UnionMemberScreenProps {
  onHeightChange: (height: number) => void;
}

export default function ConfirmScreen({ onHeightChange }: UnionMemberScreenProps) {
  const route = useStore.useUnionFlowRoute();
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const setUnionFlowRoute = useStore.useSetUnionFlowRoute();
  const payload = useStore.useUnionFlowPayload();
  const response = useStore.useTrackTraceResponse();

  const disabled = useMemo(() => {
    return payload.currentlyMemberOfAUnion && !Boolean(payload.currentUnion);
  }, [payload]);

  const animationPosition = useMemo(() => {
    switch (route) {
      case UnionFlowRouteEnum.CONFIRM:
        return AnimatedSlidePosition.MIDDLE;
      case UnionFlowRouteEnum.CONSENT:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.RIGHT_NO_ANIMATION;
    }
  }, [route]);

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} title="Afslut dit køb" onClose={() => setUnionFlowVisible(false)} onBack={() => setUnionFlowRoute(UnionFlowRouteEnum.CONSENT)}>
      <Summary>
        <SummaryRow>
          {payload.wantsUnion && (
            <>
              <div>
                Fagforening <b style={{ color: "var(--color-orange)" }}>(gratis første 6 mdr, herefter 99 kr./md.)</b>
              </div>
              <div>0 kr/md</div>
            </>
          )}
          {payload.wantsFamilyUnion && (
            <>
              <div>FamilieFagforening</div>
              <div>99 kr/md</div>
            </>
          )}
        </SummaryRow>
        <SummaryRow>
          <div>Betalingsmetode</div>
          <div>{response?.paymentType === TrackTracePaymentType.MobilePay ? "MobilePay" : "Betalingsservice"}</div>
        </SummaryRow>
        <SummaryRow style={{ fontWeight: 700 }}>
          <div>I alt:</div>
          {payload.wantsUnion && <div>0 kr./ md.</div>}
          {payload.wantsFamilyUnion && <div>99 kr./ md.</div>}
        </SummaryRow>
      </Summary>

      <Actions>
        <Button
          disabled={disabled}
          onClick={() => {
            Analytics.getInstance().trackPurchase({ paymentType: response?.paymentType === TrackTracePaymentType.MobilePay ? PaymentTypeEnum.MOBILE_PAY : PaymentTypeEnum.BSPAYMENT, paymentFrequency: PaymentFrequencyTypeEnum.Monthly } as PayloadModel, true, payload.wantsUnion ? 0 : 99, TrackingFlowTypeEnum.TrackAndTrace);
            Analytics.getInstance().trackAllConsent("collected", payload.consents, TrackingFlowTypeEnum.TrackAndTrace);
            setUnionFlowRoute(UnionFlowRouteEnum.RECEIPT);
          }}
          apperance="complete"
        >
          Køb
        </Button>
      </Actions>
    </AnimatedSlideContainer>
  );
}
