import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import SimpleProductCard from "../../../../../../components/SimpleProductCard";
import { useStore } from "../../../../../../store/store";
import { TrackingFlowTypeEnum } from "../../../../../../types/TrackingFlowTypeEnum";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import Analytics from "../../../../../../utils/analytics";
import { Wrapper } from "./ChooseUnionTypeScreen.styles";
import { useMemo } from "react";
import * as translation from "../../../../../../utils/translations.json";
import { TranslationModel } from "../../../../../../types/TranslationModel";

interface UnionMemberScreenProps {
  onHeightChange: (height: number) => void;
}

const unionFeatures = [
  {
    title: "Gennemgang af din ansættelseskontrakt",
  },
  {
    title: "Gennemgang af din lønsedler",
  },
  {
    title: "Vejledning ift. orlov og fravær",
  },
  {
    title: "Hjælp i tilfælde af arbejdsskade",
  },
  {
    title: "Rådgivning ift. problemer på jobbet",
  },
  {
    title: "Rådgivning, hvis din arbejdsgiver går konkurs",
  },
  {
    title: "Hjælp ved retssager i tilfælde af tvister",
  },
  {
    title: "Adgang til professionel karriererådgivning",
  },
];

const familyUnionFeatures = [
  {
    title: "Én pris på 99 kr./md. for hele familien",
  },
  {
    title: "Fagforening til 1-2 voksne",
  },
  {
    title: "Fagforening til alle børn (op til 21 år) i husstanden",
  },
  {
    title: "Værktøjer til at søge fritids- og studiejob",
  },
  {
    title: "Juridisk hjælp og sparring ifm. dit barns fritidsjob/studiejob",
  },
  {
    title: "Rabat på Tryg Børneforsikring",
  },
  {
    title: "Via Tryg Børneforsikring adgang til Tryg i Livets hotline",
  },
];

export default function ChooseUnionTypeScreen({ onHeightChange }: UnionMemberScreenProps) {
  const route = useStore.useUnionFlowRoute();
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const setUnionFlowRoute = useStore.useSetUnionFlowRoute();
  const payload = useStore.useUnionFlowPayload();
  const setUnionFlowPayload = useStore.useSetUnionFlowPayload();

  const animationPosition = useMemo(() => {
    switch (route) {
      case UnionFlowRouteEnum.DEFAULT:
        return AnimatedSlidePosition.MIDDLE;
      default:
        return AnimatedSlidePosition.LEFT;
    }
  }, [route]);

  const { unionPrice, familyUnionPrice } = useMemo(() => {
    const root = document.querySelector(`[data-component='trackandtrace']`) as HTMLElement;
    return {
      unionPrice: root?.dataset.unionPrice ?? "",
      familyUnionPrice: root?.dataset.familyUnionPrice ?? "",
    };
  }, []);

  return (
    <AnimatedSlideContainer title="Vælg fagforening" titlePosition="center" onHeightChange={onHeightChange} position={animationPosition} onClose={() => setUnionFlowVisible(false)}>
      <Wrapper>
        <SimpleProductCard
          title="Fagforening"
          subtitle="(1 voksen)"
          price={unionPrice}
          discountText="TILBUD: 6 mdr. GRATIS"
          features={unionFeatures}
          onClick={() => {
            setUnionFlowPayload({ wantsUnion: true, wantsFamilyUnion: false });
            Analytics.getInstance().trackUnion("union", translation as TranslationModel, TrackingFlowTypeEnum.TrackAndTrace);
            setUnionFlowRoute(UnionFlowRouteEnum.MEMBER);
          }}
          active={payload.wantsUnion}
        />
        <SimpleProductCard
          title="Familiefagforening"
          subtitle="(2 voksne + børn op til 21 år)"
          price={familyUnionPrice}
          features={familyUnionFeatures}
          onClick={() => {
            setUnionFlowPayload({ wantsUnion: false, wantsFamilyUnion: true });
            Analytics.getInstance().trackUnion("familyUnion", translation as TranslationModel, TrackingFlowTypeEnum.TrackAndTrace);
            setUnionFlowRoute(UnionFlowRouteEnum.MEMBER);
          }}
          active={payload.wantsFamilyUnion}
        />
      </Wrapper>
    </AnimatedSlideContainer>
  );
}
