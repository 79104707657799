import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.25);
  z-index: 2000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid var(--color-coal-5);
`;

export const DropdownItem = styled.div<{ isFocused?: boolean }>`
  color: var(--color-coal);
  padding: 10px 24px;
  text-transform: initial;

  &:hover {
    cursor: pointer;
    background-color: var(--color-coal-5);
  }

  ${({ isFocused }) => isFocused && "background-color: var(--color-coal-5);"}
`;
