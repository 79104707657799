import UnionMemberScreen from "./Screens/UnionMemberScreen";
import ConsentScreen from "./Screens/ConsentScreen";
import ChooseUnionTypeScreen from "./Screens/ChooseUnionTypeScreen";
import ConfirmScreen from "./Screens/ConfirmScreen";
import ReceiptScreen from "./Screens/ReceiptScreen";
import { Wrapper } from "./UnionFlow.styles";
import { useState } from "react";

export default function UnionFlow() {
  const [height, setHeight] = useState(0);

  return (
    <Wrapper height={height}>
      <UnionMemberScreen onHeightChange={setHeight} />
      <ConsentScreen onHeightChange={setHeight} />
      <ChooseUnionTypeScreen onHeightChange={setHeight} />
      <ConfirmScreen onHeightChange={setHeight} />
      <ReceiptScreen onHeightChange={setHeight} />
    </Wrapper>
  );
}
