import { useState } from "react";
import Modal from "../Modal";
import { Wrapper } from "./CPRLink.styles";

export default function CPRLink() {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const t = "<h2>Hvorfor skal du oplyse dit CPR-nummer?</h2><p>Du skal oplyse dit CPR-nummer til Ase, for at du kan få fradrag for dit medlemskab og udbetalt dagpenge, hvis du bliver ledig.</p>";
  return (
    <>
      <Wrapper onClick={() => setShowHelpModal(true)}>
        <span>i</span>
        <p>Hvorfor skal du oplyse dit CPR-nummer?</p>
      </Wrapper>

      <Modal open={showHelpModal} onClose={() => setShowHelpModal(false)} modalDescriptionHtml={t}></Modal>
    </>
  );
}
