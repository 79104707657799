export default class SituationLink {
  constructor(element) {
    this.el = element
    this.init()
  }

  init() {

    const showHideButton = this.el.querySelector(
        "[data-showsituation]"
    );
    if (showHideButton != null) {
        const showHideLink = this.el.querySelector(
            "[data-hiddensituation]"
        );
        const showHideContainer = this.el.getElementsByClassName(
            "js-situation-list__container"
        );
        const moreText = showHideButton.getAttribute("data-moretext");
        const lessText = showHideButton.getAttribute("data-lesstext");
        let showmore = false;
    
        const openSituation = function() {
            const containerHeight = showHideContainer[0].clientHeight;
    
            showHideLink.style.height = containerHeight + "px";
            showHideButton.innerHTML = lessText;
            showHideButton.dataset.showsituation = "true";
        };
    
        const closeSituation = function() {
            showHideLink.style.height = "0px";
            showHideButton.innerHTML = moreText;
            showHideButton.dataset.showsituation = "false";
        };
    
        showHideButton.onclick = function(event) {
            event.preventDefault();
    
            if (!showmore) {
                openSituation();
            } else {
                closeSituation();
            }
    
            showHideLink.dataset.hiddensituation = String(showmore);
            showmore = !showmore;
        };
    
        // On resize
        let timeOut;
    
        window.onresize = function() {
            clearTimeout(timeOut);
    
            timeOut = setTimeout(() => {
                if (showmore) {
                    closeSituation();
                    showHideLink.dataset.hiddensituation = String(showmore);
                    showmore = !showmore;
                }
            }, 50);
        };
    }
    
  }
}