import { useStore } from "../../../../store/store";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";
import "dayjs/locale/da";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Date, Inner, Wrapper } from "./CompletedStep.styles";

export default function CompletedStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const [firstname, setFirstname] = useState("");

  useEffect(() => {
    const form = document.querySelector(`[data-component='companion-enrollment']`) as HTMLElement;
    if (form?.dataset.ownerFirstname && form?.dataset.ownerFirstname !== "null") {
      setFirstname(form?.dataset.ownerFirstname);
    }
  }, []);

  return (
    <Wrapper>
      <Inner>
        <h2>{t?.completedStep.title}</h2>
        <div style={{ textAlign: "center" }} />

        <p>Hej {payload.firstName}</p>
        <p className="WithMargin">{`Vi er glade for, at du har valgt at blive medlem af vores a-kasse og familiefagforening. Vi har modtaget dine oplysninger, og du er nu tilføjet Ase FamilieFagforening sammen med ${firstname}.`}</p>
        <p>
          <b>Betalingsaftale</b>
        </p>
        <p className="WithMargin">{payload.paymentType === PaymentTypeEnum.BSPAYMENT ? `Tilmelding til Betalingsservice sker automatisk. Inden for to arbejdsdage kan du se aftalen i din Netbank eller i din Betalingsservice app. Du modtager bekræftelse og kvittering af dit medlemskab på ${payload.mailAddress}` : `Du har valgt betaling via MobilePay. Indenfor 2 arbejdsdage modtager du information til opsætning af din betalingsaftale, bekræftelse og kvittering af dit medlemskab på ${payload.mailAddress}`}</p>

        {payload.wantsUnemploymentBenefits && (
          <>
            <p>
              <b>Husk din første betaling.</b>
            </p>
            <p>Når vi har oprettet dit medlemskab, modtager du en notifikation om betaling af første rate i din MobilePay App.</p>
          </>
        )}

        <p>Dit medlemskab er aktivt fra:</p>
        <Date>
          <p>
            <b>{t?.summaryStep.enrollmentDate}</b>
          </p>
          <p style={{ textTransform: "capitalize" }}>{dayjs(payload.signupDate).isValid() && dayjs(payload.signupDate).locale("da").format("DD. MMM YYYY")}</p>
        </Date>

        {payload.wantsSalaryInsurance && (
          <>
            <p>
              <b>Ase Lønsikring</b>
            </p>
            <p className="WithMargin">Vi ringer dig op indenfor to arbejdsdage for at sammensætte den bedste lønsikring til dig.</p>
          </>
        )}

        <p>Venlig hilsen</p>
        <p>
          <b>Ase</b>
        </p>
      </Inner>
    </Wrapper>
  );
}
