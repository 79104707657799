import React, { useState } from "react";
import Modal from "../Modal";
import { CheckboxInput, Help, Label, NotificationBar, OfferText, Row, RowDescription, Wrapper } from "./CheckCard.styles";

interface CheckCardProps {
  id: string;
  onChange: () => void;
  isChecked?: boolean;
  title?: string;
  titleHtml?: string;
  titleComponent?: React.ReactNode;
  price?: string;
  description?: string;
  descriptionHtml?: string;
  modalDescriptionHtml?: string;
  transparent?: boolean;
  outlined?: boolean;
  offerEnabled?: boolean;
  offerTitle?: string;
  offerText?: string;
  offerSelected?: boolean;
}

export default function CheckCard({ id, onChange, title, titleHtml, titleComponent, price, isChecked, description, descriptionHtml, modalDescriptionHtml, transparent = false, outlined = false, offerEnabled = false, offerSelected = false, offerText, offerTitle }: CheckCardProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Wrapper>
      <CheckboxInput isTransparent={transparent} type="checkbox" id={id} onChange={onChange} checked={isChecked} />
      <Label isOutlined={outlined} isTransparent={transparent} offerEnabled={offerEnabled && !offerSelected} htmlFor={id}>
        {(title || titleHtml || titleComponent || price) && (
          <Row>
            {offerEnabled && !offerSelected && <NotificationBar>{offerTitle}</NotificationBar>}

            {title && (
              <span>
                <strong>{title}</strong>
              </span>
            )}

            {titleHtml && <span dangerouslySetInnerHTML={{ __html: titleHtml }} />}

            {<span>{titleComponent}</span>}

            {price && (
              <span>
                <strong>{price}</strong>
              </span>
            )}
          </Row>
        )}
        {description && (
          <Row>
            <RowDescription>{description}</RowDescription>
          </Row>
        )}
        {descriptionHtml && (
          <Row>
            <RowDescription dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          </Row>
        )}

        {modalDescriptionHtml && (
          <Help
            onClick={(e) => {
              setShowModal(true);
              e.preventDefault();
            }}
          >
            <span>i</span>
          </Help>
        )}
        {offerEnabled && offerSelected && <OfferText>{offerText}</OfferText>}
      </Label>

      {modalDescriptionHtml && <Modal onClose={() => setShowModal(false)} open={showModal} modalDescriptionHtml={modalDescriptionHtml} />}
    </Wrapper>
  );
}
