import { useEffect, useRef, useState } from "react";
import { Outer, Content, Inner, Title, Header, ButtonWrapper, Spacer } from "./AnimatedSlideContainer.styles";
import { IoClose, IoArrowBack } from "react-icons/io5";
import { useSize } from "../../hooks/useSize";
import { useWindowResize } from "../../hooks/useWindowResize";

export enum AnimatedSlidePosition {
  LEFT = "left",
  RIGHT = "right",
  RIGHT_NO_ANIMATION = "right_no_animation",
  MIDDLE = "middle",
}

interface Props {
  onClose?: () => void;
  onBack?: () => void;
  children?: React.ReactNode;
  headerComponent?: React.ReactNode;
  title?: string;
  titlePosition?: "left" | "center" | "right";
  position?: AnimatedSlidePosition;
  onHeightChange?: (height: number) => void;
}

export default function AnimatedSlideContainer({ children, position = AnimatedSlidePosition.MIDDLE, onHeightChange, title, titlePosition, onClose, onBack }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const size = useSize(ref);
  const windowSize = useWindowResize();
  const [h, setH] = useState(0);

  useEffect(() => {
    if (position === AnimatedSlidePosition.MIDDLE) {
      const maxHeight = document.documentElement.clientHeight * 0.8;
      let height = size.height;

      height = height + 10;
      // Bottom padding
      height = height < maxHeight ? height : maxHeight;

      onHeightChange && onHeightChange(height);
      setH(height);
    }
  }, [size, windowSize, position]);

  return (
    <Outer style={{ height: h }} show={position === AnimatedSlidePosition.MIDDLE}>
      <Inner show={position === AnimatedSlidePosition.MIDDLE}>
        <Content ref={ref} position={position} tabIndex={-1}>
          <Header>
            {onBack && (
              <ButtonWrapper onClick={onBack}>
                <IoArrowBack size={24} />
              </ButtonWrapper>
            )}
            <Spacer />
            {onClose && (
              <ButtonWrapper onClick={onClose}>
                <IoClose size={24} />
              </ButtonWrapper>
            )}
          </Header>
          <Title position={titlePosition ?? "left"}>{title}</Title>
          {children}
        </Content>
      </Inner>
    </Outer>
  );
}
