import { useStore } from "../../../../store/store";
import PrivacyPolicyLink from "../../../../components/PrivacyPolicyLink";
import CheckCard from "../../../../components/CheckCard";
import React, { useMemo, useState } from "react";
import { PrivacyPolicyWrapper } from "./ConsentStep.styles";
import StepContainer from "../../../../components/StepContainer";
import Analytics from "../../../../utils/analytics";
import textContent from "./text";
import Tooltip from "../../../../components/Tooltip";
import StepRoutes from "../../../../constants/Routes";
import Modal from "../../../../components/Modal";
import { AseFieldset, AseList } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";
import GiftCardPopup from "../../../../components/GiftCardPopup";

interface Props {
  style?: any;
}

enum ConsentType {
  BOTH = "BOTH",
  UNION = "UNION",
  UNEMPLOYMENT_BENEFITS = "UNEMPLOYMENT_BENEFITS",
}

export default function ConsentStep({ style }: Props) {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const setRouteStack = useStore.useSetRouteStack();
  const payload = useStore.usePayload();
  const productConsentSet = ((payload.wantsFamilyUnion || payload.wantsUnion) && Boolean(payload.consents?.union?.aseCooperation)) || (payload.wantsUnemploymentBenefits && Boolean(payload.consents?.unemploymentBenefits?.aseCooperation));
  const partnerConsentSet = (payload.wantsUnemploymentBenefits && Boolean(payload.consents?.unemploymentBenefits?.member) && Boolean(payload.consents?.unemploymentBenefits?.partner)) || ((payload.wantsFamilyUnion || payload.wantsUnion) && Boolean(payload.consents?.union?.member) && Boolean(payload.consents?.union?.partner));
  const [productConsent, setProductConsent] = useState(productConsentSet);
  const [partnerConsent, setPartnerConsent] = useState(partnerConsentSet);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showPartnerPolicy, setShowPartnerPolicy] = useState(false);

  const setConsent = () => {
    setPayload({
      consents: {
        union: {
          aseCooperation: (payload.wantsFamilyUnion || payload.wantsUnion) && productConsent,
          member: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
          partner: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
        },
        unemploymentBenefits: {
          aseCooperation: payload.wantsUnemploymentBenefits && productConsent,
          member: payload.wantsUnemploymentBenefits && partnerConsent,
          partner: payload.wantsUnemploymentBenefits && partnerConsent,
        },
      },
    });
  };

  const consentType = useMemo(() => {
    if ((payload.wantsFamilyUnion || payload.wantsUnion) && payload.wantsUnemploymentBenefits) {
      return ConsentType.BOTH;
    } else if (!payload.wantsFamilyUnion && !payload.wantsUnion && payload.wantsUnemploymentBenefits) {
      return ConsentType.UNEMPLOYMENT_BENEFITS;
    } else if ((payload.wantsFamilyUnion || payload.wantsUnion) && !payload.wantsUnemploymentBenefits) {
      return ConsentType.UNION;
    } else {
      return ConsentType.BOTH;
    }
  }, [payload]);

  const text = useMemo(() => {
    switch (consentType) {
      case ConsentType.BOTH:
        return textContent.wantBoth;
      case ConsentType.UNEMPLOYMENT_BENEFITS:
        return textContent.wantUnemploymentBenefits;
      case ConsentType.UNION:
        return textContent.wantUnion;
    }
  }, [consentType]);

  const box2TextComponent = useMemo(() => {
    return (
      <span>
        <b>Jeg accepterer at blive kontaktet om nyheder og medlemstilbud</b> fra {" "}
        <Tooltip isBlockStyle={false} content="<p>Ase består af følgende foreninger:</p><ul><li>Ase a-kasse</li><li>Fagforeningen Ase Lønmodtager</li><li>Ase Selvstændig</li></ul>">
          <span style={{ color: "var(--color-petrol)" }}>Ase</span>
        </Tooltip> {" "}
          og vores samarbejdspartnere. Se listen over vores samarbejdspartnere{" "}
        <span style={{ cursor: "pointer" }} onClick={() => setShowPartnerPolicy(true)}>
          <u>her</u>
        </span>
        . Det vil altid være{" "}
        <Tooltip isBlockStyle={false} content="<p>Ase består af følgende foreninger:</p><ul><li>Ase a-kasse</li><li>Fagforeningen Ase Lønmodtager</li><li>Ase Selvstændig</li></ul>">
          <span style={{ color: "var(--color-petrol)" }}>Ase</span>
        </Tooltip>
        , der kontakter mig.
      </span>
    );
  }, []);

  return (
    <StepContainer
      stepTitle={text.title}
      progress={0.8}
      buttonText={t?.unionConsentStep.buttonLabel}
      helpText={t?.unionConsentStep.helpText}
      onNext={() => {
        setConsent();
        Analytics.getInstance().trackAllConsent("added", payload.consents, TrackingFlowTypeEnum.STANDRARD);
        setRouteStack(StepRoutes.InformationAboutYouStep);
      }}
    >
      <AseFieldset>
        <p dangerouslySetInnerHTML={{ __html: text.description }} />
        {false && <PrivacyPolicyLink />}
        <AseList>
          <CheckCard id="product_consent" onChange={() => setProductConsent(!productConsent)} isChecked={productConsent} titleHtml={text.box1Text} modalDescriptionHtml={text.box1ModalText as string} />

          <CheckCard id="partner_consent" onChange={() => setPartnerConsent(!partnerConsent)} isChecked={partnerConsent} titleComponent={box2TextComponent} modalDescriptionHtml={text.box2ModalText as string} />
        </AseList>

        <PrivacyPolicyWrapper>
          <p style={{ fontSize: "1.1rem" }}>
            Du kan læse mere om, hvordan vi behandler dine personoplysninger{" "}
            <span style={{ cursor: "pointer" }} onClick={() => setShowPrivacyPolicy(true)}>
              <u>her</u>
            </span>
          </p>
        </PrivacyPolicyWrapper>
      </AseFieldset>

      <Modal onClose={() => setShowPartnerPolicy(false)} open={showPartnerPolicy} modalDescriptionHtml={Object.values(textContent.partnerPolicy).join("")} />
      <Modal onClose={() => setShowPrivacyPolicy(false)} open={showPrivacyPolicy} modalDescriptionHtml={Object.values(textContent.privacyPolicy).join("")} />
    </StepContainer>
  );
}
