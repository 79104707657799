import React, { useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "../ProgressBar";
import { useStore } from "../../store/store";
import StepRoutes from "../../constants/Routes";
import { scrollTo } from "../../utils/scrollTo";
import Modal from "../Modal";
import { AseSpinner } from "../../utils/global.styles";
import { BackButton, CampaignImg, Content, Footer, Header, HeaderTitle, Heighlight, Help, HelpButton, HelpLabel, LeftContainer, NextButton, OfferBanner, RightContainer, StepTitle } from "./StepContainer.styles";

interface StepProps {
  title?: string;
  subtitle?: string;
  stepTitle?: string;
  progress?: number;
  children: React.ReactNode;
  onNext?: () => void;
  nextDisabled?: boolean;
  hideBackButton?: boolean;
  withSuccessButton?: boolean;
  loading?: boolean;
  buttonText?: string;
  helpText?: string;
  previousDisabled?: boolean;
}

export default function StepContainer({ children, title, subtitle, stepTitle, progress = 0, onNext, nextDisabled = false, previousDisabled = false, hideBackButton = false, loading = false, buttonText, helpText, withSuccessButton }: StepProps) {
  const goBack = useStore.useSetBack();
  const t = useStore.useTranslations();
  const offerEnabled = useStore.useOfferEnabled();
  const payload = useStore.usePayload();
  const routeStack = useStore.useRouteStack();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [campaignImgUrl, setCampaignImgUrl] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(false);
    }, 400);
  }, []);

  useEffect(() => {
    const form = document.querySelector(`[data-component='form-enrollment']`) as HTMLElement;
    const imageUrl = form?.dataset.imageCampaignUrl;
    setCampaignImgUrl(`${imageUrl}`);
  }, [])

/*   const showOfferBanner = useMemo(() => {
    const currentStep = routeStack[routeStack.length - 1];
    if (offerEnabled && currentStep === StepRoutes.OccupationStep && (payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student")) {
      return true;
    } else {
      return false;
    }
  }, [payload, offerEnabled, routeStack]); */

  const showCampaignImg = useMemo(() => {
    const currentStep = routeStack[routeStack.length - 1];
    if(currentStep === StepRoutes.OccupationStep && (payload.occupation === "Employee" || payload.occupation === "Unemployed" || payload.occupation === "Student")) {
      return true;
    } else {
      return false;
    }
  }, [payload, routeStack]);


  return (
    <>
      <Header>
        {subtitle && <p style={{ margin: 0, fontSize: 16 }}>{subtitle}</p>}
        <HeaderTitle>{title ? title : t?.general.formTitle}</HeaderTitle>
        <ProgressBar progress={progress} />
      </Header>
      {showCampaignImg && campaignImgUrl && 
        <CampaignImg src={campaignImgUrl}/>
      }
      <Content isAnimating={isAnimating}>
        {stepTitle && <StepTitle>{stepTitle}</StepTitle>}
        <div>{children}</div>
      </Content>

      <Footer>
        <LeftContainer>
          <Help isVisible={!!helpText} onClick={() => setShowHelpModal(true)}>
            <HelpLabel>{t?.general.needHelp}</HelpLabel>
            <HelpButton className="button icon open ASEHelpButton">?</HelpButton>
          </Help>
          {!hideBackButton && (
            <BackButton
              className="button secondary icon medium"
              aria-label="Forrige"
              disabled={previousDisabled}
              onClick={() => {
                scrollTo("ASEEnrollmentForm");
                goBack();
              }}
            >
              <svg className="svg-arrow-left">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" href="#svg-arrow-left"></use>
              </svg>
            </BackButton>
          )}
        </LeftContainer>
        <RightContainer>
          {onNext && (
            <NextButton
              isGreen={!!withSuccessButton}
              className="button"
              tabIndex={1}
              onClick={async () => {
                scrollTo("ASEEnrollmentForm");
                await new Promise((resolve) => setTimeout(resolve, 50));
                await onNext();
              }}
              aria-label="Næste"
              disabled={nextDisabled}
            >
              {!loading && (buttonText ?? t?.general.next)}
              {loading && <AseSpinner />}
            </NextButton>
          )}
        </RightContainer>
      </Footer>
      {helpText && <Modal modalDescriptionHtml={helpText} open={showHelpModal} onClose={() => setShowHelpModal(false)} />}
    </>
  );
}
