import gsap from 'gsap'


export default class BenefitLinkListModule {
  constructor(element) {
    this.el = element
    this.init()
  }

  init() {
    const filterButtons = document.querySelectorAll(".benefit-list button");

    if (filterButtons.length > 0) {
      filterButtons.forEach((elm) => {

        elm.onclick = function (event) {

          var filterValue = event.target.getAttribute("data-filter").trim();

          // Clear active state on all buttons and set active on clicked button
          filterButtons.forEach((buttonElm) => {
            if (buttonElm.classList.contains("active")) {
              buttonElm.classList.remove("active");
            }
          });

          event.target.classList.add("active");
          filterBenefits(filterValue);
        };
      });
    }


    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }


    function filterBenefits(value) {

      var categoryItems = document.querySelectorAll(".benefit-item");

      categoryItems.forEach((elm) => {

        var filterCategories = elm.getAttribute("data-category");

        // Hide all
        elm.classList.add("hide-item");

        // Show only the ones we have filtered
        var filterValueArray = filterCategories.split(",");

        filterValueArray.forEach((elmFilterValue, index) => {

          var filterValue = elmFilterValue.trim();

          if (value == filterValue || value == "*") {
            elm.classList.remove("hide-item");

            // Start animation
            //TweenMax.set(elm, {opacity:1});
            //TweenMax.staggerFrom(elm, 1.5, {left:-30, delay: 2, opacity:0, delay:0, ease:Back.easeOut, force3D:true}, 0.1);
          }
        });
      });

      animateVisibleItems();
    }

    function animateVisibleItems() {
      gsap.set(".benefit-linklist-module .benefit-item:not(.hide-item)", { opacity: 1 });
      gsap.from(".benefit-linklist-module .benefit-item:not(.hide-item)", { duration: 0.8, top: -30, delay: 0, opacity: 0, ease: "power2".easeOut, force3D: true, stagger: 0.05 });
    }



    animateVisibleItems();
  }
}

