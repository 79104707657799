export default class SalaryModule {
  constructor(element) {
    this.el = element
    this.init()
  }

  init() {
    const module = this.el
    const isDev = window.location.host === 'localhost:1508' ||  window.location.host === '192.168.1.213:1508'

    const {dataset} = module
    const {
      contentsourceid,
      jobsEndpoint,
      resultsEndpoint,
    } = dataset

    let skip1 = String(module.dataset.skip_step_one);
    let skipStepOne;

    if (skip1 == 'True') {
      skipStepOne = true;
    } else if (skip1 == 'False') {
      skipStepOne = false;
    }
    console.log(skipStepOne);
    const state = {
      numSteps: 4,
      step: skipStepOne ? 1 : 0,
      situationId: null,
      jobTitleId: null,
      salary: null,
      educationId: null,
      jobExperience: null,
      regionId: null,
      age: null,
    }
  console.log("STATE:", state);
    /*const state = {
      numSteps: 4,
      step: 1,
      situationId: 1,
      jobTitleId: 2,
      salary: 38500,
      educationId: 3,
      jobExperience: 8,
      regionId: 3,
      age: 36,
    }*/

    const steps = [...document.querySelectorAll('[data-step]')]

    const loaderWrapper = module.querySelector('.loader-wrapper')

    if (skipStepOne != true) {
      console.log("IS FALSE");
      const buttonStart = module.querySelector('button.start')
      buttonStart.addEventListener('click', () => {
        updateStep(1)
        let step = 1;
        pushUrlParams({step});
      })
    }
    

    const buttonPrev = module.querySelector('[data-button-prev]')
    const buttonNext = module.querySelector('[data-button-next]')
    const buttonCover = module.querySelector('.button-cover');
    const btnNextValue = module.dataset.nextbtn;

    const situationListButtons = [...module.querySelectorAll('[data-situation-id]')]
    situationListButtons.forEach(button => {
      button.addEventListener('click', onSituationClick);
    })


    const autocomplete = module.querySelector('.autocomplete')
    const autocompleteInput = autocomplete.querySelector('#job-title')
    const autocompleteList = autocomplete.querySelector('[data-autocomplete-list]')
    const autocompleteNoResults = autocomplete.querySelector('.no-results')
    const autocompleteDefaultText = autocomplete.querySelector(".default-box");

    const clearInputButtons = [...module.querySelectorAll('.input-frame .clear')]
    clearInputButtons.map(cib => cib.addEventListener('click', onButtonClearInput))


    const inputSalary = module.querySelector('input#salary')
    const selectEducation = module.querySelector('#education')
    const inputExperience = module.querySelector('#experience')
    const labelExperience = module.querySelector('[data-experience]')
    const selectRegion = module.querySelector('#region')
    const inputAge = module.querySelector('input#age')

    const educationOptions = [...selectEducation.querySelectorAll('option')]
    const educations = educationOptions.map(option => {
      const {value} = option
      if(!value) return null
      return {id: Number(option.value), label: option.innerText}
    }).filter(r => r)

    const regionOptions = [...selectRegion.querySelectorAll('option')]
    const regions = regionOptions.map(option => {
      const {value} = option
      if(!value) return null
      return {id: Number(option.value), label: option.innerText}
    }).filter(r => r)

    const results = module.querySelector('.results')
    const buttonEditResults = results.querySelector('[data-results-edit]')
    buttonEditResults.addEventListener('click', () => {
      let step = state.step - 1;
      pushUrlParams({step});
      updateStep(-1);
    })

    const compare = module.querySelector('[data-compare]')
    const nodeSalaryYour = compare.querySelector('[data-salary-your]')
    const nodeSalaryAvg = compare.querySelector('[data-salary-avg]')
    const nodeUnderAbove = module.querySelector('.under-above')
    const nodeNumCVs = module.querySelector('.num-cvs')
    const nodeChartNumCVs = module.querySelector('[data-num-cvs]')

    const modalNoResults = module.querySelector('#modal-no-results')
    const modalNoResultsClose = modalNoResults.querySelector('[data-button-close]')
    modalNoResultsClose.addEventListener('click', closeModalNoResults)

    const modalCompareNote = module.querySelector('#modal-compare-note')
    const modalCompareNoteClose = modalCompareNote.querySelector('[data-button-close]')
    modalCompareNoteClose.addEventListener('click', closeModalCompareNote)

    const option_region = module.querySelector(".option-region");
    const option_salary = module.querySelector(".option-salary");
    const option_education = module.querySelector(".option-education");
    const modalHelpText = module.querySelector("#modal-help-text")
    const modalHelpTextClose = modalHelpText.querySelector("[data-button-close]")
    modalHelpTextClose.addEventListener('click', closeModalHelpText)
    const helperTextButton = module.querySelector("#salary-helpbox")
    if (helperTextButton){
      helperTextButton.addEventListener('click', showModalHelpText)
    }


    const buttonInfo = results.querySelector('.button-info')
    buttonInfo.addEventListener('click', showModalCompareNote)

    const selectGraph = module.querySelector('#select-graph')
    selectGraph.addEventListener('change', onGraphSelectorChange)


    const nodeChart = module.querySelector('[data-chart]')
    const nodeYaxis = nodeChart.querySelector('.y-axis')
    const nodeXaxis = nodeChart.querySelector('.x-axis')
    const nodeYLabel = module.querySelector('[data-y-label]')

    const nodeRecommended = module.querySelector('[data-recommended]')

    autocompleteInput.addEventListener('focus', onJobTitleFocus)
    autocompleteInput.addEventListener('blur', onJobTitleFocus)
    autocompleteInput.addEventListener('input', onJobTitleInput)
    autocompleteList.addEventListener('click', onAutoButtonClick)

    inputSalary.addEventListener('input', onSaleryChange)

    inputSalary.addEventListener('blur', onSalaryInput)
    selectEducation.addEventListener('change', onEducationChange)
    inputExperience.addEventListener('input', onExperienceChange)
    selectRegion.addEventListener('change', onRegionChange)
    inputAge.addEventListener('blur', onAgeInput)

    buttonPrev.addEventListener('click', onPrev)
    buttonNext.addEventListener('click', onNext)
    buttonCover.addEventListener('click',onCatch)

    let step = skipStepOne ? 1 : 0;
    pushUrlParams({step})


    function onCatch(event){
      const valid1 = autocompleteInput.checkValidity()
      const valid2 = inputSalary.checkValidity()
      if (valid1){
        autocompleteInput.style.borderColor = "#ffffff"
      }else{
        autocompleteInput.style.borderColor = "#eb3838"
      }
      if (valid2){
        inputSalary.style.borderColor = "#ffffff"
      }else{
        inputSalary.style.borderColor = "#eb3838"
      }


    }

    function onSaleryChange(event){
      validateForm();
    }

    updateStep(0)

    function onGraphSelectorChange(event) {
      const {target} = event
      const {value} = target
      renderGraph(value)
    }

    function renderGraph(type) {
      switch(type) {
        case '0':
          renderGraphSalary()
          break
        case '1':
          renderGraphRegion()
          break
        case '2':
          renderGraphEducation()
          break
        default:
          renderGraphSalary()
      }
    }

    function pushUrlParams(params)
    {
      const query = Object.keys(params).map(key => `${key}=${encodeURI(params[key])}`).join('&');
      const pathQuery = query.length > 0 ? `${window.location.pathname}?${query}` : `${window.location.pathname}`;
      window.history.pushState('', '', pathQuery);
    }

    function getUrlParams() {
      const {search} = window.location
      if(!search.includes('?')) return {}
      let hashes = search.slice(search.indexOf('?') + 1).split('&')
      return hashes.reduce((params, hash) => {
        let [key, val] = hash.split('=')

        // Change Q to term for search
        if(key.toLowerCase() === "q"){
          key = "Term";
        }

        return Object.assign(params, {[key]: decodeURIComponent(val)})
      }, {})
    }


    function renderGraphSalary() {
      const {data} = state
      const {graphResult} = data
      console.log(graphResult);
      if(!graphResult) return
      nodeYLabel.innerHTML = "Antal cv'er"

      const max = Math.max(...graphResult.map(r => r.yAxis))
      const offset = max > 50? (max > 99 ? 50: 10) : 5;
      const buffer = offset - (max % offset)
      const ceiling = max + buffer
      const numBars = ceiling / offset
      const arr = Array(numBars).fill(null).map((x, i) => ((i + 1) * offset))
      nodeYaxis.innerHTML = arr.map(item => `<div class="bar"><label>${item}</label></div>`).join('')

      nodeXaxis.innerHTML = graphResult.map((item) => {
        const {xAxis, yAxis} = item
        const pct = (yAxis / ceiling) * 100

        return `
          <div class="pillar">
            <label>${xAxis}</label>
            <div class="bar" style="height: ${pct}%"></div>
          </div>
        `
      }).join('')
    }

    function renderGraphRegion() {
      const {regionId, data} = state
      const {graphRegion} = data
      if(!graphRegion) return

      nodeYLabel.innerHTML = 'Løn'

      const max = Math.max(...graphRegion.map(r => r.yAxis))
      const offset = 10000
      const buffer = offset - (max % offset)
      const ceiling = max + buffer
      const numBars = ceiling / offset
      const arr = Array(numBars).fill(null).map((x, i) => ((i + 1) * offset))
      nodeYaxis.innerHTML = arr.map(item => `<div class="bar"><label>${item / 1000}k</label></div>`).join('')

      nodeXaxis.innerHTML = graphRegion.map((item) => {
        const {xAxis, yAxis} = item
        const pct = (yAxis / ceiling) * 100
        const reg = regions.find(e => e.label === item.xAxis)
        const id = reg ? reg.id : null
        const you = regionId === id
        return `
          <div class="pillar ${you ? 'you' : ''}">
            <label>${xAxis}</label>
            <div class="bar" style="height: ${pct}%"></div>
          </div>
        `
      }).join('')
    }

    function renderGraphEducation() {
      const {educationId, data} = state
      const {graphEducation} = data
      if(!graphEducation) return

      nodeYLabel.innerHTML = 'Løn'

      const max = Math.max(...graphEducation.map(r => r.yAxis))
      const offset = 10000
      const buffer = offset - (max % offset)
      const ceiling = max + buffer
      const numBars = ceiling / offset
      const arr = Array(numBars).fill(null).map((x, i) => ((i + 1) * offset))
      nodeYaxis.innerHTML = arr.map(item => `<div class="bar"><label>${item / 1000}k</label></div>`).join('')

      nodeXaxis.innerHTML = graphEducation.map((item) => {
        const {xAxis, yAxis} = item
        const pct = (yAxis / ceiling) * 100
        const edu = educations.find(e => e.label === item.xAxis)
        const id = edu ? edu.id : null
        const you = educationId === id
        return `
          <div class="pillar ${you ? 'you' : ''}">
            <label>${xAxis}</label>
            <div class="bar" style="height: ${pct}%"></div>
          </div>
        `
      }).join('')

      let educationLabels =  nodeXaxis.querySelectorAll('label');
      if (educationLabels){
        educationLabels.forEach(label =>{
          let innerLabel = label.innerHTML
          if (innerLabel.includes('uddannelse')){
            label.innerHTML = innerLabel.replace('uddannelse', 'udd.')
          }
        });
      }

    }

    function renderRecommended() {
      const {recommended} = state.data
      nodeRecommended.innerHTML = recommended.map(article => {
        const {
          title,
          text,
          linkText,
          linkUrl,
        } = article

        return `
          <div class="column col-sm-12 col-md-4">
          <div class="card blue">
            <div class="article">
              <img class="icon-article" src="/assets/images/icon-article.svg" alt="artikel ikon">
              <div class="content">
                <h3 class="headline-tiny">${title}</h3>
                <p>${text}</p>
                <div class="arrow-link">
                  <img class="" src="/assets/images/arrow.png" alt="pil">
                  <a href="${linkUrl}">${linkText}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        `
      }).join('')
    }

    function onPrev(event) {
      let step = state.step - 1;
      event.preventDefault()
      updateStep(-1)
      pushUrlParams({step})
    }

    function onNext(event) {
      let step = state.step + 1;
      if (state.step == 2){
        const valid1 = autocompleteInput.checkValidity()
        const valid2 = inputSalary.checkValidity()
        const formValid = valid1 && valid2;
        if (formValid){
          updateStep(1)
          pushUrlParams({step})
        }else{
          buttonNext.disabled = !formValid
        }
      }
      else{
        updateStep(1)
        pushUrlParams({step})
      }
    }



    function onNextHover(event){
      buttonNext.focus();
    }

    function loadResults() {
      loaderWrapper.classList.remove('hide')

      const {
        situationId,
        jobTitleId,
        salary,
        educationId,
        jobExperience,
        regionId,
        age,
      } = state

      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          contentSourceId: contentsourceid,
          situationId: situationId,
          jobTitleId: jobTitleId,
          salary: salary,
          educationId: educationId,
          jobExperience: jobExperience,
          regionId: regionId,
          age: age,
        })
      }

      fetch(resultsEndpoint, isDev ? {} : options)
        .then(response => response.json())
        .then(data => {
          console.log("data:", data);
          state.data = data
          if(data && data.salaryAvg) {
            if (data.graphResult) {
              if (data.graphResult.length < 1) {
                option_salary.style.display = "none";
              } else {
                option_salary.style.display = "block";
              }
            } else {
              option_salary.style.display = "none";
            }

            if (data.graphEducation) {
              if (data.graphEducation.length < 1) {
                option_education.style.display = "none";
              } else {
                option_education.style.display = "block";
              }
            } else {
              option_education.style.display = "none";
            }

            if (data.graphRegion) {
              if (data.graphRegion.length < 1) {
                option_region.style.display = "none";
              } else {
                option_region.style.display = "block";
              }
            } else {
              option_region.style.display = "none";
            }


            updateNumCVs()
            renderResultsCompare()
            renderGraph(selectGraph.value)
            renderRecommended()
          } else {
            updateStep(-1)
            showModalNoResults()
          }
          loaderWrapper.classList.add('hide')
        })
        .catch((err) => {
          console.log('error', err)
          loaderWrapper.classList.add('hide')
        })
    }

    function renderResultsCompare() {
      const {salary} = state
      const {salaryAvg} = state.data

      nodeSalaryYour.innerHTML = thousandSeparator(salary)
      nodeSalaryAvg.innerHTML = thousandSeparator(salaryAvg)

      const diff = salary - salaryAvg
      if(diff < 0) {
        nodeUnderAbove.innerHTML = `<strong>${thousandSeparator(Math.abs(diff))}</strong> kr. under`
      } else if(diff > 0) {
        nodeUnderAbove.innerHTML = `<strong>${thousandSeparator(Math.abs(diff))}</strong> kr. over`
      } else {
        nodeUnderAbove.innerHTML = `præcis på`
      }
    }

    function showModalNoResults() {
      modalNoResults.classList.add('show')
    }

    function closeModalNoResults() {
      modalNoResults.classList.remove('show')
    }

    function showModalHelpText(){
      modalHelpText.classList.add('show')
    }

    function closeModalHelpText(){
      modalHelpText.classList.remove('show')
    }


    function showModalCompareNote() {
      modalCompareNote.classList.add('show')
    }

    function closeModalCompareNote() {
      modalCompareNote.classList.remove('show')
    }

    function updateNumCVs() {
      const {numberOfSamples} = state.data

      if(numberOfSamples < 100) {
        nodeNumCVs.innerHTML = 'under 100 CV\'er'
        nodeChartNumCVs.innerHTML = 'under 100 CV\'er'
      } else if(numberOfSamples > 99 && numberOfSamples < 500) {
        nodeNumCVs.innerHTML = 'over 100 CV\'er'
        nodeChartNumCVs.innerHTML = 'over 100 CV\'er'
      } else {
        nodeNumCVs.innerHTML = 'over 500 CV\'er'
        nodeChartNumCVs.innerHTML = 'over 500 CV\'er'
      }
    }

    function updateStep(direction) {
      state.step += direction
      const isPreResults = state.step === state.numSteps - 2
      buttonNext.innerHTML = isPreResults ? 'Se resultat' : btnNextValue

      buttonPrev.hidden = state.step === 0

      const isLastStep = state.step === state.numSteps - 1;
      const isFirstStep = state.step === 1;

      buttonNext.hidden = isLastStep;

      const noSituationFilled = state.step === 1 && state.situationId === null
      //const missingInputs = state.step === 1 && (!state.jobTitleId || !state.salary);

      buttonNext.disabled = noSituationFilled;

      if (isFirstStep && state.situationId != null){
        buttonCover.style.display = "none";
      }
      
      steps.forEach((node, idx) => {
        const {dataset} = node
        const {step, step2} = dataset
        const showSection = Number(step) === state.step || Number(step2) === state.step
        node.hidden = !showSection
      })

      if(isLastStep) {
        loadResults()
      }

      const {top} = module.getBoundingClientRect()
      window.scrollTo({
        top: top + window.scrollY - 150
      })
      if (state.step == 2){
        validateForm();
      }
      console.log("UPDATE STATE", state);
      if (skipStepOne && state.step == 1) {
        buttonPrev.style.visibility = "hidden";
      } else {
        buttonPrev.style.visibility = "visible";
      }
    }

    function onSituationClick(event) {
      const {target} = event
      const {dataset} = target
      const {situationId} = dataset
      state.situationId = situationId

      situationListButtons.forEach(slb => {
        if(target !== slb) {
          slb.classList.remove('active')
        }
        target.classList.add('active')
      })
      buttonNext.disabled = false
      buttonNext.click();
    }

    function onJobTitleFocus(event){
      if (event.type == "focus"){
        if (autocompleteInput.value.length == 0){
          autocompleteDefaultText.style.display = "block";
          autocompleteList.innerHTML = "";
        }
      }
      else{
        autocompleteDefaultText.style.display = "none";
      }

    }

    function onJobTitleInput(event) {
      const q = event.target.value
      validateForm();
      autocompleteNoResults.style.display = 'none'
      if(q.length < 2) return

      autocompleteNoResults.style.display = 'none';
      autocompleteDefaultText.style.display = "none";

      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            query: q
          }
        )
      }

      fetch(jobsEndpoint, isDev ? {} : options)
        .then(response => response.json())
        .then(data => {
          if(data) {
            if(!data || data.length === 0) {
              autocompleteNoResults.style.display = 'block';
              autocompleteDefaultText.style.display = 'none';
              autocompleteList.innerHTML = "";
            } else {
              autocompleteList.innerHTML = "";
              autocompleteList.innerHTML = data.map(item => {
                const {id, title} = item
                return `
                <li>
                  <button data-job-title-id="${id}">${title}</button>
                </li>
              `
              }).join('')
            }
          }
        })
        .catch((err) => {
          console.log('error', err)
        })
    }

    function validateForm() {
      const valid1 = autocompleteInput.checkValidity()
      const valid2 = inputSalary.checkValidity()
      const formValid = valid1 && valid2
      buttonNext.disabled = !formValid
      if (valid1){
        autocompleteInput.style.borderColor = "#ffffff"
      }
      if (valid2){
        inputSalary.style.borderColor = "#ffffff"
      }
      if (!formValid){
        buttonCover.style.display = "block";
      }else{
        buttonCover.style.display = "none";
      }
    }

    function thousandSeparator(integer) {
      if(!integer) return integer
      return integer.toLocaleString('da-DK').split(',').join('.')
    }

    function onAutoButtonClick(event) {
      const {target} = event
      const {dataset} = target
      const {jobTitleId} = dataset
      autocompleteInput.value = target.textContent
      autocompleteList.innerHTML = ''
      state.jobTitleId = Number(jobTitleId)
      validateForm()
    }

    function onSalaryInput(event) {
      const {target} = event
      const {value} = target
      if(!value) return

      const stripped = value.split('.').join('').split(',').join('')
      const salary = Number(stripped)
      if(isNaN(salary)) return
      inputSalary.value = thousandSeparator(salary)
      state.salary = salary
      validateForm()
    }

    function onEducationChange(event) {
      const {target} = event
      const {value} = target
      const buttonClear = target.parentNode.parentNode.querySelector('.clear')
      if(!value) {
        state.educationId = null
        buttonClear.style.display = 'none'
      } else {
        state.educationId = Number(value)
        buttonClear.style.display = 'block'
      }
    }

    function onExperienceChange(event) {
      const {target} = event
      const {value} = target
      const buttonClear = target.parentNode.parentNode.querySelector('.clear')
      if(!value || value === '0') {
        labelExperience.innerHTML = ''
        state.jobExperience = null
        buttonClear.style.display = 'none'
      } else {
        labelExperience.innerHTML = value
        state.jobExperience = Number(value)
        buttonClear.style.display = 'block'
      }
    }

    function onRegionChange(event) {
      const {target} = event
      const {value} = target
      const buttonClear = target.parentNode.parentNode.querySelector('.clear')
      if(!value) {
        state.regionId = null
        buttonClear.style.display = 'none'
      } else {
        state.regionId = Number(value)
        buttonClear.style.display = 'block'
      }
    }

    function onAgeInput(event) {
      const {target} = event
      const {value} = target
      const buttonClear = target.parentNode.parentNode.querySelector('.clear')
      if(!value) {
        state.age = null
        buttonClear.style.display = 'none'
      } else {
        const age = Number(value)
        if(isNaN(age)) return
        state.age = Number(value)
        buttonClear.style.display = 'block'
      }
    }

    function onButtonClearInput(event) {
      const {target} = event
      const {parentNode} = target
      let input = parentNode.querySelector('select') || parentNode.querySelector('input')
      const {type} = input
      input.value = type !== 'range' ? '' : 0
      if ('createEvent' in document) {
        const evt = document.createEvent("HTMLEvents")
        evt.initEvent("change", false, true)
        input.dispatchEvent(evt)

        evt.initEvent("input", false, true)
        input.dispatchEvent(evt)

        evt.initEvent("blur", false, true)
        input.dispatchEvent(evt)

      } else {
        input.fireEvent("onchange")
      }
    }
  }
}


  