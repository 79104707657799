import { useStore } from "../../store/store";
import "dayjs/locale/da";
import dayjs from "dayjs";
import { Content, Header, HeaderTitle, Row, Wrapper } from "./TotalAmountBox.styles";

interface TotalAmountBoxProps {
  date?: string;
  hideHeader?: boolean;
  rows: { label: string; value: string; bold?: boolean }[];
  withGradient?: boolean;
}

export default function TotalAmountBox({ date, rows, hideHeader = false, withGradient = false }: TotalAmountBoxProps) {
  const t = useStore.useTranslations();

  return (
    <Wrapper>
      {!hideHeader && (
        <Header>
          <HeaderTitle>{t?.summaryStep.enrollmentDate}</HeaderTitle>
          <div style={{ textTransform: "capitalize" }}>{dayjs(date).isValid() && dayjs(date).locale("da").format("DD. MMM YYYY")}</div>
        </Header>
      )}
      <Content withGradient={withGradient}>
        {rows.map((r, index) => (
          <Row key={index}>
            <div className={r.bold ? "bold" : ""}>{r.label}</div>
            <div className={r.bold ? "bold" : ""}>{r.value}</div>
          </Row>
        ))}
      </Content>
    </Wrapper>
  );
}
