import gsap from 'gsap'
import ScrollMagic from './scrollmagic/uncompressed/ScrollMagic'

const inView = () => {
    var inView = [...document.querySelectorAll('.inview-module')];
    let elm = void 0;
    let controller = void 0;


    function _initit() {
        controller = new ScrollMagic.Controller({ loglevel: 2 });


        inView.forEach(function (elm) {
            var inviewOffset = elm.getAttribute("data-inviewoffset") ? elm.getAttribute("data-inviewoffset") : 0;


            // On mobile decrease the offset by 25%
            if (window.innerWidth < 767) {
                inviewOffset = parseInt(inviewOffset * 0.75);

            }


            if (elm.getBoundingClientRect().top <= window.innerHeight * 0.9 && elm.getBoundingClientRect().top > 0) {
                elm.classList.add("nview");
            }
            else {
                var videoSceen = new ScrollMagic.Scene({
                    triggerElement: elm,
                    offset: inviewOffset
                }).on('enter', function (event) {
                    if (!elm.classList.contains("nview")) {
                        elm.classList.add("nview");
                    }

                })
                    .addTo(controller);
            }

        });
    }

    if (inView.length > 0) {

        setTimeout(function () {
            _initit();
        }, 50);
    }
}

export default inView