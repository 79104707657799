import { SpringValue } from "@react-spring/web";
import { Inner, SubTitle, Title, Wrapper } from "./StartScreen.styles";
import * as StartAnimation from "./start-animation.json";
import Lottie from "react-lottie";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function StartScreen({ style }: Props) {
  return (
    <Wrapper style={style}>
      <Inner>
        <Title>Et ekstra økonomisk sikkerhedsnet til dig</Title>
        <SubTitle>Få ro og overskud til at søge nyt job.</SubTitle>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: StartAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={200}
          width={200}
        />
      </Inner>
    </Wrapper>
  );
}
