import { animated } from "@react-spring/web";
import styled from "styled-components";

export const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const Title = styled.h3`
  text-transform: initial;
  margin-bottom: 45px;
  font-size: 28px;
  text-align: center;
`;

export const Label = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Value = styled.p`
  font-size: 22px;
  font-weight: 700;
  justify-: center;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  line-height: 32px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: var(--color-petrol);
  color: var(--color-white);
  border-radius: 6px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-: center;
  box-shadow: 0px 0px 7px 4px rgba(0, 142, 159, 0);
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 0px 0px 7px 4px rgba(0, 142, 159, 0.28);
  }

  &:active {
    background-color: #008393;
  }

  &:disabled {
    background-color: #b7b7b7;
    box-shadow: none;
  }
`;

export const ResultWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 2px solid var(--color-coal);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 50px;

  span {
    text-transform: initial;
  }

  &:after {
    position: absolute;
    : "";
    bottom: -6px;
    left: 0px;
    width: 100%;
    border-bottom: 2px solid var(--color-coal);
  }
`;

export const ResultValue = styled.span`
  font-size: 48px;
  line-height: 48px;
  text-transform: initial;
  padding: 0 10px;
  color: var(--color-orange);
  padding-top: 20px;
`;
