import styled from "styled-components";
import Button from "../Button";

export const Container = styled.div<{ active: boolean; maxWidth?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 5px solid transparent;
  box-shadow: 0 0.1875rem 1rem #00000029;
  border-radius: 1.25rem;
  padding: 20px;

  ${({ active }) =>
    active &&
    `
  border: 1px solid var(--color-orange);
  `}

  ${({ maxWidth }) =>
    maxWidth &&
    `
  max-width: ${maxWidth}px;
  `}

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Discount = styled.div`
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  background-color: var(--color-orange);
  font-weight: 700;
  border-radius: 10px;
  padding: 4px 10px;
  white-space: nowrap;
  color: #4f4a45;
`;

export const Title = styled.div`
  font-size: 28px;
  color: var(--color-orange);
  font-weight: 700;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 20px;
`;

export const Price = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #4f4a45;
  padding-bottom: 20px;
`;

export const FeatureTitle = styled.div`
  font-size: 18px;
  color: #4f4a45;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FeatureItem = styled.div<{ bold: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ bold }) => (bold ? "#479376" : "#4F4A45")};
  padding-bottom: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Spacer = styled.div`
  flex: 1;
  min-height: 20px;
`;

export const CardButton = styled(Button)`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
