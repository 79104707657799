import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-coal-25);
`;

export const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 4px;
  background-color: #09ba7b;
  transition: width 0.8s ease;
`;
