import { SpringValue } from "@react-spring/web";
import { Inner, Spinner, Title, Wrapper } from "./LoadingScreen.styles";
import { useEffect } from "react";
import { SalaryInsuranceStateEnum } from "../../../../types/SalaryInsuranceStateEnum";
import { useStore } from "../../../../store/store";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function LoadingScreen({ style }: Props) {
  const setSalaryInsuranceState = useStore.useSetSalaryInsuranceState();
  useEffect(() => {
    const loading = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSalaryInsuranceState(SalaryInsuranceStateEnum.CALCULATOR_SCREEN);
    };

    loading();
  }, []);
  return (
    <Wrapper style={style}>
      <Inner>
        <Title>Finder din pris...</Title>
        <Spinner />
      </Inner>
    </Wrapper>
  );
}
