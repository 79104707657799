import styled from "styled-components";

export const Description = styled.p`
  font-size: 16px;
  padding-bottom: 20px;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: black;
  }
`;

export const ConsentWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  border: 1px solid black;
  padding: 20px;
  padding-left: 50px;
  cursor: pointer;
`;

export const ConsentCheck = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  left: 10px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid black;

  ${({ active }) =>
    active &&
    `
    background-color: #57bc98;
    border-color: #57bc98;
  `}
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
