import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
`;

export const Inner = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;

  p {
    margin: 0;
    text-align: center;
  }

  h2 {
    text-align: center;
    text-transform: initial;
  }

  a {
    text-decoration: underline;
  }

  .WithMargin {
    margin-bottom: 1rem;
  }
`;

export const Date = styled.div`
  margin: 20px 0;
  margin-left: -30px;
  width: calc(100% + 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 3px 13px -1px rgba(0, 0, 0, 0.25);
`;
