import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../../store/store";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import { Wrapper, Date } from "./ReceiptScreen.styles";
import Spinner from "../../../../../../components/Spinner";
import dayjs from "dayjs";
import Lottie from "react-lottie";
import * as SuccessAnimation from "./SuccessAnimation.json";
import Button from "../../../../../../components/Button";
import { TrackTracePaymentType } from "../../../../../../types/TrackTraceResponseModel";
import { postTrackAndTraceBuyUnion } from "../../../../../../utils/api";

interface UnionMemberScreenProps {
  onHeightChange: (height: number) => void;
}

enum LoadingState {
  Initial,
  Loading,
  Success,
  Error,
}

export default function ReceiptScreen({ onHeightChange }: UnionMemberScreenProps) {
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const route = useStore.useUnionFlowRoute();
  const response = useStore.useTrackTraceResponse();
  const payload = useStore.useUnionFlowPayload();
  const [loading, setLoading] = useState(LoadingState.Initial);

  const onSendRequest = async () => {
    try {
      setLoading(LoadingState.Loading);
      await postTrackAndTraceBuyUnion(payload);
      setLoading(LoadingState.Success);
    } catch (err) {
      setLoading(LoadingState.Error);
    }
  };

  useEffect(() => {
    if (loading === LoadingState.Initial && route === UnionFlowRouteEnum.RECEIPT) {
      onSendRequest();
    }
  }, [route, loading]);

  const animationPosition = useMemo(() => {
    switch (route) {
      case UnionFlowRouteEnum.RECEIPT:
        return AnimatedSlidePosition.MIDDLE;
      case UnionFlowRouteEnum.CONFIRM:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.RIGHT_NO_ANIMATION;
    }
  }, [route]);

  const getProducts = () => {
    let products = [];

    if (payload.wantsUnion) {
      products.push("Fagforening");
    }

    if (payload.wantsFamilyUnion) {
      products.push("FamilieFagforening");
    }

    return products.reduce((acc, cur, i) => {
      if (i + 1 === products.length && products.length > 1) {
        return acc + " og " + cur;
      } else {
        const seperator = acc ? ", " : "";
        return acc + seperator + cur;
      }
    }, "");
  };

  const signup = useMemo(() => {
    if (payload.currentlyMemberOfAUnion) {
      return dayjs().add(1, "month").startOf("month").locale("da").format("DD. MMM YYYY");
    } else {
      return dayjs().locale("da").format("DD. MMM YYYY");
    }
  }, [payload]);

  const onClose = async () => {
    setUnionFlowVisible(false);
    window.location.reload();
  };

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} title="" onClose={() => setUnionFlowVisible(false)}>
      {loading === LoadingState.Loading && (
        <Wrapper style={{ height: 250 }}>
          <Spinner isCentered />
        </Wrapper>
      )}
      {loading === LoadingState.Error && (
        <Wrapper style={{ height: 250 }}>
          <h3 style={{ paddingBottom: 30 }}>
            <b>Der opstod en fejl, prøv venligst senere!</b>
          </h3>

          <Button style={{ width: 350, marginTop: 20 }} onClick={onClose}>
            Luk
          </Button>
        </Wrapper>
      )}
      {loading === LoadingState.Success && (
        <Wrapper>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: SuccessAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={130}
            width={130}
            style={{ marginBottom: 20 }}
          />
          <h3>
            <b>Hej {response?.name}</b>
          </h3>
          <p>{`Vi er glade, for du har valgt at blive medlem af vores ${getProducts()}.`}</p>
          <p>
            <b>Betalingsaftale</b>
          </p>
          <p className="WithMargin">{response?.paymentType === TrackTracePaymentType.PaymentService ? `Tilmelding til Betalingsservice sker automatisk. Inden for to arbejdsdage kan du se aftalen i din Netbank eller i din Betalingsservice app. Du modtager bekræftelse og kvittering af dit medlemskab på email` : `Du har valgt betaling via MobilePay. Indenfor 2 arbejdsdage modtager du information til opsætning af din betalingsaftale, bekræftelse og kvittering af dit medlemskab på email`}</p>

          <p>Dit medlemskab er aktivt fra:</p>
          <Date>
            <p>
              <b>Indmeldelsesdato</b>
            </p>
            <p style={{ textTransform: "capitalize" }}>{signup}</p>
          </Date>

          <p>Venlig hilsen</p>
          <p>
            <b>Ase</b>
          </p>

          <Button style={{ width: 350, marginTop: 20 }} onClick={onClose}>
            Luk
          </Button>
        </Wrapper>
      )}
    </AnimatedSlideContainer>
  );
}
