import { useStore } from "../../../../store/store";
import { Inner, Wrapper } from "./CompletedContactYouStep.styles";

export default function CompletedContactYouStep() {
  const payload = useStore.usePayload();

  return (
    <Wrapper>
      {payload.occupation === "Student" ? (
        <Inner>
          <h2>Vi har modtaget dine kontaktoplysninger</h2>

          <p className="WithMargin">Vi ringer til dig hurtigst muligt for at blive klogere på dig og din uddannelse, og om vi er det rette match.</p>

          <p className="WithMargin">Vi glæder os til at snakke med dig.</p>

          <p>Med venlig hilsen</p>
          <p>
            <b>Ase</b>
          </p>
        </Inner>
      ) : (
        <Inner>
          <h2>Dine oplysninger er sendt</h2>
          <p className="WithMargin">
            <b>Har du prøvet et løntjek?</b>
          </p>

          <p className="WithMargin">I Ases lønberegner kan du finde ud af, om du tjener nok, eller hvad du kan forvente at få i løn, hvis du skifter job eller er ny på arbejdsmarkedet.</p>

          <p className="WithMargin">
            <a href="https://ase.dk/faa-svar/loen/loenberegner?step=1">{">>"} Tjek din løn her</a>
          </p>

          <p>Med venlig hilsen</p>
          <p>
            <b>Ase</b>
          </p>
        </Inner>
      )}
    </Wrapper>
  );
}
