import gsap, { Quad } from 'gsap'
import ScrollMagic from '../../libs/scrollmagic/uncompressed/ScrollMagic'

export default class Header {
    constructor(element) {
        this.el = element
        this.init()
    }

    init() {
        this.logoAnimation()
        this.burgerAnimation()
        this.hideTabsOnScroll()
        this.toggleLoginMenu()
    }

    hideTabsOnScroll() {
        const headerContainer = this.el;
        let lastScroll = 0;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                headerContainer.classList.remove("scroll-up");
                return;
            }

            if (currentScroll > lastScroll && !headerContainer.classList.contains("scroll-down")) {
                // down
                headerContainer.classList.remove("scroll-up");
                headerContainer.classList.add("scroll-down");
            } else if (
                currentScroll < lastScroll &&
                headerContainer.classList.contains("scroll-down")
            ) {
                // up
                headerContainer.classList.remove("scroll-down");
                headerContainer.classList.add("scroll-up");
            }
            lastScroll = currentScroll;
        })
    }

    logoAnimation() {
        const logoContainer = this.el;
        const bodyContainer = document.querySelector("main");

        const controller = new ScrollMagic.Controller({ loglevel: 2 });

        if (logoContainer) {
            var scene = new ScrollMagic.Scene({
                triggerElement: bodyContainer,
                triggerHook: 'onLeave',
                offset: 200
            })
                .on('enter', function () {
                    shrinkMenuClass();
                })
                .on('leave', function () {
                    shrinkMenuClass();
                })
                .addTo(controller);


            var shrinkMenuClass = function () {
                logoContainer.classList.toggle('small');
            }
        }
    }

    burgerAnimation() {
        //Create timeline and get logo circle
        var controlit = this.el.querySelector("#burger");
        var menuToggle = gsap.timeline({ paused: true, reversed: true });
        menuToggle
            /*
            .set('', {className:"-=closemenu"})
            .set('', {className:"+=openmenu"})
            */
            .to(' .top', { duration: .2, y: '-9px', transformOrigin: '50% 50%' }, 'burg')
            .to(' .bot', { duration: .2, y: '9px', transformOrigin: '50% 50%' }, 'burg')
            .to(' .mid', { duration: .2, scale: 0.1, transformOrigin: '50% 50%' }, 'burg')
            .add('rotate')
            .to(' .top', { duration: .2, y: '5' }, 'rotate')
            .to(' .bot', { duration: .2, y: '-5' }, 'rotate')
            .to(' .top', { duration: .2, rotationZ: 45, transformOrigin: '50% 50%' }, 'rotate')
            .to(' .bot', { duration: .2, rotationZ: -45, transformOrigin: '50% 50%' }, 'rotate')
        // .set('#burger .mid', {opacity:0})//temp fix for stupid iOS rotate y bug


        if (controlit) {
            controlit.onclick = function () {
                menuToggle.reversed() ? menuToggle.restart() : menuToggle.reverse();

            };
        }

        const moveButton = document.querySelector('.navigation-burger');
        const subnavigationContainer = document.querySelector('#sub-navigation');
        const items = [...document.querySelectorAll('.menu__item')];
        const root = document.body;
        const headerContainer = this.el;
        let isMoved = false;

        if (moveButton) {
            moveButton.onclick = function () {
                // toggle flag
                isMoved = !isMoved;

                if (isMoved) {
                    root.classList.add('menuOpen');
                    headerContainer.classList.remove("scroll-down")
                    headerContainer.classList.add("scroll-up")
                    subnavigationContainer.classList.add('menuOpen');
                }
                else {
                    root.classList.remove('menuOpen');
                    subnavigationContainer.classList.remove('menuOpen');
                }


                items.forEach((item, index) => {
                    setTimeout(() => {
                        item.classList.toggle('is-moved')
                    }, index * 30);
                });
            };
        }
    }

    toggleLoginMenu() {
        const headerContainer = this.el;
        const loginMenuItem = headerContainer.querySelector('.login')

        if (!loginMenuItem) return;

        const outerContainer = loginMenuItem.querySelector('.login-menu')
        const innerContainerHeight = loginMenuItem.querySelector('.login-menu__inner').offsetHeight

        loginMenuItem.addEventListener('mouseover', (e) => {
            loginMenuItem.classList.add("show-menu")
            outerContainer.style.height = innerContainerHeight + 'px'
        })

        loginMenuItem.addEventListener('mouseleave', (e) => {
            loginMenuItem.classList.remove("show-menu")
            outerContainer.style.height = '0px'
        })
    }
}
