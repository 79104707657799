export default class Help {
  constructor(element) {
    this.el = element

    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleKeyUpLabelClose = this.handleKeyUpLabelClose.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.init()
  }

  init() {
    const modalId = this.el.getAttribute('for')

    this.el.addEventListener('click', this.toggleModal)
    this.el.addEventListener('keyup', this.handleKeyUp)

    this.modal = document.querySelector(`#${modalId}`)
    this.labelClose = this.modal.querySelector('.label-close')
    this.labelClose.addEventListener('click', this.closeModal)
    this.labelClose.addEventListener('keyup', this.handleKeyUpLabelClose)
  }

  handleKeyUp(event) {
    event.preventDefault()
    // console.log('code', event.keyCode)
    switch (event.keyCode) {
      case 27:
        // ESC:
        this.modal.classList.remove('show')
        break;
      case 32:
      case 13:
        // SPACE (32), RETURN (13)
        this.toggleModal()
        break;
      default:
    }
  }

  handleKeyUpLabelClose(event) {
    event.preventDefault()
    switch (event.keyCode) {
      case 27:
      case 32:
      case 13:
        // ESC (27), SPACE (32), RETURN (13)
        this.closeModal()
        break;
      default:
    }
  }

  toggleModal() {
    const visible = this.modal.classList.contains('show')
    if(!visible) {
      this.modal.classList.add('show')
      setTimeout(() => {
        this.labelClose.focus()
      }, 500)
    } else {
      this.closeModal()
    }
  }

  closeModal() {
    this.modal.classList.remove('show')
    setTimeout(() => {
      this.el.focus()
    }, 500)
  }
}