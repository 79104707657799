import styled from "styled-components";

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr;
  max-width: var(--content-max-width);
  padding: 5rem var(--grid-gutter);
  margin: 0 auto;
  max-width: 824px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
