import { Wrapper, Inner, Container, Label } from "./Checkbox.styles";

interface Props {
  checked?: boolean;
  text?: string;
  onChange?: () => void;
}

export default function Checkbox({ onChange, checked = false, text }: Props) {
  return (
    <Container onClick={onChange}>
      <Wrapper>{checked && <Inner />}</Wrapper>
      {text && <Label>{text}</Label>}
    </Container>
  );
}
