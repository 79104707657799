export default class ProductCards {
    constructor(element) {
        this.el = element
        this.init()
        this.initToogleBar()
    }

    init() {
        const productCardsWheel = this.el.querySelector(".product-cards-wheel");
        this.productCardGliders = []
        this.activeProductCardGliders = undefined;


        if (!productCardsWheel) {
            return
        } else {
            const sliders = productCardsWheel.querySelectorAll(".glide");
            //const glideSlides = slide.querySelector(".glide__slides");
            const touchOverlay = this.el.querySelector(".touch-overlay");

            this.initProductCardModals();

            if (sliders && sliders.length > 0) {
                // Initiate glide slider.
                sliders.forEach((elm, index) => {
                    const glider = new ProductCardGlider(elm);
                    this.productCardGliders.push(glider);

                    if (index === 0) {
                        this.activeProductCardGliders = glider;
                        this.activeProductCardGliders.show();
                    }
                });
 

                //Checking if the slider img is visible in the viewport
                function overlayVisibilInViewport() {
                    const bubbleImg = document.querySelector(".bubble")
                    const elementStyle = window.getComputedStyle(bubbleImg);
                    if(
                        elementStyle.height == '0px' ||
                        elementStyle.display == 'none' ||
                        elementStyle.opacity == '0' ||
                        elementStyle.visibility == 'hidden' ||
                        elementStyle.transform == 'scale(0)'
                        ) {
                        return false;
                    } 
                
                    const rect = bubbleImg.getBoundingClientRect();
                            
                    return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                        ); 
                };

                
                productCardsWheel.addEventListener('touchstart', e => {
                    if(overlayVisibilInViewport()) {
                        touchOverlay.classList.remove('loaded', 'pulse');
                    }
                });
                productCardsWheel.addEventListener('mousedown', e => {
                    if(overlayVisibilInViewport()) {
                        touchOverlay.classList.remove('loaded', 'pulse');
                    }
                })
             
              
            }
        }
    }

    initProductCardModals() {
        const featureItemsElm = [...this.el.querySelectorAll(".feature-item")].filter((elm) => elm.hasAttribute("data-description"));

        featureItemsElm.forEach((elm) => {
            const button = elm.querySelector(".info-circle");
            const description = elm.dataset.description;

            if (button && description) {
                const callback = () => this.onProductCardModalOpen(JSON.parse(description).Value)
                button.addEventListener("click", callback);
            }
        })
    }

    initToogleBar() {
        this.productCardsToggleBar = this.el.querySelector(".toggle-bar");
        // Add event listener to toggle bar to toggle between product cards.
        if (this.productCardsToggleBar) {
            this.toggleBarButtons = this.productCardsToggleBar.querySelectorAll(".toggle-bar-button");
            this.toggleBarIndicator = this.productCardsToggleBar.querySelector(".toggle-bar--indicator");

            this.toggleBarButtons.forEach((elm, index) => {
                if (index === 0) {
                    elm.classList.add("active");
                    this.toggleBarIndicator.style.left = elm.offsetLeft + "px"
                    this.toggleBarIndicator.style.width = elm.offsetWidth + "px"
                }

                const callback = (event) => this.handleToggleClicked(event, index)

                elm.addEventListener("click", callback);
            })

            window.addEventListener("resize", () => {
                const activeToggleBarButton = this.productCardsToggleBar.querySelector(".toggle-bar-button.active");
                this.toggleBarIndicator.style.left = activeToggleBarButton.offsetLeft + "px"
                this.toggleBarIndicator.style.width = activeToggleBarButton.offsetWidth + "px"
            });
        }
    }

    handleToggleClicked(event, index) {
        // Remove active class
        this.toggleBarButtons.forEach((elm) => {
            elm.classList.remove("active");
        })
        // Hide active product card glider
        this.productCardGliders.forEach((glider, i) => {
            if (i === index) {
                this.activeProductCardGliders = glider;
                this.activeProductCardGliders.show();
            } else {
                glider.hide();
            }
        })

        this.toggleBarIndicator.style.left = event.currentTarget.offsetLeft + "px"
        this.toggleBarIndicator.style.width = event.currentTarget.offsetWidth + "px"
        event.currentTarget.classList.add("active");
    }

    async onProductCardModalOpen(description) {
        let wrapperElm = document.createElement("div");
        wrapperElm.classList.add("product-card-modal");
        window.document.body.appendChild(wrapperElm);
        wrapperElm.innerHTML += `
            <div class="product-card-modal__inner">
                <div class="product-card-modal__content"><div>${description}</div></div>
                <button class="product-card-modal__close"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#fff" height="30" width="30" xmlns="http://www.w3.org/2000/svg" style="color: rgb(255, 255, 255);"><path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path></svg></button>
            </div>
        `;
        const modal = document.querySelector(".product-card-modal");
        const closeButton = document.querySelector(".product-card-modal__close");
        await new Promise((resolve) => setTimeout(resolve, 100));
        modal.classList.add("active");
        closeButton.addEventListener("click", this.onProductCardModalClose.bind(this));
    }

    async onProductCardModalClose() {
        const modal = document.querySelector(".product-card-modal");
        modal.classList.remove("active");
        await new Promise((resolve) => setTimeout(resolve, 100));
        modal.remove();
    }
}

class ProductCardGlider {
    constructor(element) {
        this.el = element
        this.init()
        this.resizeCallback = this.resizeFunction.bind(this)
    }

    init() {
        this.glideSlides = this.el.querySelector(".glide__slides");
        this.el.style.display = "none";
        this.productCardGlider = new Glide(this.el, {
            startAt: 0
        });
        this.productCardGlider.mount();
    }

    show() {
        this.el.style.display = "block";
        this.productCardGlider.update();
        this.productCardGlider.enable();
        this.resizeFunction();
        window.addEventListener("resize", this.resizeCallback);
    }

    hide() {
        this.el.style.display = "none";
        this.productCardGlider.disable();
        window.removeEventListener("resize", this.resizeCallback);
    }

    resizeFunction() {
        const svgGraphic = document.querySelector(".leader-line");
        var windowSize = window.innerWidth;

        if (windowSize > 991) {
            this.productCardGlider.disable();
            this.glideSlides.style.width = "auto";
            this.glideSlides.style.overflow = "visible";
            this.glideSlides.style.transform = "translate3d(0px, 0px, 0px)";
            this.glideSlides.style.justifyContent = "center";
            if (svgGraphic) {
                svgGraphic.style.display = "block";

            }
        } else {
            if (svgGraphic) {
                svgGraphic.style.display = "none";
            }
            this.productCardGlider.enable();
            this.glideSlides.style.overflow = "hidden";
            this.glideSlides.style.justifyContent = "initial";
        }
    };


}