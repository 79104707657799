import React from "react";
import { ErrorMessage, Input, Label, LabelWrapper, RequiredIndicator, Wrapper } from "./TextInput.styles";

interface TextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label?: string;
  id: string;
  error?: string | undefined;
  hasError?: boolean;
  outlined?: boolean;
  postfix?: React.ReactNode;
  infoComponent?: React.ReactNode;
  disableNativeError?: boolean;
  style?: React.CSSProperties;
}

export default function TextInput({ value, id, label, error, hasError, outlined, postfix, disableNativeError = false, infoComponent, style, ...props }: TextInput) {
  return (
    <Wrapper style={{ ...style }}>
      {label && (
        <LabelWrapper>
          <Label htmlFor={id}>
            {label}
            {props.required && <RequiredIndicator aria-label="required">*</RequiredIndicator>}
          </Label>
          {infoComponent}
        </LabelWrapper>
      )}
      <div>
        <Input nativeErrorsEnabled={!disableNativeError} hasError={!!hasError} isOutlined={!!outlined} {...props} value={value} id={id} />
        {postfix}
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
}
