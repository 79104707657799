import useResizeObserver from "@react-hook/resize-observer";
import { RefObject, useLayoutEffect, useState } from "react";

interface Rect {
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const initialValues = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const useSize = (target: RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState<Rect>(initialValues);

  useLayoutEffect(() => {
    const { width, height, top, bottom, left, right } = target?.current?.getBoundingClientRect() ?? initialValues;
    setSize({ width, height, top, bottom, left, right });
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry: any) =>
    setSize({
      width: entry.contentRect.width,
      height: entry.contentRect.height,
      bottom: entry.contentRect.bottom,
      top: entry.contentRect.top,
      left: entry.contentRect.left,
      right: entry.contentRect.right,
    })
  );
  return size;
};
