import styled from "styled-components";

export const WrapperInner = styled.div`
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr;
  max-width: var(--content-max-width);
  padding: 3rem var(--grid-gutter) 3.5rem var(--grid-gutter);
  margin: 0 auto;
  max-width: 824px;
 /*  position: relative; */

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
