import styled from "styled-components";
import { AnimatedSlidePosition } from "./AnimatedSlideContainer";

export const Outer = styled.div<{ show: boolean }>`
  pointer-events: ${(p) => (p.show ? "auto" : "none")};
  z-index: ${(p) => (p.show ? "2000" : "0")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div<{ show: boolean }>`
  flex: 1;
  overflow: ${(p) => (p.show ? "auto" : "none")};
`;

export const Content = styled.div<{
  position: AnimatedSlidePosition;
}>`
  top: 0px;
  left: 0;
  width: 100%;
  will-change: transform;

  ${(p) =>
    p.position === AnimatedSlidePosition.LEFT &&
    `
    transition: transform 0.3s ease;
    transform: translate3d(-100%, 0, 0);
    `}

  ${(p) =>
    p.position === AnimatedSlidePosition.RIGHT_NO_ANIMATION &&
    `
    transition: transform 0s ease;
      transform: translate3d(100%, 0, 0);
      `}

  ${(p) =>
    p.position === AnimatedSlidePosition.RIGHT &&
    `
    transition: transform 0.3s ease;
    transform: translate3d(100%, 0, 0);
    `}
  
    ${(p) =>
    p.position === AnimatedSlidePosition.MIDDLE &&
    `
    transition: transform 0.3s ease;
    transform: translate3d(0, 0, 0);
    `}
`;

export const Title = styled.div<{ position: "left" | "center" | "right" }>`
  font-size: 32px;
  font-weight: 700;
  padding: 16px 0 24px 0;
  text-align: ${(p) => p.position};

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
