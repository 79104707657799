import gsap from 'gsap'
import Handlebars from 'handlebars/dist/handlebars'

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-');
}

export default class NewsletterContainer {
    constructor(element) {
        this.el = element
        this.init()
    }

    init() {
        const root = this.el;

        const filterListData = {
            "intro-text": "filter:",
            more: true,
            "show-year": true,
            links: window.filters
        };

        const newsListData = {
            grid: "col-sm-12 col-md-6 extra-column-padding",
            "has-paging": true,
            linklist: window.listdata
        };

        if (!window.listdata) {
            return;
        }

        newsListData.linklist.forEach(item => {
            item.date = formatDate(new Date(item.newsDate));
        });

        const iconPrevMobile = `<svg height="30" width="20"><polyline points="13,10 5,18 13,26" style="fill:none;stroke:currentColor;stroke-width:4" /></svg>`;
        const iconPrevDesktop = `<svg height="30" width="20"><polyline points="15,5 5,15 15,25" style="fill:none;stroke:currentColor;stroke-width:7" /></svg>`;

        const iconNextMobile = `<svg height="30" width="20"><polyline points="5,10 13,18 5,26" style="fill:none;stroke:currentColor;stroke-width:4" /></svg>`;
        const iconNextDesktop = `<svg height="30" width="20"><polyline points="5,5 15,15 5,25" style="fill:none;stroke:currentColor;stroke-width:7" /></svg>`;

        let wasMobile = window.matchMedia("(max-width: 767px)").matches;
        let isMobile = wasMobile;
        let iconPrev = isMobile ? iconPrevMobile : iconPrevDesktop;
        let iconNext = isMobile ? iconNextMobile : iconNextDesktop;

        const itemListContainer = root.querySelector(".linklist-module");
        const filterListContainer = root.querySelector(".situationlink-module");
        const numItemsPerPage = 12;

        let filterLinks = [];
        let numItems = window.listdata.length;
        let numPages = Math.ceil(numItems / numItemsPerPage);
        let filteredDataList = [];
        let paginatedDataList = [];

        let currentFilterId = "";
        let currentDateFilter = "";
        let paginationIndex = 0;

        getSearchQueryState();
        createFilterList(filterListData);
        toggleListItems();
        createPagination();

        window.onpopstate = function () {
            getSearchQueryState();
            createFilterList(filterListData);
            toggleListItems();
            createPagination();
        };

        window.addEventListener("resize", onResize);

        function onResize() {
            isMobile = window.matchMedia("(max-width: 767px)").matches;
            iconPrev = isMobile ? iconPrevMobile : iconPrevDesktop;
            iconNext = isMobile ? iconNextMobile : iconNextDesktop;

            if (isMobile !== wasMobile) {
                createPagination();
                wasMobile = isMobile;
            }
        }

        function getSearchQueryState() {
            const searchQuery = window.location.search;
            currentFilterId = searchQuery.split("filter=")[1]
                ? searchQuery.split("filter=")[1].split("&")[0]
                : "";
            currentDateFilter =
                searchQuery.split("date=")[1] &&
                    searchQuery.split("&")[1].split("=")[1]
                    ? searchQuery.split("&")[1].split("=")[1]
                    : "";
            paginationIndex = searchQuery.split("page=")[1]
                ? Number(searchQuery.split("page=")[1])
                : 0;
        }

        function createPagination() {
            const paginationList = root.querySelector(".pagination-list");
            paginationList.innerHTML = "";
            numItems = filteredDataList.length;
            numPages = Math.ceil(numItems / numItemsPerPage);
            if (numPages < 2) return;

            let hasFirstDots = false;
            let hasLastDots = false;

            let hasPrev = false;
            let hasNext = false;

            Array.from({ length: numPages }).map((item, idx) => {
                const showPrev = Boolean(paginationIndex !== 0 && !hasPrev);
                const isFirst = Boolean(idx === 0);
                const insertFirstDots = Boolean(
                    idx > 0 && idx < paginationIndex && !hasFirstDots
                );
                const isCurrentOrNextTwo = Boolean(
                    idx > paginationIndex - 1 && idx < paginationIndex + 3
                );
                const insertLastDots = Boolean(
                    idx < numPages - 1 && idx > paginationIndex + 2 && !hasLastDots
                );
                const isLast = Boolean(idx === numPages - 1);
                const showNext = Boolean(
                    paginationIndex !== numPages - 1 &&
                    idx === numPages - 1 &&
                    !hasNext
                );

                var dots = isMobile ? ".." : "...";

                if (showPrev) {
                    getPaginationNode(
                        idx,
                        paginationList,
                        paginationIndex - 1,
                        "Forrige",
                        iconPrev,
                        "arrow"
                    );
                    hasPrev = true;
                }
                if (isFirst) {
                    getPaginationNode(
                        idx,
                        paginationList,
                        idx,
                        idx + 1,
                        idx + 1,
                        "num"
                    );
                }
                if (insertFirstDots) {
                    if (numPages === 3) {
                        getPaginationNode(
                            idx,
                            paginationList,
                            idx,
                            idx + 1,
                            idx + 1,
                            "num"
                        );
                    } else {
                        getPaginationNode(
                            idx,
                            paginationList,
                            null,
                            null,
                            dots,
                            "dot"
                        );
                        hasFirstDots = true;
                    }
                }
                if (insertLastDots) {
                    getPaginationNode(idx, paginationList, null, null, dots, "dot");
                    hasLastDots = true;
                }
                if (isCurrentOrNextTwo && !isFirst && !isLast) {
                    getPaginationNode(
                        idx,
                        paginationList,
                        idx,
                        idx + 1,
                        idx + 1,
                        "num"
                    );
                }
                if (isLast) {
                    getPaginationNode(
                        idx,
                        paginationList,
                        idx,
                        idx + 1,
                        idx + 1,
                        "num"
                    );
                }
                if (showNext) {
                    getPaginationNode(
                        idx,
                        paginationList,
                        paginationIndex + 1,
                        "Næste",
                        iconNext,
                        "arrow"
                    );
                    hasNext = true;
                }
            });
        }

        function getPaginationNode(
            idx,
            paginationList,
            dataIndex,
            ariaLabel,
            text,
            type
        ) {
            const nodeLI = document.createElement("li");
            const nodeA = document.createElement("a");
            nodeA.innerHTML = text.toString();
            if (dataIndex !== null) {
                nodeA.setAttribute(
                    "href",
                    `?filter=${currentFilterId}&date=${currentDateFilter}&page=${dataIndex}`
                );
                nodeA.setAttribute("data-index", dataIndex);
            }
            if (dataIndex === null) nodeA.setAttribute("disabled", "true");
            if (ariaLabel) nodeA.setAttribute("aria-label", ariaLabel);

            if (type == "arrow") {
                nodeLI.classList.add("arrow");
            }
            if (type == "dot") {
                nodeLI.classList.add("dot");
            }

            if (idx === paginationIndex) {
                nodeLI.classList.add("active");
                nodeA.setAttribute("disabled", "true");
            }
            nodeLI.appendChild(nodeA);
            paginationList.appendChild(nodeLI);
            nodeLI.addEventListener("click", onPaginationClick);
            return nodeLI;
        }

        function onPaginationClick(event) {
            event.preventDefault();
            const index = event.target.getAttribute("data-index");
            if (index && index !== paginationIndex) {
                setPaginationIndex(Number(index));
            }
        }

        function setPaginationIndex(index) {
            paginationIndex = index;
            toggleListItems();

            updateUrl();

            createPagination("onPaginationClick > setPaginationIndex");

            const posY =
                window.scrollY +
                root.querySelector(".linklist-module").getBoundingClientRect()
                    .top -
                100;
            window.scrollTo(0, posY);
        }

        function createFilterList(data) {
            // const templateSource = document.getElementById("template-filterlist")
            // if(!templateSource) return
            // const source = templateSource.innerHTML
            // const template = Handlebars.compile(source)
            // const html = template(data)
            // filterListContainer.innerHTML = html.trim()

            filterLinks = [
                ...root.querySelectorAll(
                    ".situation-link:not(.situation-link--all)"
                )
            ];
            filterLinks.forEach(link => {
                const filterId = link.getAttribute("data-filter-id");
                if (filterId === currentFilterId) {
                    link.classList.add("active");
                }
                link.addEventListener("click", onFilterClick);
            });

            setupMoreButton();
            setupDropdown();
        }

        function resetActiveFilters() {
            filterLinks.forEach(link => {
                link.classList.remove("active");
            });
        }

        function onFilterClick(event) {
            event.preventDefault();

            paginationIndex = 0;

            resetActiveFilters();

            event.target.classList.add("active");

            currentFilterId = event.target.dataset.filterId;

            updateUrl();
            toggleListItems();
            createPagination();
        }

        function updateUrl() {
            const newUrl = `?filter=${currentFilterId}&date=${currentDateFilter}&page=${paginationIndex}`;
            window.history.pushState(null, null, newUrl);
        }

        function getListItems(data) {
            const templateSource = document.getElementById("template-newslist");
            if (!templateSource) return;
            const source = templateSource.innerHTML;
            const template = Handlebars.compile(source);
            const html = template(data);
            return html.trim();
        }

        function toggleListItems() {
            if (!itemListContainer) return;

            const paginationOffset = paginationIndex * numItemsPerPage;
            const paginationEnd = paginationOffset + numItemsPerPage - 1;

            filteredDataList = window.listdata.filter(item => {
                if (currentFilterId === "" && currentDateFilter === "") return true;

                if (currentFilterId === "" && currentDateFilter != "") {
                    const created = item.newsDate;
                    const doesNotMatchDate =
                        currentDateFilter.length &&
                        new Date(created).getFullYear() !==
                        new Date(currentDateFilter).getFullYear();
                    if (doesNotMatchDate) return null;
                    return true;
                }

                const filterIds = item.filters.map(filter => filter.Key);
                const matchesFilters = Boolean(
                    filterIds.filter(id => id === currentFilterId).length > 0
                );

                const created = item.newsDate;

                const doesNotMatchDate =
                    currentDateFilter.length &&
                    new Date(created).getFullYear() !==
                    new Date(currentDateFilter).getFullYear();

                if (!matchesFilters || doesNotMatchDate) return null;

                return true;
            });

            paginatedDataList = filteredDataList.filter((item, idx) => {
                if (idx < paginationOffset || idx > paginationEnd) return null;
                return true;
            });

            const listData = Object.assign(newsListData);
            listData.linklist = paginatedDataList;

            itemListContainer.innerHTML = getListItems(listData);

            animateItemList();
        }

        function animateItemList() {
            [...root.querySelectorAll(".fade-in")].forEach((node, index) => {
                gsap.from(node, {
                    duration: 0.5,
                    delay: index / 20,
                    autoAlpha: 0,
                    y: "10px"
                });
            });
        }

        function onDropdownChange(selectorValue) {
            paginationIndex = 0;
            currentDateFilter = selectorValue;

            const newUrl = `?filter=${currentFilterId}&date=${currentDateFilter}`;
            window.history.pushState(null, null, newUrl);

            //updateUrl()
            toggleListItems();
            createPagination();
        }

        function setupDropdown() {
            const selectorControl = filterListContainer.querySelector(".selector-control");
            selectorControl.onclick = () =>
                selectorControl.classList.toggle("open");

            if (currentDateFilter) {
                const selectorControlValue = filterListContainer.querySelector(
                    ".selector-control-value"
                );
                const activeSelector = root.querySelector(".selected-li");
                selectorControlValue.innerHTML = currentDateFilter;
                if (activeSelector) {
                    activeSelector.classList.remove("selected-li");
                }
                const selectedLi = root.querySelector(
                    "[data-value='" + currentDateFilter + "']"
                );
                selectedLi.className += "selected-li";
            }

            const selectorDropdown = selectorControl.querySelector(".selector-control-dropdown");

            selectorDropdown.addEventListener("click", function (event) {
                const selector = event.target;
                const activeSelector = root.querySelector(".selected-li");

                const selectorValueControl = event.target.parentElement.parentElement.querySelector(
                    ".selector-control-value"
                );
                const selectorValue = event.target.getAttribute("data-value");

                selectorValueControl.setAttribute(
                    "data-selected-value",
                    selectorValue
                );
                selectorValueControl.innerHTML = event.target.textContent;

                if (activeSelector) {
                    activeSelector.classList.remove("selected-li");
                }
                selector.className += "selected-li";

                onDropdownChange(selectorValue);
            });

            const selectControl = selectorControl.querySelectorAll("select");
        }

        function setupMoreButton() {
            const hasButtonMore = document.getElementsByClassName(
                "situation-list-more"
            ).length;

            if (hasButtonMore) {
                const showHideButton = root.querySelector(
                    "[data-showsituation]"
                );
                const showHideLink = root.querySelector(
                    "[data-hiddensituation]"
                );
                const showHideContainer = document.getElementsByClassName(
                    "js-situation-list__container"
                );
                const moreText = showHideButton.getAttribute("data-moretext");
                const lessText = showHideButton.getAttribute("data-lesstext");
                let showmore = false;

                const openSituation = function () {
                    const containerHeight = showHideContainer[0].clientHeight;

                    showHideLink.style.height = containerHeight + "px";
                    showHideButton.innerHTML = lessText;
                    showHideButton.dataset.showsituation = "true";
                };

                const closeSituation = function () {
                    showHideLink.style.height = "0px";
                    showHideButton.innerHTML = moreText;
                    showHideButton.dataset.showsituation = "false";
                };

                showHideButton.onclick = function (event) {
                    event.preventDefault();

                    if (!showmore) {
                        openSituation();
                    } else {
                        closeSituation();
                    }

                    showHideLink.dataset.hiddensituation = String(showmore);
                    showmore = !showmore;
                };

                // On resize
                let timeOut;

                window.onresize = function () {
                    clearTimeout(timeOut);

                    timeOut = setTimeout(() => {
                        if (showmore) {
                            closeSituation();
                            showHideLink.dataset.hiddensituation = String(showmore);
                            showmore = !showmore;
                        }
                    }, 50);
                };
            }
        }
    }
}