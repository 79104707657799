import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import SimpleProductCard from "../../../../../../components/SimpleProductCard";
import { useStore } from "../../../../../../store/store";
import { Wrapper } from "./DefaultScreen.styles";
import { useMemo } from "react";
import { SalaryInsuranceFlowRouteEnum } from "../../../../../../types/SalaryInsuranceFlowRouteEnum";

interface DefaultScreenProps {
  onHeightChange: (height: number) => void;
}

const salaryInsuranceFeatures = [
  {
    title: "Pris fra 51 kr./md.",
  },
  {
    title: "Få op til 50.000 kr./md. oven i dagpengene",
  },
  {
    title: "Månedlig udbetaling",
  },
  {
    title: "Tryg Lægehotline",
  },
  {
    title: "Adgang til psykolog",
  },
];

export default function DefaultScreen({ onHeightChange }: DefaultScreenProps) {
  const route = useStore.useSalaryInsuranceFlowRoute();
  const setSalaryInsuranceFlowVisible = useStore.useSetSalaryInsuranceFlowVisible();
  const setSalaryInsuranceFlowRoute = useStore.useSetSalaryInsuranceFlowRoute();

  const animationPosition = useMemo(() => {
    switch (route) {
      case SalaryInsuranceFlowRouteEnum.DEFAULT:
        return AnimatedSlidePosition.MIDDLE;
      default:
        return AnimatedSlidePosition.LEFT;
    }
  }, [route]);

  return (
    <AnimatedSlideContainer title="" titlePosition="center" onHeightChange={onHeightChange} position={animationPosition} onClose={() => setSalaryInsuranceFlowVisible(false)}>
      <Wrapper>
        <SimpleProductCard
          title="Ring mig op om lønsikring"
          subtitle="Vi ringer til dig hurtigst muligt for at gennemgå dine muligheder."
          features={salaryInsuranceFeatures}
          onClick={() => {
            setSalaryInsuranceFlowRoute(SalaryInsuranceFlowRouteEnum.CONFIRM);
          }}
          maxWidth={450}
        />
      </Wrapper>
    </AnimatedSlideContainer>
  );
}
