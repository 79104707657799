import { TweenMax } from 'gsap'


export default class BenefitModule {
    constructor(element) {
        this.el = element
        this.init()
    }

    init() {
        const benefitModule = this.el;

        let isBenefitModuleExpanded = benefitModule.classList.contains("expanded");

        const benefitViewMoreBtn = document.querySelector(".benefit-module .benefit-viewmore-button");
        const benefitContentContainer = document.querySelector(".benefit-content-container");

        if (benefitViewMoreBtn) {
            benefitViewMoreBtn.onclick = function (event) {
                event.preventDefault();

                benefitModule.classList.toggle("expanded");
                // Set global expand variable
                isBenefitModuleExpanded = benefitModule.classList.contains("expanded");

                // Adjust text and calculate height
                if (isBenefitModuleExpanded) {
                    var btnText = benefitViewMoreBtn.getAttribute("data-lesstext");
                    benefitViewMoreBtn.innerHTML = btnText;
                    setExpandedHeight();
                }
                else {
                    var btnText = benefitViewMoreBtn.getAttribute("data-moretext");
                    benefitViewMoreBtn.innerHTML = btnText;
                    setCollapsedHeight();
                }


            };



        }

        // Calculate collapsed height
        var setCollapsedHeight = function () {

            const columns = document.querySelectorAll(".benefit-module .column");
            var collapsedHeight = 0;

            // Calculate height for 2 modules
            columns.forEach((elm, index) => {

                if (index < 3) {

                    // Mobile calculation
                    var windowWidth = window.matchMedia("(max-width: 767px)");

                    if (windowWidth.matches) {
                        collapsedHeight += columns[index].clientHeight;
                    }
                    else {
                        // Ipad and Desktop sizes
                        if (collapsedHeight < columns[index].clientHeight) {
                            collapsedHeight = columns[index].clientHeight;

                        }
                    }

                }

            });

            benefitContentContainer.style.height = collapsedHeight + "px";
        }


        var isTweenRunning = false;

        var setExpandedHeight = function () {
            const heightWrapper = document.querySelector(".benefit-height-wrapper");
            var expandedHeight = 0;
            expandedHeight = heightWrapper.clientHeight;
            benefitContentContainer.style.height = expandedHeight + "px";


            // Animate them down
            if (isTweenRunning == false) {
                isTweenRunning = true;

                const benefits = document.querySelectorAll(".benefit-height-wrapper .benefit-item");

                if (benefits.length > 3) {
                    var benefitsRemaining = [];

                    benefits.forEach((elm, index) => {
                        if (index > 3) {
                            benefitsRemaining.push(elm);
                        }
                    });

                    TweenMax.staggerFrom(benefitsRemaining, 0.8, { top: -30, delay: 0, opacity: 0, ease: "power2".easeOut, force3D: true }, 0.05,
                        function () {
                            isTweenRunning = false;
                        });
                }

            }

        }

        setCollapsedHeight();

        // On resize
        var timer;

        window.onresize = function () {

            clearTimeout(timer);

            timer = setTimeout(
                function () {
                    // calculateHeight();

                    if (isBenefitModuleExpanded) {
                        setExpandedHeight();
                    }
                    else {
                        setCollapsedHeight();
                    }

                }, 50);

        };

    }
}

