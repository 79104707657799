import StepContainer from "../../../../components/StepContainer";
import { useStore } from "../../../../store/store";
import CheckCard from "../../../../components/CheckCard";
import StepRoutes from "../../../../constants/Routes";
import { AseFieldset, AseList } from "../../../../utils/global.styles";

export default function FitWithYouStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();

  return (
    <StepContainer
      stepTitle={t?.fitWithYouStep.title}
      subtitle="Velkommen til familiefagforeningen!"
      progress={0.5}
      buttonText={t?.fitWithYouStep.buttonLabel}
      helpText={t?.fitWithYouStep.helpText}
      onNext={() => {
        if (!payload.haveYouEveryHadDanishUnemploymentInsurance && payload.areYouPrimarilyWorkingInDenmark && !payload.haveYouRecentlyWorkedAbroad) {
          setRouteStack(StepRoutes.ConsentStep);
        } else {
          const cause = [];
          if (payload.haveYouEveryHadDanishUnemploymentInsurance) {
            cause.push("Har været medlem af a-kasse");
          }

          if (!payload.areYouPrimarilyWorkingInDenmark) {
            cause.push("Arbejder ikke primært i danmark");
          }

          if (payload.haveYouRecentlyWorkedAbroad) {
            cause.push("Har arbejdet i udlandet fornyligt");
          }

          setPayload({
            cause: cause.join(", "),
          });
          setRouteStack(StepRoutes.NeedToContactYouStep);
        }
      }}
    >
      <AseFieldset>
        <AseList>
          <CheckCard
            id="question_prior_akasse"
            onChange={() =>
              setPayload({
                haveYouEveryHadDanishUnemploymentInsurance: !payload.haveYouEveryHadDanishUnemploymentInsurance,
              })
            }
            isChecked={payload.haveYouEveryHadDanishUnemploymentInsurance === undefined ? undefined : !payload.haveYouEveryHadDanishUnemploymentInsurance}
            title={t?.fitWithYouStep.noPriorUnemploymentInsurance}
          />
          <CheckCard
            id="question_working_in_dk"
            onChange={() =>
              setPayload({
                areYouPrimarilyWorkingInDenmark: !payload.areYouPrimarilyWorkingInDenmark,
              })
            }
            isChecked={payload.areYouPrimarilyWorkingInDenmark}
            title={t?.fitWithYouStep.worksInDenmark}
          />
          <CheckCard
            id="question_working_abroad"
            onChange={() =>
              setPayload({
                haveYouRecentlyWorkedAbroad: !payload.haveYouRecentlyWorkedAbroad,
              })
            }
            isChecked={!payload.haveYouRecentlyWorkedAbroad}
            title={t?.fitWithYouStep.neverWorkedAbroad}
          />
        </AseList>
      </AseFieldset>
    </StepContainer>
  );
}
