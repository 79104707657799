import React, { useMemo, useState } from "react";
import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import { useStore } from "../../../../store/store";
import TextInput from "../../../../components/TextInput/TextInput";
import StepRoutes from "../../../../constants/Routes";
import { AseButtonActions, AseFieldset } from "../../../../utils/global.styles";
import GiftCardPopup from "../../../../components/GiftCardPopup";

export default function SelfEmployedStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const [error, setError] = useState(true);

  const disabled = useMemo(() => {
    if (payload.doYouRunYourOwnBusiness) {
      return error;
    } else {
      return payload.doYouRunYourOwnBusiness === undefined;
    }
  }, [payload, error]);

  return (
    <StepContainer
      stepTitle={t?.selfEmployedStep.title}
      progress={0.5}
      buttonText={t?.selfEmployedStep.buttonLabel}
      helpText={t?.selfEmployedStep.helpText}
      onNext={() => {
        if (payload.doYouRunYourOwnBusiness) {
          setRouteStack(StepRoutes.MembershipStep);
        } else {
          setPayload({
            cause: "Selvstændig men ikke på fuldtid",
          });
          setRouteStack(StepRoutes.NeedToContactYouStep);
        }
      }}
      nextDisabled={disabled}
    >
      <AseFieldset>
        <AseButtonActions>
          <RadioButton onChange={() => setPayload({ doYouRunYourOwnBusiness: true })} id="selfemployed_yes" checked={payload.doYouRunYourOwnBusiness === undefined ? false : payload.doYouRunYourOwnBusiness}>
            {t?.selfEmployedStep.answerYes}
          </RadioButton>
          <RadioButton
            onChange={() =>
              setPayload({
                doYouRunYourOwnBusiness: false,
              })
            }
            id="selfemployed_no"
            checked={payload.doYouRunYourOwnBusiness === undefined ? false : !payload.doYouRunYourOwnBusiness}
          >
            {t?.selfEmployedStep.answerNo}
          </RadioButton>
        </AseButtonActions>

        {payload.doYouRunYourOwnBusiness && (
          <TextInput
            label={t?.selfEmployedStep.cvr}
            value={payload.cvrNo}
            onChange={(e) => {
              setError(e.currentTarget.validity.patternMismatch || !e.currentTarget.value);
              setPayload({ cvrNo: e.currentTarget.value });
            }}
            placeholder={t?.selfEmployedStep.cvr}
            pattern="[0-9]{8}"
            id="cvrNumber"
          />
        )}
      </AseFieldset>
    </StepContainer>
  );
}
