import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  padding: 30px 0;
`;

export const ToggleButton = styled.button<{ selected: boolean }>`
  border-radius: 5px;
  border: 1px solid var(--color-orange);
  flex: 1;
  height: 50px;
  width: 100%;
  background-color: white;
  font-size: 16px;
  color: var(--color-orange);
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
  background-color: var(--color-orange);
  color: white;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  padding: 30px 0;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const RowQuestion = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
