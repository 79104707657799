import { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: React.ReactNode;
  className: string;
}

const Portal = ({ children, className }: Props) => {
  const el = useMemo(() => document && document.createElement("div"), []);

  useEffect(() => {
    const target = document.body;
    const classList = ["portal-container"];
    if (className) className.split(" ").forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el, parent, className]);

  return createPortal(children, el);
};

export default Portal;
