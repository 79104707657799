import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export default class AccordionModule {
    constructor(element) {
        this.el = element
        this.accordionModule = this.el;
        this.accordionHeader = this.accordionModule.querySelectorAll(".accordion-header")
        this.toggleAccordion = this.toggleAccordion.bind(this)
        document.addEventListener('readystatechange', e => {
            if (document.readyState == 'complete') {
                this.init()
            }
        });
    }

    init() {
        gsap.registerPlugin(ScrollToPlugin);

        if (this.accordionModule) {
            if (this.accordionHeader) {
                this.accordionHeader.forEach(header => {
                    header.addEventListener('click', e => {
                        this.toggleAccordion(header)
                    })
                    header.addEventListener('keypress', e => {
                        let key = e.which || e.keyCode || 0;
                        if (key === 13) {
                            this.toggleAccordion(header)
                        }
                    })
                })
            }
        }
    }
    toggleAccordion(header) {
        const headerHeight = 75;
        const selectorValueControl = header;
        const selectorContainer = selectorValueControl.nextSibling.nextSibling;
        const selectorChontainerHeight = selectorContainer.querySelector('.row').clientHeight + "px";

        if (selectorValueControl.classList.contains('accordion-open')) {
            selectorValueControl.classList.remove("accordion-open");
            selectorContainer.style.height = "0px";
        } else {
            let myVar = setInterval(myTimer, 500);
            function myTimer() {
                var divOffset = myOffset(selectorValueControl);
                gsap.to(window, { duration: 0.5, scrollTo: divOffset.top - headerHeight });
                clearTimeout(myVar);
            }
            function myOffset(el) {
                var rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }
            selectorValueControl.classList.add("accordion-open");
            selectorContainer.style.height = selectorChontainerHeight;
        }
    }

}