import styled from "styled-components";
import { TrackTracerItemStateEnum } from "../../types/TrackTracerItemStateEnum";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 3px;
  background-repeat: repeat-y;
  padding-left: 22px;
`;

export const Inner = styled.div<{ canHover: boolean }>`
  padding: 24px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;

  ${({ canHover }) =>
    canHover &&
    `&:hover {
    background-color: #d76f2c36;`}
`;

export const StateWrapper = styled.div<{
  $state: TrackTracerItemStateEnum;
  $hasDate: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ $hasDate }) => ($hasDate ? "53px" : "24px")};
  left: -15px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #4f4a45;

  ${({ $state }) => $state === TrackTracerItemStateEnum.Success && `border-color: #57bc98;`}
  ${({ $state }) => $state === TrackTracerItemStateEnum.Error && `border-color: var(--color-error);`}
  ${({ $state }) => $state === TrackTracerItemStateEnum.Pending && `border-color: var(--color-petrol);`}

  ${({ $state }) =>
    $state === TrackTracerItemStateEnum.Create &&
    `border-color: #d76f2c;
    box-shadow: rgba(204, 169, 44, 0.4) 0px 0px 0px;
    animation: 1.5s ease 0s infinite normal none running ASEBorderPulse;
    `}
`;

export const Title = styled.h3`
  padding-bottom: 0.6rem;
  text-transform: initial;
`;

export const Description = styled.p`
  font-size: 0.9rem;
  text-transform: initial;

  a {
    color: #4f4a45;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 0.6rem;
  display: flex;

  button,
  a {
    text-transform: initial;
  }
`;

export const Badge = styled.div<{ $state: TrackTracerItemStateEnum }>`
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 3px 16px;
  margin-bottom: 0.6rem;
  text-transform: initial;

  ${({ $state }) =>
    $state === TrackTracerItemStateEnum.Success &&
    `border: 2px solid #57bc98;
    background-color: #57bc98;
    color: white;
    `}

  ${({ $state }) =>
    $state === TrackTracerItemStateEnum.Pending &&
    `border: 2px solid var(--color-petrol);
    background-color: var(--color-petrol);
    color: var(--color-white);
    font-weight: 700;
    `}

    ${({ $state }) =>
    $state === TrackTracerItemStateEnum.Create &&
    `border: 2px solid #d76f2c;
    color: #d76f2c;
    `}

     ${({ $state }) =>
    $state === TrackTracerItemStateEnum.Error &&
    `border: 2px solid var(--color-error);
    background-color: var(--color-error);
    color: var(--color-white);
    `}
`;

export const AnimationWrapper = styled.div`
  width: 140px;
`;

export const Date = styled.p`
  font-size: 0.6rem;
  color: var(--color-coal-75);
`;
