import { UnionOptionModel } from "../../types/UnionOptionModel";
import { Label, Select as SelectComp, Option } from "./Select.styles";

interface SelectProps {
  id: string;
  label?: string;
  defaultOption?: string;
  selectedOption?: UnionOptionModel;
  options: UnionOptionModel[];
  onSelect: (id: string) => void;
}

export default function Select({ id, label, defaultOption, options, selectedOption, onSelect }: SelectProps) {
  return (
    <div>
      {label && <Label htmlFor={id}>{label}</Label>}
      <SelectComp
        id={id}
        required
        onChange={(e) => {
          onSelect(e.currentTarget.value);
        }}
      >
        {defaultOption && (
          <Option value="" selected>
            {defaultOption}
          </Option>
        )}
        {options.map((option, index) => (
          <Option key={index} value={option.id} selected={selectedOption?.id === option.id}>
            {option.name}
          </Option>
        ))}
      </SelectComp>
    </div>
  );
}
