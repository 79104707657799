import styled from "styled-components";

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  max-width: var(--content-max-width);
  padding: 5rem var(--grid-gutter);
  margin: 0 auto;
  max-width: 824px;

  h1 {
    text-transform: initial;
  }

  h2 {
    margin: 0;
    text-transform: initial;
  }
`;

export const Form = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
`;

export const FormTitle = styled.h2`
  text-transform: initial;
  margin: 0;

  .validation {
    display: none;
    margin-left: 5px;
    vertical-align: middle;

    @media (max-width: 600px) {
      display: inline-block;
    }
  }
`;

export const FormValidationIndicator = styled.span`
  display: none;
  margin-left: 5px;
  vertical-align: middle;

  @media (max-width: 600px) {
    display: inline-block;
  }
`;

export const FormItem = styled.div`
  padding-bottom: 50px;
`;

export const FormInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  &:not(:last-child) {
    padding-bottom: 20px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3em;
`;

export const DeleteButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;

  &:hover {
    background-color: var(--color-petrol-25);
  }
`;

export const FormError = styled.div`
  color: var(--color-error);
  text-transform: initial;
  padding-top: 12px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
`;

export const ActionsButton = styled.button`
  width: 200px;
  padding: 10px;
`;

export const SubmitButton = styled.button`
  height: 51px;
`;
