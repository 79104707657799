import styled from "styled-components";

export const StepQuestion = styled.h3`
  text-transform: initial;
  margin-bottom: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;
