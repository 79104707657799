import styled from "styled-components";

const barHeight = 200;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px 45px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BarWrapper = styled.div`
  width: 150px;
  height: ${barHeight}px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 16px 0px;
`;

export const BarBaseCoverage = styled.div<{ procentage: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(p) => (barHeight / 100) * p.procentage}px;
  background-color: #b9e0e5;
  transition: height 0.5s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 10px;
    height: 0px;
    border-bottom: 3px solid #b9e0e5;
  }
`;

export const BarSalaryInsurance = styled.div<{ procentage: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(p) => (barHeight / 100) * p.procentage}px;
  background-color: #008e9f;
  transition: height 0.5s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 10px;
    height: 0px;
    border-bottom: 3px solid #008e9f;
  }
`;

export const BarSalary = styled.div<{ procentage: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(p) => (barHeight / 100) * p.procentage}px;
  background-color: var(--color-gray);
  transition: height 0.5s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 10px;
    height: 0px;
    border-bottom: 3px solid var(--color-gray);
  }
`;

export const ProcentageTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const BarTitle = styled.div`
  position: absolute;
  top: -8px;
  left: calc(100% + 14px);
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  text-transform: initial;
`;
