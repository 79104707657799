import styled from "styled-components";

export const Content = styled.div<{ isAnimating: boolean }>`
  ${({ isAnimating }) =>
    isAnimating &&
    `
    animation-duration: .75s;
    animation-fill-mode: forwards;
    animation-name: ASEenterFieldset;
    animation-timing-function: var(--cubic-ease-out);
`}
`;

export const StepTitle = styled.h4`
  text-transform: initial;
  font-size: var(--font-size-header-medium);
`;

export const OfferBanner = styled.div`
  border-radius: 3px;
  background-color: var(--color-orange);
  margin-bottom: 20px;
  padding: 10px 20px;
  color: white;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ASEBorderPulse 2s infinite;
  font-size: 18px;
`;

export const Heighlight = styled.p`
  font-weight: 600;
  display: inline;
  margin-bottom: 0px;
  text-transform: uppercase;
`;


export const Header = styled.div``;

export const HeaderTitle = styled.h3`
  text-transform: initial;
  font-size: var(--font-size-header-large);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    order: 8;
    justify-content: space-between;
    padding-top: 30px;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const NextButton = styled.button<{ isGreen: boolean }>`
  width: 270px;
  height: 52px;

  ${({ isGreen }) =>
    isGreen &&
    ` 
    background-color: #05bd2b;

    &:before {
        background-color: #05ce2e;
    }
  `}

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const BackButton = styled.button`
  height: 50px;
  width: 50px;
  min-width: 50px;
  padding: 0;
  margin-right: 20px;

  &:hover {
    background-color: transparent;
    border-color: currentColor;
    color: inherit;
  }

  $:before {
    display: none;
  }
`;

export const Help = styled.div<{ isVisible: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 20px 0;

  ${({ isVisible }) =>
    !isVisible &&
    `
    display: none;

    @media (max-width: 600px) {
        display: block;
        visibility: hidden;
        pointer-events: none;
    }
  `}
`;

export const HelpLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

export const HelpButton = styled.span`
  height: 26px;
  min-width: 26px;
  padding: 0;
  width: 26px;
  margin-left: 10px;
  background-color: var(--color-coal);

  &:before {
    display: none;
  }
`;

export const CampaignImg = styled.img`
    height: auto;
    max-width: 100%;
    margin-top: -30px;
    margin-bottom: -10px;
`;
