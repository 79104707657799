import { animated } from "@react-spring/web";
import styled from "styled-components";

export const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h3`
  text-transform: initial;
  margin-bottom: 8px;
  font-size: 20px;
  text-align: center;
  color: var(--color-orange);
`;

export const SubTitle = styled.p`
  text-transform: initial;
  margin-bottom: 8px;
  font-size: 14px;
  text-align: center;
  color: var(--color-orange);
`;
