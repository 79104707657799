import {EventBus, EventTypes} from '../consts/const-event-types'
import FormAdtractionHandler from './form-adtraction/form-adtraction'

/*
Use App.js for global functionality
*/

export default class App {
  constructor(element) {
    this.el = element
    this.init = this.init.bind(this)
    EventBus.addEventListener(EventTypes.ALL_COMPONENTS_READY, this.init);
  }

  init() {
    FormAdtractionHandler.init();
  }
}
