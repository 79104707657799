import styled from "styled-components";

export const Wrapper = styled.div<{ offset: number }>`
/*   position: absolute;
  top: -70px; */
  /* transform: translate3d(0, ${({ offset }) => offset}px, 0); */
  left: 0;
  width: 100%;
  /* z-index: 100; */
  transition: transform 0.4s;
  pointer-events: none;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0px auto;
  max-width: 824px;
  padding: 30px var(--grid-gutter) 0 var(--grid-gutter);
`;

export const PopupWrapper = styled.div<{ show: boolean; size: { width: string; height: string } }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${({ show }) => (show ? 16 : 12)}px;
  cursor: ${({ show }) => (show ? "initial" : "pointer")};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 8px 4px;
  background-color: var(--color-white);
  border: 1px solid var(--color-coal-25);
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
  transition: ${({ show }) => (show ? "all 0.3s ease-in-out" : "all 0.3s ease-in-out 0.3s")};
  overflow: hidden;
  pointer-events: auto;
`;

export const PopupContent = styled.div<{ show: boolean }>`
  position: relative;
  padding-left: 16px;
  padding-right: 32px;
  transition: ${({ show }) => (show ? "opacity 0.3s ease-in-out 0.3s" : "opacity 0.3s ease-in-out")};
`;

export const PopupCollapsButton = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid var(--color-coal-25);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: var(--color-coal-5);
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-coal-15);
  }
`;

export const PopupTitle = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 4px;

  @media (max-width: 700px) {
    font-size: 13px;
  }
`;

export const PopupSubTitle = styled.p`
  display: inline-flex;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

export const PopupAvailable = styled.p`
  margin: 0;
  font-size: 11px;
`;

export const PulseCircle = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-green);
  margin-right: 4px;
  animation: ASEOnlinePulse 2s infinite;
`;
