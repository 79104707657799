export default class SalaryCalculatorModule {
  constructor(element) {
    this.el = element
    this.init()
  }

  init() {
    const version = 4
    const calculatorSection = this.el;
    if (calculatorSection) {
        const calculator = calculatorSection.querySelector(".calculator");
        const calculatorID = calculator.dataset.calculatorid;
        const dagpenge = 19322;
        const showData = calculator.querySelector(".show-data");
        const showContact = calculator.querySelector(".contact-input");
        const showSpinner = calculator.querySelector(".loader");
        const showSpinnerContact = calculator.querySelector(".loader.contact");
        const showSpinnerReceipt = calculator.querySelector(".loader.receipt");
        const slider = calculator.querySelector("#myRange");
        const slidenumber = calculator.querySelector(".slidenumber");
        const security = calculator.querySelector(".security");
        const infoboxes = [].slice.call(calculator.querySelectorAll(".info"));
        const saleryInput = calculator.querySelector("#salery");
        const inputbox = calculator.querySelector(".number-input");
        const maxValue = calculator.querySelector(".max-value");
        const threeMonth = calculator.querySelector("#three");
        const sixMonth = calculator.querySelector("#six");
        const twelveMonth = calculator.querySelector("#twelve");
        const amount = calculator.querySelector("p.result");
        const radiobuttons = calculator.querySelectorAll("input[type='radio']");
        const myRequest = new Request("/assets/images/L_nsikring jason datagrundlag 21-4-2020_norm.json");
        const stepButton = calculator.querySelectorAll("button");
        const stepOne = calculator.querySelector(".step-one");
        const stepTwo = calculator.querySelector(".step-two");
        const stepThree = calculator.querySelector(".step-three");
        const inputName = calculator.querySelector("#name");
        const inputPhone = calculator.querySelector("#phone");
        const callButton = calculator.querySelector(".contact-input button");
        const showReceipt = calculator.querySelector(".show-receipt");
        const showContactContainer = calculator.querySelector(".show-contact");
        const receipt = calculator.querySelector(".receipt");
        const minError = calculator.querySelector(".error-minimum");
        const maxError = calculator.querySelector(".error-max");
        const saleryLabel = calculator.querySelector("[for='salery']");

        let isActive = false;

        //saleryInput.focus();

        inputbox.addEventListener("click", e => saleryInput.focus());

        saleryLabel.addEventListener("click", e => {
            e.preventDefault();
        });

        radiobuttons.forEach(radio =>
            radio.addEventListener("change", function() {
                radio.checked
                    ? (amount.innerHTML = new Intl.NumberFormat("da-dk").format(
                          radio.value
                      ))
                    : null;
            })
        );

        saleryInput.addEventListener("focus", e => {
            clearInput();
        });

        infoboxes.forEach(button => {
            button.addEventListener("click", e => {
                openInfo(e, button.dataset.info, button);
            });
        });

        slider.addEventListener("change", function() {
            slidenumber.innerHTML = new Intl.NumberFormat("da-dk").format(
                this.value
                );

            var securityPerCent = this.value - dagpenge;
            console.log("slideNumber:", this.value);
            console.log("securityPerCent:", securityPerCent);
            security.innerHTML = new Intl.NumberFormat("da-dk").format(
                Math.round(securityPerCent)
            );
        });

        slider.addEventListener("mouseup", e => {
            sixMonth.checked = true;
            getPrice(slider.value);
        });

        slider.addEventListener("touchend", e => {
            sixMonth.checked = true;
            getPrice(slider.value);
        });

        stepButton.forEach(button => {
            button.addEventListener("click", e => {
                e.preventDefault();
                var checkedRadio = calculator.querySelector(
                    "input[type='radio']:checked"
                );

                if (button.dataset.step === "two") {
                    openContactStep();
                }
                if (button.dataset.step === "three") {
                    openReceipt();
                }
            });
        });

        inputName.addEventListener("input", e => {
            if (inputPhone.value.length > 0) {
                callButton.disabled = false;
            }
        });

        inputPhone.addEventListener("input", e => {
            if (inputName.value.length > 0) {
                callButton.disabled = false;
            }
        });

        inputPhone.oninput = handleTelephone;

        saleryInput.oninput = handleInput;

        function clearInput() {
            saleryInput.value = null;
            if (isActive) {
                isActive = false;
                showData.classList.remove("show");
            }
        }

        function postData() {
            const checkedRadio = calculator.querySelector(
                "input[type='radio']:checked"
            );
            let duration = null;
            switch (checkedRadio.id) {
                case "three":
                    duration = 3;
                    break;
                case "six":
                    duration = 6;
                    break;
                case "twelve":
                    duration = 12;
                    break;
                default:
                    duration = 6;
            }

            var salerysecurity = security.innerHTML;

            var test = {
                name: inputName.value,
                phone: inputPhone.value,
                contentid: calculatorID,
                grosspay: saleryInput.value,
                //payout: slider.value,
                payout: salerysecurity,
                payoutduration: duration
            };

            fetch("/umbraco/api/SalaryCalculatorApi/PostSalCalCampaign", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },

                body: JSON.stringify({
                    name: inputName.value,
                    phone: inputPhone.value,
                    contentid: calculatorID,
                    grosspay: saleryInput.value,
                    //payout: slider.value,
                    payout: salerysecurity,
                    payoutduration: duration
                })
            }).then(response => {
                showSpinnerReceipt.classList.add("show");
                window.scrollTo({ top: 0 });
                scrollToTargetAdjusted(receipt);

                function loadData() {
                    showSpinnerReceipt.classList.remove("show");
                    showReceipt.classList.add("show");
                }
                var timerId = setTimeout(loadData, 2000);
            });
        }

        function openReceipt() {
            postData();
            stepTwo.classList.remove("show");
            stepThree.classList.add("show");
            stepThree.scrollIntoView({ behavior: "smooth", block: "end" });
        }

        function openContactStep() {
            stepOne.classList.remove("show");
            stepTwo.classList.add("show");
            window.scrollTo({ top: 0 });
            scrollToTargetAdjusted(showContactContainer);

            showSpinnerContact.classList.add("show");

            function loadData() {
                showSpinnerContact.classList.remove("show");
                showContact.classList.add("show");
                inputName.focus();
            }
            var timerId = setTimeout(loadData, 2000);
        }

        function handleInput(e) {
            if (isActive) {
                isActive = false;
                showData.classList.remove("show");
            }

            let salery = e.target.value.replace(/[^0-9]/g, "");

            sixMonth.checked = true;
            e.target.value = new Intl.NumberFormat("da-dk").format(
                e.target.value.replace(/[^0-9]/g, "")
            );
            if (salery > 10000 && salery < 26354) {
                minError.classList.add("active");
            }

            if (salery > 85999) {
                maxError.classList.add("active");
            }

            if (salery < 86000) {
                maxError.classList.remove("active");
            }

            if (salery > 26353) {
                minError.classList.remove("active");
                setTimeout(function() {
                    getSalery(salery);
                    if (!isActive) {
                        isActive = true;
                        loadSpinne();
                    }
                }, 700);
            }
        }

        function handleTelephone(e) {
            e.target.value = e.target.value.replace(/([^0-9|\+])/g, "");
        }

        function getSalery(goal) {
            fetch(myRequest)
                .then(function(response) {
                    return response.json();
                })
                .then(function(data) {
                    var closest = data.reduce(function(prev, curr) {
                        return goal < curr.loen ? prev : curr;
                    });
                    setSlider(closest,data);
                });
        }

        function getPrice(salery) {
            fetch(myRequest)
                .then(function(response) {
                    return response.json();
                })
                .then(function(dataset) {
                    var price = dataset.filter(
                        data => data.dagpenge === Number(salery)
                    );

                    threeMonth.value = price[0].three_mnd;
                    sixMonth.value = price[0].six_mnd;
                    twelveMonth.value = price[0].twelve_mnd;
                    amount.innerHTML = new Intl.NumberFormat("da-dk").format(
                        sixMonth.value
                    );
                });
        }

        function setSlider(closest, data) {
            let lastStep = data[data.length-1];
            let maxDagpenge = lastStep.dagpenge;

            let maxdagpenge =
                closest.dagpenge <= maxDagpenge ? closest.dagpenge : maxDagpenge;

            slider.max = maxdagpenge;
            slider.value = maxdagpenge;
            maxValue.innerHTML = new Intl.NumberFormat("da-dk").format(
                maxdagpenge
            );
            slidenumber.innerHTML = new Intl.NumberFormat("da-dk").format(
                maxdagpenge
            );
            security.innerHTML = new Intl.NumberFormat("da-dk").format(
                maxdagpenge - dagpenge
            );
            getPrice(maxdagpenge);
        }

        function openInfo(e, popdata, button) {
            const thisPop = calculator.querySelector(
                ".info-text[data-info='" + popdata + "']"
            );
            const icon = button.querySelector(".icon");

            if (thisPop) {
                if (!thisPop.classList.contains("show")) {
                    thisPop.classList.add("show");
                    icon.classList.add("close");
                    icon.innerHTML = "x";
                } else {
                    thisPop.classList.remove("show");
                    icon.classList.remove("close");
                    icon.innerHTML = "i";
                }
            }
        }
        function loadSpinne() {
            showSpinner.classList.add("show");
            saleryInput.blur();
            function loadData() {
                showSpinner.classList.remove("show");
                showData.classList.add("show");
                window.scrollTo({ top: 0 });
                scrollToTargetAdjusted(showData);
            }
            var timerId = setTimeout(loadData, 2000);
        }

        function scrollToTargetAdjusted(element) {
            var headerOffset = 80;
            var elementPosition = element.getBoundingClientRect().top;

            var offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
            window.scrollTo(0, offsetPosition);
        }
    }
  }
}


  