import styled from "styled-components";

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 5px;
  background-color: #f6f1ee;
  color: #4f4a45;
  width: 100%;
  padding: 36px;
`;

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
