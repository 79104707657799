import React, { useState } from "react";
import Portal from "../Portal";
import { Backdrop, Content, Inner } from "./SimpleModal.styles";

export interface Props {
  open: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  maxWidth?: number;
}

export default function Modal({ open, children, onClose, maxWidth }: Props) {
  const [active, setActive] = useState(false);
  const backdrop = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e: any) => [27].indexOf(e.which) >= 0 && onClose && onClose();

    const clickHandler = (e: any) => e.target === current && onClose && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        (document as any).activeElement.blur();
        setActive(open);
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, onClose]);

  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open ? "active" : ""}>
            <Content className="modal-content" maxWidth={maxWidth ?? 800}>
              <Inner>{children}</Inner>
            </Content>
          </Backdrop>
        </Portal>
      )}
    </React.Fragment>
  );
}
