
export default class FormSlider {
    constructor(element) {
        this.el = element
        this.init()
    }

    init() {
        const slideforms = document.querySelectorAll(".umbraco-forms-theme-slider")
        if (slideforms) {
            slideforms.forEach(form => {
                let umbracoPage = form.querySelector(".umbraco-forms-page");
                let totalCounter = umbracoPage.dataset.total;
                let nextButtons = form.querySelectorAll(".next-button")
                let backButtons = form.querySelectorAll(".prev-button")
                let stepButtons = form.querySelectorAll(".step-container span")
                let formslider = form.querySelector('.slide-container')
                MoveNavigation(form, totalCounter)

                if (nextButtons) {
                    nextButtons.forEach(nextButton => {
                        nextButton.addEventListener('click', e => {
                            e.preventDefault();
                            forward(formslider, nextButton, totalCounter)
                        })
                    })
                }
                if (backButtons) {
                    backButtons.forEach(backButton => {
                        backButton.addEventListener('click', e => {
                            e.preventDefault();
                            backwards(formslider, backButton)

                        })
                    })
                }
            })
        }

        function MoveNavigation(form, totalCounter) {
            totalCounter = Number(totalCounter) - 1
            let Navigation = form.querySelector('.umbraco-forms-navigation')
            let lastSlide = form.querySelector("[data-slide='" + totalCounter + "']")
            if (lastSlide) {
                let lastSlideNavigation = lastSlide.querySelector(".navigation-container");
                if (lastSlideNavigation && Navigation) {
                    lastSlideNavigation.appendChild(Navigation);
                }

            }
        }

        function goToStep(formslider, step) {
            let gotoSlide = Number(step.dataset.step)
            if (validateSlide() == 0) {
                formslider.style.transform = "translateX(" + gotoSlide * 100 * - 1 + "%)"
                setActiveSlide(formslider, gotoSlide)
            }
        }

        function forward(formslider, button, totalCounter) {
            let nextSlide = Number(button.dataset.goto) + 1;

            if (validateSlide() == 0) {
                formslider.style.transform = "translateX(" + nextSlide * 100 * -1 + "%)"
                setActiveSlide(formslider, nextSlide)
                // formslider.style.paddingTop = "80px"
                // formslider.scrollIntoView(true)

            }
        }

        function backwards(formslider, button) {
            let prevSlide = Number(button.dataset.goto) + -1
            formslider.style.transform = "translateX(" + prevSlide * 100 * -1 + "%)"
            setActiveSlide(formslider, prevSlide)
            // formslider.style.paddingTop = "80px"
            // formslider.scrollIntoView(true)
        }

        function setActiveSlide(formslider, index) {
            const slides = formslider.querySelectorAll(".umbraco-forms-fieldset")
            if (slides) {
                slides.forEach(slide => {
                    if (Number(slide.dataset.slide) == index) {
                        slide.classList.remove("inactive")
                        slide.classList.add("active")
                    }
                    else {
                        slide.classList.add("inactive")
                        slide.classList.remove("active")
                    }
                })
            }
        }

        function validateSlide() {
            let validationArray = [];
            $("fieldset.active [data-val='true']").each(function () {
                var id = "#" + $(this).attr("id")
                $(id).valid();
                if (!$(id).valid()) {
                    validationArray.push({
                        id: id,
                        valid: false
                    });
                }

            })
            return validationArray.length

        }

    }
}
