import styled from "styled-components";

export const AseFieldset = styled.fieldset<{ isRelative?: boolean }>`
  border: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  ${({ isRelative }) => isRelative && "position: relative;"}
`;

export const AseButtonActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  margin-bottom: 2rem;
`;

export const AseList = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const AseFormInputRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  width: 100%;
  gap: 30px;
`;

export const AseFormInputError = styled.div`
  color: var(--color-error);
`;

export const AseSpinner = styled.span`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: ASESpinner 1s linear infinite;
`;

export const ASESummaryHeader = styled.div`
  border-bottom: 2px solid var(--color-petrol);
  margin: 2rem 0 1.4rem 0;

  h3 {
    text-transform: initial;
  }
`;
