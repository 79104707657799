import styled from "styled-components";

export const Wrapper = styled.div`
  border: 2px solid var(--color-petrol);
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--color-white);
`;

export const HeaderTitle = styled.h3`
  text-transform: initial;
`;

export const Content = styled.div<{ withGradient: boolean }>`
  background-color: var(--color-petrol);
  padding: 20px;

  ${({ withGradient }) =>
    withGradient &&
    `
  background: linear-gradient(133deg, rgba(0, 127, 159, 1) 37%, rgba(0, 142, 159, 1) 100%);
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 0.8rem;
  font-size: 14px;
  color: white;
  text-transform: initial;

  &:last-child {
    padding: 0;
  }

  .bold {
    font-weight: 700;
  }
`;
