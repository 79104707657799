import { useEffect, useState } from "react";
import { Actions, Circle, Description, Inner, Label, LoadingWrapper, RatingList, SpinnerText, TextArea, Title, Wrapper } from "./RatingForm.styles";
import { IoStar, IoStarOutline, IoCheckmark } from "react-icons/io5";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import { RatingRequest } from "../../types/RatingRequest";
import { postRating } from "../../utils/api";

export default function RatingForm() {
  const [submittingStarRating, setSubmittingStarRating] = useState(true);
  const [submittingReview, setSubmittingReview] = useState(false);
  const [reviewIsSubmitted, setReviewIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [request, setRequest] = useState<RatingRequest>({ userId: "", rating: 0, message: "" });

  const submitStarRating = async (requestData: RatingRequest) => {
    try {
      await postRating(requestData);
    } catch (error) {
      setHasError(true);
    } finally {
      setSubmittingStarRating(false);
    }
  };

  const submitReviewText = async () => {
    try {
      if (request.userId && request.rating !== 0) {
        setSubmittingReview(true);
        await postRating(request);
      } else {
        throw new Error("Missing userId or rating");
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setSubmittingReview(false);
      setReviewIsSubmitted(true);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const rating = urlParams.get("rating");
    const userId = urlParams.get("id");

    if (rating && userId) {
      const requestData = { ...request, rating: parseInt(rating), userId };
      setRequest(requestData);
      submitStarRating(requestData);
    } else {
      setHasError(true);
      setSubmittingStarRating(false);
    }
  }, []);

  return (
    <Wrapper>
      {submittingStarRating && !reviewIsSubmitted && (
        <LoadingWrapper>
          <Spinner isCentered />
          <SpinnerText>Gemmer din bedømmelse...</SpinnerText>
        </LoadingWrapper>
      )}
      {!submittingStarRating && hasError && (
        <LoadingWrapper>
          <SpinnerText style={{ color: "red", fontSize: 20, fontWeight: 700 }}>Der opstod en fejl - prøv venligst senere!</SpinnerText>
        </LoadingWrapper>
      )}
      {!submittingStarRating && !reviewIsSubmitted && !hasError && (
        <Inner>
          <RatingList>
            {[1, 2, 3, 4, 5].map((item, index) => {
              if (item <= request.rating) {
                return (
                  <IoStar
                    key={index}
                    size={45}
                    color="rgb(255 205 17)"
                    onClick={() => {
                      setRequest({ ...request, rating: index + 1 });
                    }}
                  />
                );
              } else {
                return (
                  <IoStarOutline
                    key={index}
                    size={45}
                    color="rgb(255 205 17)"
                    onClick={() => {
                      setRequest({ ...request, rating: index + 1 });
                    }}
                  />
                );
              }
            })}
          </RatingList>
          <Title>Tak for din bedømmelse af os</Title>

          <Description>
            Vi sætter pris på din mening og bruger den til at forbedre vores service og produkter. Er der noget, vi kan gøre bedre? Så fortæl os det. Din feedback er værdifuld.
            <br />
            <br />
            <span style={{ fontWeight: 700 }}>Hilsen Ase</span>
          </Description>

          <Label>Lad os høre din mening: </Label>
          <TextArea onChange={(e) => setRequest({ ...request, message: e.currentTarget.value })} value={request.message} />

          <Actions>
            <Button onClick={submitReviewText} loading={submittingReview} disabled={request.message === ""}>
              Send
            </Button>
          </Actions>
        </Inner>
      )}
      {reviewIsSubmitted && (
        <Inner>
          <Circle>
            <IoCheckmark size={50} color="#fff" />
          </Circle>
          <Title>Tak for din kommentar!</Title>
          <Description>
            Vi forsøger konstant at forbedre vores service, og vi sætter stor pris på din feedback.
            <br />
            <br />
            <span style={{ fontWeight: 700 }}>Hilsen Ase</span>
          </Description>
        </Inner>
      )}
    </Wrapper>
  );
}
