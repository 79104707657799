import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--color-white);
`;

export const HeaderImage = styled.div<{ src: string }>`
  max-width: var(--content-max-width);
  width: 100%;
  height: 400px;
  margin: 0 auto;
  background-image: url(${(p) => p.src});
  background-position: top;
  background-size: cover;
`;

export const Inner = styled.div`
  margin: -120px auto 0 auto;
  width: 100%;
  max-width: 540px;
  background-color: #f5f5f5;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
`;

export const Header = styled.div`
  background-color: #3a3a39f2;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: var(--color-white);
  text-transform: initial;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const HeaderLabel = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--color-white);
  text-transform: initial;
  margin-bottom: 5px;
`;

export const HeaderInputWrapper = styled.div`
  position: relative;
`;

export const HeaderInput = styled.input`
  padding: 1rem;
  text-transform: initial;
  height: 60px;
  width: 100%;
  color: var(--color-coal);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.46);
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
`;

export const HeaderInputPostfix = styled.span`
  position: absolute;
  top: 18px;
  right: 16px;
`;

export const HeaderInputError = styled.span`
  position: absolute;
  text-transform: initial;
  font-size: 12px;
  top: calc(100% + 8px);
  left: 16px;
  color: var(--color-orange);

  @media (max-width: 768px) {
    top: calc(100% + 5px);
    font-size: 10px;
  }
`;

export const AnimatedContent = styled.div<{ height: number }>`
  transition: height 0.3s ease;
  will-transform: height;
  height: ${(p) => p.height}px;
`;

export const Content = styled.div`
  overflow: hidden;
`;
