import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  position: absolute;
  appearance: none;
  border: none;
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;

  &:focus + label {
    border: 5px solid rgba(0, 142, 159, 0.4);
    font-weight: 700;
  }

  &:checked + label {
    border: 5px solid var(--color-petrol);
    font-weight: 700;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  color: var(--color-black);
  width: 100%;
  font-size: var(--font-size-xlarge);
  cursor: pointer;
  padding: 1.4rem;
  border: 5px solid var(--color-white);
  text-transform: initial;
`;
