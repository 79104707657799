import SelectSalaryInsuranceScreen from "./Screens/DefaultScreen";
import ConfirmScreen from "./Screens/ConfirmScreen";
import ReceiptScreen from "./Screens/ReceiptScreen";
import { Wrapper } from "./SalaryInsuranceFlow.styles";
import { useState } from "react";

export default function SalaryInsuranceFlow() {
  const [height, setHeight] = useState(0);

  return (
    <Wrapper height={height}>
      <ReceiptScreen onHeightChange={setHeight} />
      <ConfirmScreen onHeightChange={setHeight} />
      <SelectSalaryInsuranceScreen onHeightChange={setHeight} />
    </Wrapper>
  );
}
