import { useEffect, useMemo, useState } from "react";
import TextInput from "../../components/TextInput";
import { IoCloseCircleOutline, IoCheckmarkCircleOutline, IoAdd, IoTrash } from "react-icons/io5";
import dayjs from "dayjs";
import "dayjs/locale/da";
import { postChildrenEnrollment } from "../../utils/api";
import Modal from "../../components/Modal";
import Analytics from "../../utils/analytics";
import { Content, DeleteButton, FormAction, FormActionButton, FormError, FormItem, FormValidation, FormWrapper, Header, HeaderTitle, HeaderValidation, Inner, SubmitButton, Wrapper } from "./ChildrenEnrollment.styles";
import { AseSpinner } from "../../utils/global.styles";

dayjs.locale("da");

const defaultState = { firstName: { value: "", error: true }, lastName: { value: "", error: true }, socialSecurityNumber: { value: "", error: true } };

const errorHtml = "<h2><b>Der opstod en fejl</b></h2><br /><p>Prøv igen om et par sekunder. Hvis fejlen varer ved, kan du ringe til os på <a href='tel:004570137013'  target='_blank'>7013 7013</a> og få hjælp. </p><br /><p>Vi sidder klar ved telefonerne:</p><br /><p><b>Mandag - torsdag: 8.00 - 17.00</b></p><p><b>Fredag: 8.00 - 16.00 </b></p><br /><br /><p>Venlig hilsen </p><p><b>Ase</b></p>";
let idToken = "";
let referralUrl = "";

export default function ChildrenEnrollment() {
  const [childrenList, setChildrenList] = useState<{ [key: string]: { value: string; error: boolean } }[]>([defaultState]);
  const [] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [name, setName] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const form = document.querySelector(`[data-component='form-childrenEnrollment']`) as HTMLElement;
    if (form?.dataset.redirect && form?.dataset.redirect !== "null") {
      setRedirectUrl(form?.dataset.redirect);
    }

    if (form?.dataset.firstname && form?.dataset.firstname !== "null") {
      setName(form?.dataset.firstname);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    idToken = urlParams.get("id") ?? "";
    referralUrl = urlParams.get("ref") ?? "";

    if (referralUrl) {
      referralUrl = `${referralUrl}?id=${idToken}`;
    }
  }, []);

  const setValue = (field: string, value: string, error: boolean, index: number) => {
    const updatedChildrenList = childrenList.map((childData, childIndex) => {
      if (childIndex === index) {
        const data = {
          ...childData,
          [field]: {
            ...childData[field],
            value,
            error,
          },
        };
        return data;
      } else {
        return childData;
      }
    });

    setChildrenList([...updatedChildrenList]);
  };

  const cprHasError = (cpr: string) => {
    if (!cpr) {
      return true;
    }

    const inputWithRemovedDash = cpr.replace("-", "");
    if (inputWithRemovedDash.length !== 10) {
      return true;
    }

    if (!/^[0-9]+$/.test(inputWithRemovedDash)) {
      return true;
    }

    const day = parseInt(inputWithRemovedDash.substring(0, 2));

    if (day < 0 || day > 31) {
      return true;
    }

    const month = parseInt(inputWithRemovedDash.substring(2, 4));

    if (month < 0 || month > 12) {
      return true;
    }

    let year = parseInt(inputWithRemovedDash.substring(4, 6));

    if (year < 0 || year > 99) {
      return true;
    }

    const date = new Date(2000 + year, month - 1, day);
    const valid = dayjs(date).isValid() && dayjs(date).isAfter(dayjs().subtract(20, "years"), "day");

    return !valid;
  };

  const isChildsAgeValid = (cpr: string) => {
    if (!cpr) {
      return true;
    }

    const inputWithRemovedDash = cpr.replace("-", "");
    if (inputWithRemovedDash.length !== 10) {
      return true;
    }

    if (!/^[0-9]+$/.test(inputWithRemovedDash)) {
      return true;
    }

    const day = parseInt(inputWithRemovedDash.substring(0, 2));

    if (day < 0 || day > 31) {
      return true;
    }

    const month = parseInt(inputWithRemovedDash.substring(2, 4));

    if (month < 0 || month > 12) {
      return true;
    }

    let year = parseInt(inputWithRemovedDash.substring(4, 6));

    if (year < 0 || year > 99) {
      return true;
    }

    const date = new Date(2000 + year, month - 1, day);
    const valid = dayjs(date).isValid() && dayjs(date).isAfter(dayjs().subtract(20, "years"), "day");

    return valid;
  };

  const onSendData = async () => {
    try {
      setLoading(true);
      Analytics.getInstance().trackSubmitChildrenEnrollment(childrenList.length);
      const childrenData = childrenList.map((childData) => {
        return {
          firstName: childData.firstName.value,
          lastName: childData.lastName.value,
          socialSecurityNumber: childData.socialSecurityNumber.value,
        };
      });
      await postChildrenEnrollment({ children: childrenData, token: idToken });

      if (referralUrl) {
        window.location.href = referralUrl;
      } else {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const noDuplicateCprs = useMemo(() => {
    let cprList = childrenList.map((child) => child.socialSecurityNumber.value);
    const noDuplicateCprs = cprList.filter((item, index) => cprList.indexOf(item) !== index).length === 0;
    return noDuplicateCprs;
  }, [childrenList]);

  const formIsValid = useMemo(() => {
    const noErroes = childrenList.every((childData) => {
      return Object.values(childData).every((item) => !item.error && item.value);
    });

    return noErroes && noDuplicateCprs;
  }, [childrenList, noDuplicateCprs]);

  return (
    <>
      <Wrapper>
        <Inner>
          <h1>Tilføj dine børn </h1>

          {name && <h2>Hej {name}</h2>}
          <p>Her tilføjer du dine børn til dit medlemskab. Husk at tilføje alle børn, inden du indsender oplysninger. </p>

          <FormWrapper>
            {childrenList.map((childData, index) => {
              const isValid = Object.values(childData).every((item) => !item.error && item.value);
              return (
                <FormItem key={index}>
                  <Header>
                    <HeaderTitle>
                      <span>Barn</span>
                      <HeaderValidation>{isValid ? <IoCheckmarkCircleOutline size={24} color="#479376" /> : <IoCloseCircleOutline size={24} color="rgba(58, 58, 57, .15)" />}</HeaderValidation>
                    </HeaderTitle>
                    {index !== 0 && (
                      <DeleteButton
                        onClick={() => {
                          const updatedList = childrenList.filter((item, itemIndex) => itemIndex !== index);
                          setChildrenList([...updatedList]);
                        }}
                      >
                        <IoTrash size={18} color="#3A3A39" />
                      </DeleteButton>
                    )}
                  </Header>

                  <Content>
                    <TextInput
                      label="Fornavn"
                      value={childData.firstName.value}
                      onChange={(e) => {
                        setValue("firstName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value, index);
                      }}
                      type="text"
                      autoComplete="given-name"
                      placeholder="Fornavn"
                      pattern=".{1,32}"
                      id="firstName"
                    />
                    <TextInput
                      label="Efternavn"
                      value={childData.lastName.value}
                      onChange={(e) => {
                        setValue("lastName", e.currentTarget.value, e.currentTarget.validity.patternMismatch || !e.currentTarget.value, index);
                      }}
                      type="text"
                      autoComplete="Efternavn"
                      placeholder="Efternavn"
                      pattern=".{1,32}"
                      id="lastName"
                    />
                    <TextInput
                      label="CPR"
                      value={childData.socialSecurityNumber.value}
                      onChange={(e) => {
                        const reg = /^\d{0,10}?$/;
                        if (reg.test(e.currentTarget.value)) {
                          setValue("socialSecurityNumber", e.currentTarget.value, cprHasError(e.currentTarget.value), index);
                        }
                      }}
                      hasError={(Boolean(childData.socialSecurityNumber.value) && cprHasError(childData.socialSecurityNumber.value as string)) || !noDuplicateCprs}
                      inputMode="numeric"
                      placeholder="CPR"
                      id="socialSecurityNumber"
                    />

                    <FormValidation>{isValid ? <IoCheckmarkCircleOutline size={28} color="#479376" /> : <IoCloseCircleOutline size={28} color="rgba(58, 58, 57, .15)" />}</FormValidation>
                  </Content>

                  {!isChildsAgeValid(childData.socialSecurityNumber.value as string) && <FormError>Dit barn må ikke være mere end 20 år gammelt, for at kunne blive medforsikret.</FormError>}
                </FormItem>
              );
            })}
            <FormAction>
              <FormActionButton
                className="button secondary"
                onClick={() => {
                  setChildrenList([...childrenList, defaultState]);
                }}
              >
                <IoAdd size={28} /> Tilføj barn
              </FormActionButton>
            </FormAction>

            <SubmitButton className="button" disabled={!formIsValid || loading} onClick={onSendData}>
              {loading ? <AseSpinner /> : <span>Indsend oplysninger</span>}
            </SubmitButton>
          </FormWrapper>
        </Inner>
      </Wrapper>

      <Modal modalDescriptionHtml={errorHtml} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
