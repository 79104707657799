import styled from "styled-components";

export const StepQuestion = styled.h3`
  text-transform: initial;
  margin-bottom: 0;
  padding-right: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const ProductSelectionWrapper = styled.div`
  width: 100%;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: var(--color-white);
  margin-bottom: 30px;
`;

export const ProductSelectionWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;
`;

export const ProductCheckbox = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border: 3px solid var(--color-petrol);
  border-radius: 3px;
  transition: border-color 0.3s ease;

  ${({ active }) =>
    active &&
    `
      background-color: var(--color-petrol);
      box-shadow: 0 0 4px 3px rgba(0,142,159,.25);

      &:after {
        border-bottom: 4px solid #fff;
        border-bottom: 4px solid var(--color-white);
        border-right: 4px solid #fff;
        border-right: 4px solid var(--color-white);
        content: "";
        height: 20px;
        left: 9px;
        position: absolute;
        top: 3px;
        transform: rotate(45deg);
        width: 11px;
      }
    `}
`;

export const ProductNameCheckbox = styled.p`
  margin: 0;
  padding-left: 20px;
  font-weight: var(--font-weight-bold);
`;
