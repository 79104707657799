import { SpringValue } from "@react-spring/web";
import { SubTitle, Title, Wrapper } from "./SuccessScreen.styles";
import Lottie from "react-lottie";
import * as SuccessAnimation from "./SuccessAnimation.json";

interface Props {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

export default function SuccessScreen({ style }: Props) {
  return (
    <Wrapper style={style}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: SuccessAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={130}
        width={130}
        style={{ marginBottom: 20 }}
      />

      <Title>Tak!</Title>
      <SubTitle>Vi kontakter dig snarest.</SubTitle>
    </Wrapper>
  );
}
