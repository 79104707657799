import { useMemo } from "react";
import { BarBaseCoverage, BarSalary, BarSalaryInsurance, BarTitle, BarWrapper, ProcentageTitle, Wrapper } from "./SalaryBarChart.styles";

interface Props {
  salary: number;
  salaryInsurance: number;
  baseCoverage: number;
}

export default function SalaryBarChart({ salary, salaryInsurance, baseCoverage }: Props) {
  const { coverageProcentage, salaryInsuranceProcentage, salaryProcentage } = useMemo(() => {
    const salaryInsuranceProcentage = Math.round((salaryInsurance / salary) * 100);
    const coverageProcentage = Math.round((baseCoverage / salary) * 100);
    const salaryProcentage = 100 - coverageProcentage - salaryInsuranceProcentage;

    return {
      coverageProcentage,
      salaryInsuranceProcentage,
      salaryProcentage,
    };
  }, [salary, salaryInsurance, baseCoverage]);

  return (
    <Wrapper>
      <BarWrapper>
        <BarSalary procentage={salaryProcentage}>
          <BarTitle>Normal løn</BarTitle>
          <ProcentageTitle>{salaryProcentage}%</ProcentageTitle>
        </BarSalary>
        <BarSalaryInsurance procentage={salaryInsuranceProcentage}>
          <BarTitle>Lønsikring</BarTitle>
          <ProcentageTitle>{salaryInsuranceProcentage}%</ProcentageTitle>
        </BarSalaryInsurance>
        <BarBaseCoverage procentage={coverageProcentage}>
          <BarTitle>Dagpenge</BarTitle>
          <ProcentageTitle>{coverageProcentage}%</ProcentageTitle>
        </BarBaseCoverage>
      </BarWrapper>
    </Wrapper>
  );
}
