import { isMobile,isDesktop } from "../../services/service-utils";

export default class EqualHeight {
  constructor(element) {
    this.el = element;
    this.equalizedElement = this.el.dataset.elements
    this.equalize = this.equalize.bind(this)
    this.equalizeRow = this.equalizeRow.bind(this)
    if (isDesktop) {
      this.init();
    }
  }

  init() {
    if(this.equalizedElement) {
        this.equalize();
    }
    if(!this.equalizedElement) {
        this.equalizeRow();
    }
  }
  equalize() {
      let equalizid = this.el.querySelectorAll(this.equalizedElement)
      let setHeight = 0;

      document.addEventListener('readystatechange', event => {
          if(event.target.readyState === 'complete') {
              if(equalizid) {
                  equalizid.forEach(element => {
                      setHeight = setHeight > element.offsetHeight ? setHeight : element.offsetHeight
                  });
                  equalizid.forEach(element => {
                      element.style.height = setHeight + "px";
                      if(element.offsetHeight == 0) {
                          element.style.height = "fit-content"
                      }
                  })
              }
          }
      });
  }
  equalizeRow() {
      let equalizid = this.el.querySelectorAll("[data-equalize]")
      let setHeight = 0;
      document.addEventListener('readystatechange', event => {
          if(event.target.readyState === 'complete') {
              if(equalizid) {
                  equalizid.forEach(element => {
                      setHeight = setHeight > element.offsetHeight ? setHeight : element.offsetHeight
                  });
              }
              equalizid.forEach(element => {
                  element.style.height = setHeight + "px";
                  if(element.offsetHeight == 0) {
                      element.style.height = "fit-content"
                  }
              })
          }
      });
  }
}