import React, { useState } from "react";
import Portal from "../Portal";
import { Backdrop, CloseButton, Content } from "./Modal.styles";
import { IoClose } from "react-icons/io5";

export interface Props {
  open: boolean;
  modalDescriptionHtml: string;
  onClose?: () => void;
}

export default function Modal({ open, modalDescriptionHtml, onClose }: Props) {
  const [active, setActive] = useState(false);
  const backdrop = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e: any) => [27].indexOf(e.which) >= 0 && onClose && onClose();

    const clickHandler = (e: any) => e.target === current && onClose && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        (document as any).activeElement.blur();
        setActive(open);
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, onClose]);

  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open ? "active" : ""}>
            <Content className="modal-content">
              <div style={{ overflowY: "auto", maxHeight: "calc(80vh - 60px)" }}>
                <div className="help-content" tabIndex={0} dangerouslySetInnerHTML={{ __html: modalDescriptionHtml }}></div>
              </div>
              <CloseButton onClick={onClose}>
                <IoClose size={30} color="#fff" />
              </CloseButton>
            </Content>
          </Backdrop>
        </Portal>
      )}
    </React.Fragment>
  );
}
