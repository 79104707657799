import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import StepContainer from "../../../../components/StepContainer";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { fetchSignupDate } from "../../../../utils/api";
import Modal from "../../../../components/Modal";
import { ASESummaryHeader } from "../../../../utils/global.styles";
import { Row } from "./SummaryStep.styles";

export default function SummaryStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const setPayload = useStore.useSetPayload();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const rows = useMemo(() => {
    const r = [];

    const UnemploymentInsurancePrice = payload.wantsUnemploymentBenefits ? Number(t?.summaryStep.unemploymentInsurance.price) : 0;
    const FamilyUnionPrice = 0;

    let totalAmount = UnemploymentInsurancePrice;

    if (payload.wantsFamilyUnion) {
      totalAmount = totalAmount + FamilyUnionPrice;
    }

    if (payload.wantsUnemploymentBenefits) {
      r.push({
        label: t?.summaryStep.unemploymentInsurance.title,
        value: `${UnemploymentInsurancePrice} ${t?.general.krPerMonth}`,
      });
    }

    if (payload.wantsFamilyUnion) {
      r.push({
        label: `${t?.productsStep.familyUnion.title} (tilføjes som partner)`,
        value: `0 ${t?.general.krPerMonth}`,
      });
    }

    r.push({
      label: t?.general.totalAmount,
      value: `${totalAmount} ${t?.general.krPerMonth}`,
      bold: true,
    });

    return r as { label: string; value: string; bold?: boolean }[];
  }, [payload, t]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await fetchSignupDate(payload);
        setPayload({ signupDate: response.data.signupdate });
      } catch (err) {
        setShowErrorModal(true);
      }
    };

    if (payload) {
      fetch();
    }
  }, []);

  return (
    <>
      <StepContainer
        stepTitle={t?.summaryStep.title}
        progress={0.9}
        buttonText={t?.summaryStep.buttonLabel}
        helpText={t?.summaryStep.helpText}
        subtitle="Velkommen til familiefagforeningen!"
        onNext={() => {
          setRouteStack(StepRoutes.PaymentStep);
        }}
      >
        <ASESummaryHeader>
          <h3>
            <strong>{t?.summaryStep.personalInformation}</strong>
          </h3>
        </ASESummaryHeader>
        <div>
          <Row>
            <span>{t?.informationAboutYouStep.cpr}</span>
            <span>{payload.socialSecurityNumber.slice(0, 6)}••••</span>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.name}</div>
            <div>
              {payload.firstName} {payload.lastName}
            </div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.address}</div>
            <div>{`${payload.address}, ${payload.zipCode} ${payload.city}`}</div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.mail}</div>
            <div>{payload.mailAddress}</div>
          </Row>
          <Row>
            <div>{t?.informationAboutYouStep.phone}</div>
            <div>{payload.mobilePhone}</div>
          </Row>
        </div>

        {payload.wantsSalaryInsurance && !payload.wantsUnion && !payload.wantsHRJura && (
          <p style={{ paddingTop: 30, color: "var(--color-orange)" }}>
            <strong>{t?.summaryStep.salaryInsuranceInfo}</strong>
          </p>
        )}
        {payload.wantsHRJura && payload.wantsSalaryInsurance && payload.occupation === "SelfEmployed" && (
          <p style={{ paddingTop: 30, color: "var(--color-orange)" }}>
            <strong>{t?.summaryStep.salaryInsuranceAndHRJuraInfo}</strong>
          </p>
        )}

        {payload.wantsHRJura && !payload.wantsSalaryInsurance && payload.occupation === "SelfEmployed" && (
          <p style={{ paddingTop: 30, color: "var(--color-orange)" }}>
            <strong>{t?.summaryStep.hrJuraInfo}</strong>
          </p>
        )}

        <TotalAmountBox date={payload.signupDate} rows={rows} />
      </StepContainer>
      <Modal modalDescriptionHtml={t?.summaryStep.errorMessage ?? ""} open={showErrorModal} onClose={() => setShowErrorModal(false)} />
    </>
  );
}
