import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 740px;
  align-items: center;
  justify-content: center;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  margin: 0 auto;
  max-width: 600px;

  h1 {
    text-transform: initial;
  }

  h2 {
    margin: 0;
    text-transform: initial;
  }
`;

export const Title = styled.h2`
  text-transform: initial;
  text-align: center;
  font-size: 2.2rem;
  padding-bottom: 20px;
`;

export const Description = styled.p`
  text-transform: initial;
  text-align: center;
  padding-bottom: 40px;
`;

export const RatingList = styled.div`
  display: flex;
  gap: 30px;
  max-width: 390px;
  width: 100%;
  background-color: var(--color-coal-15);
  padding: 10px 30px;
  border-radius: 40px;
  margin-bottom: 80px;
  align-self: center;
  justify-content: center;

  @media (max-width: 600px) {
    gap: 15px;
  }

  svg {
    cursor: pointer;
  }
`;

export const TextArea = styled.textarea`
  background-color: var(--color-coal-5);
  border: 3px solid var(--color-coal-15);
  resize: none;
  border-radius: 10px;
  height: 160px;
  margin-bottom: 20px;
  padding: 20px;
  outline: none;

  &:focus {
    border-color: var(--color-petrol);
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: initial;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 65px;
  height: 65px;
  background-color: var(--color-green-2);
  border-radius: 50%;
  margin-bottom: 40px;
`;

export const SpinnerText = styled.p`
  text-transform: initial;
  padding-top: 80px;
  margin: 0;
`;
