import { useEffect, useMemo } from "react";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { InnerRow, Row, Spacer, StepQuestion } from "./MembershipStep.styles";
import Checkbox from "../../../../components/Checkbox";
import Select from "../../../../components/Select";
import Analytics from "../../../../utils/analytics";
import { AseFieldset } from "../../../../utils/global.styles";
import { TrackingFlowTypeEnum } from "../../../../types/TrackingFlowTypeEnum";

export default function MembershipStep() {
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();
  const unimploymentInsuranceOptions = useStore.useUnimploymentInsuranceOptions();
  const unionOptions = useStore.useUnionOptions();
  const t = useStore.useTranslations();

  const disabled = useMemo(() => {
    return (payload.wantsUnemploymentBenefits && payload.currentlyHasUnemploymentBenefits === undefined) || (payload.wantsUnemploymentBenefits && payload.currentlyHasUnemploymentBenefits && !Boolean(payload.currentUnemploymentBenefits)) || (payload.wantsUnion && payload.currentlyMemberOfAUnion === undefined) || (payload.wantsUnion && payload.currentlyMemberOfAUnion && !Boolean(payload.currentUnion));
  }, [payload]);

  useEffect(() => {
    if (payload.currentUnemploymentBenefits) {
      const option = unimploymentInsuranceOptions.find((o) => o.id === payload.currentUnemploymentBenefits);
      const relatedUnion = option?.related;

      if (relatedUnion) {
        const unionOption = unionOptions.find((o) => o.id === relatedUnion);

        if (payload.wantsFamilyUnion || payload.wantsUnion) {
          setPayload({ currentUnion: unionOption?.id });
        } else {
          setPayload({ currentUnion: undefined });
        }
      }
    }
  }, [payload.currentUnemploymentBenefits, payload.wantsFamilyUnion, payload.wantsUnion, unimploymentInsuranceOptions]);

  const canUnsubscribe = useMemo(() => {
    const relatedUnion = unionOptions.find((o) => o.id === payload.currentUnion);

    return Boolean(relatedUnion?.userUnsubscribe);
  }, [payload.currentUnemploymentBenefits, payload.currentUnion, unimploymentInsuranceOptions]);

  useEffect(() => {
    if (canUnsubscribe && payload.currentUnion && payload.currentlyMemberOfAUnion) {
      setPayload({ unsubscribeCurrentUnion: true });
    } else {
      setPayload({ unsubscribeCurrentUnion: false });
    }
  }, [canUnsubscribe, payload.currentUnion, payload.currentlyMemberOfAUnion]);

  return (
    <StepContainer
      progress={0.35}
      buttonText={t?.workingDenmarkStep.buttonLabel}
      helpText={t?.workingDenmarkStep.helpText}
      subtitle="Velkommen til familiefagforeningen!"
      onNext={() => {
        if (!payload.isDanishResident) {
          setRouteStack(StepRoutes.NeedToContactYouStep);
          setPayload({ cause: "Bor i udlandet" });
          return;
        }
        // Add tracking
        if (payload.currentlyHasUnemploymentBenefits) {
          Analytics.getInstance().trackTerminateUnemploymentBenefits(TrackingFlowTypeEnum.PARTNER);
        }

        if (payload.unsubscribeCurrentUnion) {
          Analytics.getInstance().trackTerminateUnion(TrackingFlowTypeEnum.PARTNER);
        }

        if (payload.occupation === "Employee" || payload.occupation === "SelfEmployed") {
          if (payload.wantsUnemploymentBenefits) {
            if (payload.currentlyHasUnemploymentBenefits) {
              setRouteStack(StepRoutes.WorkingDenmarkStep);
            } else {
              setRouteStack(StepRoutes.FitWithYouStep);
            }
          } else {
            setRouteStack(StepRoutes.ConsentStep);
          }
        } else {
          // TODO: UnEmployed
          if (payload.wantsUnemploymentBenefits) {
            if (payload.currentlyHasUnemploymentBenefits) {
              setRouteStack(StepRoutes.ReceivingUnemploymentBenefitsStep);
            } else {
              setRouteStack(StepRoutes.NeedToContactYouStep);
            }
          } else {
            setRouteStack(StepRoutes.ConsentStep);
          }
        }
      }}
      nextDisabled={disabled}
    >
      <AseFieldset>
        <Row>
          <StepQuestion>Bor du i Danmark?</StepQuestion>
          <Spacer />
          <InnerRow>
            <Checkbox
              onChange={() => {
                setPayload({ isDanishResident: true });
              }}
              text={t?.unsubscripeCurrentUnionStep.answerYes}
              checked={payload.isDanishResident === undefined ? false : payload.isDanishResident}
            />
            <Checkbox
              onChange={() => {
                setPayload({
                  isDanishResident: false,
                });
              }}
              text={t?.unsubscripeCurrentUnionStep.answerNo}
              checked={payload.isDanishResident === undefined ? false : !payload.isDanishResident}
            />
          </InnerRow>
        </Row>

        {payload.wantsUnemploymentBenefits && (
          <Row>
            <StepQuestion>{t?.unemploymentInsuranceStep.title}</StepQuestion>
            <Spacer />
            <InnerRow>
              <Checkbox
                onChange={() => {
                  setPayload({ currentlyHasUnemploymentBenefits: true });
                }}
                text={t?.unemploymentInsuranceStep.answerYes}
                checked={payload.currentlyHasUnemploymentBenefits === undefined ? false : payload.currentlyHasUnemploymentBenefits}
              />
              <Checkbox
                onChange={() => {
                  setPayload({
                    currentlyHasUnemploymentBenefits: false,
                    currentUnemploymentBenefits: undefined,
                  });
                }}
                text={t?.unemploymentInsuranceStep.answerNo}
                checked={payload.currentlyHasUnemploymentBenefits === undefined ? false : !payload.currentlyHasUnemploymentBenefits}
              />
            </InnerRow>
          </Row>
        )}

        {payload.wantsUnemploymentBenefits && payload?.currentlyHasUnemploymentBenefits && (
          <>
            <div style={{ paddingBottom: "1.4rem" }}>
              <Select
                id="member_akasse_which"
                options={unimploymentInsuranceOptions}
                label={t?.general.which}
                defaultOption={t?.unemploymentInsuranceStep.chooseUnemploymentInsurance}
                selectedOption={unimploymentInsuranceOptions.find((o) => o.id === payload.currentUnemploymentBenefits)}
                onSelect={(id) => {
                  setPayload({ currentUnemploymentBenefits: id });
                }}
              />
            </div>
            {payload.currentUnemploymentBenefits && <p style={{ marginBottom: "4rem" }}>{t?.unemploymentInsuranceStep.description}</p>}
          </>
        )}

        {(payload.wantsFamilyUnion || payload.wantsUnion) && (
          <Row>
            <StepQuestion>{t?.unionStep.title}</StepQuestion>
            <Spacer />
            <InnerRow>
              <Checkbox
                onChange={() => {
                  setPayload({ currentlyMemberOfAUnion: true });
                }}
                text={t?.unionStep.answerYes}
                checked={payload.currentlyMemberOfAUnion === undefined ? false : payload.currentlyMemberOfAUnion}
              />
              <Checkbox
                onChange={() => {
                  setPayload({
                    currentlyMemberOfAUnion: false,
                    unsubscribeCurrentUnion: false,
                    currentUnion: undefined,
                  });
                }}
                text={t?.unionStep.answerNo}
                checked={payload.currentlyMemberOfAUnion === undefined ? false : !payload.currentlyMemberOfAUnion}
              />
            </InnerRow>
          </Row>
        )}

        {(payload.wantsFamilyUnion || payload.wantsUnion) && payload?.currentlyMemberOfAUnion && (
          <>
            <div style={{ paddingBottom: "1.4rem" }}>
              <Select
                id="member_fagforening_which"
                options={unionOptions}
                label={t?.general.which}
                defaultOption={t?.unionStep.chooseUnion}
                selectedOption={unionOptions.find((p) => p.id === payload.currentUnion)}
                onSelect={(id) => {
                  setPayload({ currentUnion: id });
                }}
              />
            </div>
            {t?.unionStep.description && <p>{t?.unionStep.description}</p>}
          </>
        )}

        {!canUnsubscribe && payload.currentlyMemberOfAUnion && payload.currentUnion && (
          <p>
            <strong>{t?.unionStep.cannotMoveUnion}</strong>
          </p>
        )}
        {canUnsubscribe && payload.currentlyMemberOfAUnion && (
          <Row>
            <StepQuestion>{t?.unsubscripeCurrentUnionStep.title}</StepQuestion>
            <Spacer />
            <InnerRow>
              <Checkbox
                onChange={() => {
                  setPayload({ unsubscribeCurrentUnion: true });
                }}
                text={t?.unsubscripeCurrentUnionStep.answerYes}
                checked={payload.unsubscribeCurrentUnion === undefined ? false : payload.unsubscribeCurrentUnion}
              />
              <Checkbox
                onChange={() => {
                  setPayload({
                    unsubscribeCurrentUnion: false,
                  });
                }}
                text={t?.unsubscripeCurrentUnionStep.answerNo}
                checked={payload.unsubscribeCurrentUnion === undefined ? false : !payload.unsubscribeCurrentUnion}
              />
            </InnerRow>
          </Row>
        )}
      </AseFieldset>
    </StepContainer>
  );
}
