export default class SalaryInsuranceCalculatorModule {
    constructor(element) {
        this.el = element
        this.init()
    }

    init() {
        const version = 4
        let drawLine = null;
        const insuranceCalcContainer = this.el;
        const insuranceWheel = this.el.querySelector(".insurance-calculator-wheel");
        const calculatorSection = this.el.querySelectorAll(".claculator-insurance-section");
        const touchOverlay = this.el.querySelector(".touch-overlay");
        let glide = null;
        let selectedPackage = null;

        if (!insuranceWheel) {
            return
        } else {
            const slide = insuranceWheel.querySelector(".glide");
            const glideSlides = slide.querySelector(".glide__slides");
            // Initial mount of glide slider.
            if (slide) {
                glide = new Glide(slide, {
                }).mount()
                resizeFunction();

                setTimeout(e => {
                    glide.go('=1')
                }, 400)


                insuranceWheel.addEventListener('touchstart', e => {
                    touchOverlay.classList.remove('loaded', 'pulse');
                });
                insuranceWheel.addEventListener('mousedown', e => {
                    touchOverlay.classList.remove('loaded', 'pulse');
                })
                window.addEventListener("resize", resizeFunction);

                const insuranceCards = insuranceWheel.querySelectorAll(".insurance-card");
                insuranceCards.forEach(card => {
                    const cardAction = card.querySelector("button");
                    const cardInfo = card.querySelectorAll(".info-toggle");
                    const SlideTarget = cardAction.dataset.setactive;
                    cardAction.addEventListener('click', () => {
                        glide.go(`'=${SlideTarget}'`)
                        const targetCalculator = insuranceCalcContainer.querySelector(`[data-calcount="${SlideTarget}"]`);

                        // Set Placeholder formatted
                        const input = targetCalculator.querySelector(".number-input input")
                        const placeholder = `min. ${Intl.NumberFormat('da-DK').format(input.dataset.minValue)}`;
                        input.placeholder = placeholder;

                        insuranceCards.forEach(card => {
                            card.classList.remove("active");
                        })
                        card.classList.add("active");
                        calculatorSection.forEach(calc => {
                            calc.style.display = "none";
                        })
                        const existingLine = document.querySelector(".leader-line");
                        if (existingLine) {
                            existingLine.remove();
                        }
                        if (window.innerWidth > 991) {
                            setTimeout(e => {
                                drawLine = new LeaderLine(
                                    card.querySelector("#point"),
                                    targetCalculator.querySelector("#target"), {
                                    path: "fluid",
                                    color: "#d76f2c"
                                }
                                );
                            }, 300)
                        }


                        const calcName = card.querySelector("h2 span").textContent;
                        targetCalculator.style.display = "block";
                        selectedPackage = SlideTarget;

                        targetCalculator.querySelector(".calculator-name").textContent = calcName;
                    });

                    cardInfo.forEach(toggle => {
                        toggle.addEventListener('click', () => {
                            renderPopup(toggle);
                        })
                    })

                });

                // Resize function to ensure that gide will dismount if device size exceeds 991px.
                function resizeFunction() {
                    const svgGraphic = document.querySelector(".leader-line");
                    var windowSize = window.innerWidth;
                    if (windowSize > 991) {
                        glide.disable();
                        glideSlides.style.width = "auto";
                        glideSlides.style.overflow = "visible";
                        glideSlides.style.transform = "translate3d(0px, 0px, 0px)";
                        if (svgGraphic) {
                            svgGraphic.style.display = "block";

                        }
                    } else {
                        if (svgGraphic) {
                            svgGraphic.style.display = "none";
                        }
                        glide.enable();
                        glideSlides.style.overflow = "hidden";
                    }
                };
                // Informational popups.
                function renderPopup(toggle) {
                    const popUp = `
                        <div class="popup-container">
                            <div class="popup">
                                <div class="popup-content">

                                    <h4>${toggle.dataset.popuptitle}</h4>
                                    <p>${toggle.dataset.popupdesc}</p>
                                    <button class="cross-close close"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <rect x="12.728" y="3.53552" width="3" height="13" transform="rotate(45 12.728 3.53552)" fill="black"/>
                                    <rect x="5.65674" y="3.53552" width="13" height="3" transform="rotate(45 5.65674 3.53552)" fill="black"/>
                                    </svg></button>
                                </div>
                            </div>
                        </div>`
                    const body = document.querySelector("body");
                    body.insertAdjacentHTML("beforeend", popUp);
                    const mypopup = body.querySelector(".popup-container");
                    const closePopup = mypopup.querySelector(".close");
                    if (closePopup) {
                        closePopup.addEventListener('click', e => {
                            mypopup.remove();
                        })
                    }
                }
            }


        }
        // Calculation part:
        if (calculatorSection.length) {
            calculatorSection.forEach(calc => {
                const calcount = calc.dataset.calcount;
                const calculator = calc.querySelector(".calculator");
                const calculatorID = calculator.dataset.calculatorid;
                const jsonSheet = calculator.dataset.jsoncontent;
                const nonFormattedDagpenge = calculator.dataset.dagpengesats;
                const formatDagpenge = nonFormattedDagpenge.replaceAll(".", "");
                const dagpenge = formatDagpenge;
                const showData = calculator.querySelector(".show-data");
                const showContact = calculator.querySelector(".contact-input");
                const showSpinner = calculator.querySelector(".loader");
                const showSpinnerContact = calculator.querySelector(".loader.contact");
                const showSpinnerReceipt = calculator.querySelector(".loader.receipt");
                const slider = calculator.querySelector(".myRange");
                const slidenumber = calculator.querySelector(".slidenumber");
                const security = calculator.querySelector(".security");
                const infoboxes = [].slice.call(calculator.querySelectorAll(".info"));
                const saleryInput = calculator.querySelector(".saleryinput");
                const inputbox = calculator.querySelector(".number-input");
                const maxValue = calculator.querySelector(".max-value");
                const minValue = calculator.querySelector(".min-value");
                const threeMonth = calculator.querySelector(".three");
                const sixMonth = calculator.querySelector(".six");
                const twelveMonth = calculator.querySelector(".twelve");
                const amount = calculator.querySelector("p.result");
                const radiobuttons = calculator.querySelectorAll("input[type='radio']");
                const myRequest = new Request(jsonSheet);
                const stepButton = calculator.querySelectorAll("button");
                const stepOne = calculator.querySelector(".step-one");
                const stepTwo = calculator.querySelector(".step-two");
                const stepThree = calculator.querySelector(".step-three");
                const inputName = calculator.querySelector(".name");
                const inputPhone = calculator.querySelector(".phone");
                const inputEmail = calculator.querySelector(".email");
                const callButton = calculator.querySelector(".contact-input button");
                const showReceipt = calculator.querySelector(".show-receipt");
                const showContactContainer = calculator.querySelector(".show-contact");
                const receipt = calculator.querySelector(".receipt");
                const minError = calculator.querySelector(".error-minimum");
                const maxError = calculator.querySelector(".error-max");
                const saleryLabel = calculator.querySelector(`[for='salery-${calcount}']`);
                const lowAmountValue = calculator.dataset.low_amount_value;

                let initialLoad = true;


                let isActive = false;

                //saleryInput.focus();

                inputbox.addEventListener("click", e => saleryInput.focus());

                saleryLabel.addEventListener("click", e => {
                    e.preventDefault();
                });

                radiobuttons.forEach(radio =>
                    radio.addEventListener("change", function () {
                        radiobuttons.forEach(rad => {
                            rad.removeAttribute("checked");
                        })
                        radio.setAttribute("checked", "checked");
                        radio.checked
                            ? (amount.innerHTML = new Intl.NumberFormat("da-dk").format(
                                radio.value
                            ))
                            : null;
                    })
                );

                saleryInput.addEventListener("focus", e => {
                    clearInput();
                    resetRadio();
                });

                infoboxes.forEach(button => {
                    button.addEventListener("click", e => {
                        openInfo(e, button.dataset.info, button);
                    });
                });

                slider.addEventListener("change", function () {
                    slidenumber.innerHTML = new Intl.NumberFormat("da-dk").format(
                        this.value
                    );

                    var securityPerCent = this.value - dagpenge;
                    security.innerHTML = new Intl.NumberFormat("da-dk").format(
                        Math.round(securityPerCent)
                    );
                });

                slider.addEventListener("mouseup", e => {
                    getPrice(slider.value);
                });

                slider.addEventListener("touchend", e => {
                    getPrice(slider.value);
                });

                stepButton.forEach(button => {
                    button.addEventListener("click", e => {
                        e.preventDefault();
                        var checkedRadio = calculator.querySelector(
                            "input[type='radio']:checked"
                        );
                        if (button.dataset.step == "one") {
                            openCalculationStep();
                        }
                        if (button.dataset.step === "two") {
                            openContactStep();
                            getSelectedPackage();
                        }
                        if (button.dataset.step === "three") {
                            openReceipt();
                        }
                    });
                });
                inputName.addEventListener("input", e => {
                    if (inputPhone.value.length > 0 && inputName.value.length > 0 && inputEmail.value.length > 0) {
                        callButton.disabled = false;
                    } else {
                        callButton.disabled = true;
                    }
                });

                inputPhone.addEventListener("input", e => {
                    if (inputName.value.length > 0 && inputPhone.value.length > 0 && inputEmail.value.length > 0) {
                        callButton.disabled = false;
                    } else {
                        callButton.disabled = true;
                    }
                });
                inputEmail.addEventListener("input", e => {
                    if (inputName.value.length > 0 && inputPhone.value.length && inputEmail.value.length > 0) {
                        callButton.disabled = false;
                    } else {
                        callButton.disabled = true;
                    }
                })

                inputPhone.oninput = handleTelephone;

                saleryInput.oninput = handleInput;

                function clearInput() {
                    saleryInput.value = null;
                    if (isActive) {
                        isActive = false;
                        showData.classList.remove("show");
                    }
                }
                function resetRadio() {
                    radiobuttons.forEach(radio => {
                        radio.checked = false;
                    });
                    radiobuttons[0].checked = true;
                }
                function getSelectedPackage() {
                    const selectionArea = calculator.querySelector(".my-selection");
                    const selectionHeader = calculator.querySelector(".my-selection-header");
                    const selectionLead = calculator.querySelector(".my-selection-lead");
                    const productName = calculator.querySelector(".calculator-name").textContent;
                    const checkedRadio = calculator.querySelector(
                        "input[type='radio']:checked"
                    );
                    let duration = null;
                    switch (checkedRadio.id) {
                        case "three-" + calcount:
                            duration = 3;
                            break;
                        case "six-" + calcount:
                            duration = 6;
                            break;
                        case "twelve-" + calcount:
                            duration = 12;
                            break;
                        default:
                            duration = 6;
                    }

                    var salerysecurity = security.innerHTML;

                    var test = {
                        name: inputName.value,
                        phone: inputPhone.value,
                        contentid: calculatorID,
                        grosspay: saleryInput.value,
                        payout: slider.value,
                        security: salerysecurity,
                        payoutduration: duration,
                        price: amount.textContent,
                    };
                    /*selectionArea.innerHTML = `
            <div class="column-left">
                <div class="selection-row">
                    <p class="label">Din løn:</p>
                    <p>${test.grosspay} kr./md.</p>
                </div>
                <div class="selection-row">
                    <p class="label">Hvor meget ønsker du udbetalt brutto?</p>
                    <p>${Intl.NumberFormat("da-dk").format(test.payout)} kr./md.</p>
                </div>
                <div class="selection-row">
                    <p class="label">Din udbetaling består af:</p>
                    <p>${Intl.NumberFormat("da-dk").format(dagpenge)} kr./md. + lønsikring ${test.security} kr./md.</p>
                </div>
            </div>
            <div class="column-right">
                <div class="selectio-row">
                    <p class="label">Udbetalingsperiode:</p>
                    <p>${duration} mdr.</p>
                </div>
                <div class="selection-row">
                    <p class="label">Din pris:</p>
                    <p>${test.price} kr./md.</p>
                </div>
                <div class="selection-row">
                    <p class="label">Valgte produkt:</p>
                    <p>Lønsikring: <span class="product">${productName}</span></p>
                </div>
            </div>`*/
                    selectionHeader.innerHTML = `Du har valgt: <span class="product">${productName}</span>`;

                    selectionLead.innerHTML = `
                        <div class="row">
                            <div class="column">
                                Pris for lønsikring
                            </div>
                            <div class="column">
                                ${test.price} kr./md.
                            </div>
                        </div>
                    `;

                    selectionArea.innerHTML = `
                        <div class="inner">
                            <div class="row">
                                <div class="column">
                                    Din løn (før skat):
                                </div>
                                <div class="column">
                                    ${test.grosspay} kr./md.
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <b>Med Ase Lønsikring får du:</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    Dagpenge:
                                </div>
                                <div class="column">
                                    ${Intl.NumberFormat("da-dk").format(dagpenge)} kr./md.
                                </div>
                            </div>
                            <div class="row underlined">
                                <div class="column">
                                    Lønsikring:
                                </div>
                                <div class="column ">
                                    ${test.security} kr./md.
                                </div>
                            </div>
                            <div class="row underlined">
                                <div class="column">
                                    <b>I alt:</b>
                                </div>
                                <div class="column ">
                                    <b>${Intl.NumberFormat("da-dk").format(parseInt(dagpenge) + parseInt(test.security.replaceAll(".", "")))} kr./md.</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column padding-top">
                                    Du har valgt lønsikring i ${duration} mdr.
                                </div>
                            </div>
                        </div>
                            `
                }
                function postData() {
                    const productName = calculator.querySelector(".product-name").value;

                    showSpinnerReceipt.classList.add("show");
                    stepTwo.classList.remove("show");
                    stepThree.classList.add("show");
                    const checkedRadio = calculator.querySelector(
                        "input[type='radio']:checked"
                    );

                    let duration = null;
                    switch (checkedRadio.id) {
                        case "three-" + calcount:
                            duration = 3;
                            break;
                        case "six-" + calcount:
                            duration = 6;
                            break;
                        case "twelve-" + calcount:
                            duration = 12;
                            break;
                        default:
                            duration = 6;
                    }

                    var salerysecurity = security.innerHTML;

                    var test = {
                        name: inputName.value,
                        phone: inputPhone.value,
                        contentid: calculatorID,
                        grosspay: saleryInput.value,
                        email: inputEmail.value,
                        dinpris: amount.textContent,
                        payout: salerysecurity,
                        payoutduration: duration,
                        product: productName
                    };
                    //console.log("test:", test);
                    fetch("/umbraco/api/SalaryCalculatorApi/PostSalCalCampaign2021", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },

                        body: JSON.stringify({
                            name: inputName.value,
                            phone: inputPhone.value,
                            contentid: calculatorID,
                            grosspay: saleryInput.value,
                            email: inputEmail.value,
                            dinpris: amount.textContent,
                            payout: salerysecurity,
                            payoutduration: duration,
                            product: productName
                        })
                    }).then(response => {
                        window.scrollTo({ top: 0 });
                        scrollToTargetAdjusted(receipt);

                        function loadData() {
                            showSpinnerReceipt.classList.remove("show");
                            showReceipt.classList.add("show");
                        }
                        var timerId = setTimeout(loadData, 2000);
                    });
                }
                function openCalculationStep() {
                    stepTwo.classList.remove("show");
                    showContact.classList.remove("show");
                    stepOne.classList.add("show");
                }
                function openReceipt() {
                    postData();
                    const receiptPlaceholder = calculator.querySelector(".receipt-intro");
                    const cName = calculator.querySelector(".calculator-name").textContent;
                    const formatPhonenumber = inputPhone.value.replace(/.{1,2}(?=(.{2})+$)/g, '$& ');
                    receiptPlaceholder.innerHTML = receiptPlaceholder.innerHTML.replace('{phoneNumber}', formatPhonenumber);
                    receiptPlaceholder.innerHTML = receiptPlaceholder.innerHTML.replace('{productName}', `<span class="choice">${cName}</span>`);
                    stepThree.scrollIntoView({ behavior: "smooth", block: "end" });

                }

                function openContactStep() {
                    stepOne.classList.remove("show");
                    stepTwo.classList.add("show");
                    window.scrollTo({ top: 0 });
                    scrollToTargetAdjusted(showContactContainer);

                    showSpinnerContact.classList.add("show");

                    function loadData() {
                        showSpinnerContact.classList.remove("show");
                        showContact.classList.add("show");
                        inputName.focus();
                    }
                    var timerId = setTimeout(loadData, 2000);
                }

                function handleInput(e) {
                    if (isActive) {
                        isActive = false;
                        showData.classList.remove("show");
                    }

                    let salery = e.target.value.replace(/[^0-9]/g, "");

                    if (initialLoad) {
                        threeMonth.setAttribute("checked", "checked");
                        threeMonth.checked;
                    }

                    e.target.value = new Intl.NumberFormat("da-dk").format(
                        e.target.value.replace(/[^0-9]/g, "")
                    );
                    const suggestedPackage = calculator.querySelector(".suggested-package[data-setactive='0']");
                    if (salery > 10000 && salery < Number(lowAmountValue)) {
                        minError.classList.add("active");
                        if (selectedPackage == 1 || selectedPackage == 2) {
                            if (suggestedPackage) {
                                suggestedPackage.classList.add("active");
                                const suggestedTarget = suggestedPackage.dataset.setactive;
                                const suggestedCard = insuranceWheel.querySelector(`[data-card="${suggestedTarget}"]`);
                                suggestedPackage.addEventListener('click', () => {
                                    selectedPackage = 0;
                                    const targetcalc = insuranceCalcContainer.querySelector(`[data-calcount="${suggestedTarget}"]`);
                                    setTimeout(e => {
                                        glide.go('=0')
                                    }, 400)
                                    calculatorSection.forEach(calc => {
                                        calc.style.display = "none";
                                    })
                                    const existingLine = document.querySelector(".leader-line");
                                    if (existingLine) {
                                        existingLine.remove();
                                    }
                                    const icards = insuranceWheel.querySelectorAll(".insurance-card");
                                    icards.forEach(card => {
                                        card.classList.remove("active");
                                    })
                                    suggestedCard.classList.add("active");
                                    if (window.innerWidth > 991) {
                                        setTimeout(e => {
                                            drawLine = new LeaderLine(
                                                suggestedCard.querySelector("#point"),
                                                targetcalc.querySelector("#target"), {
                                                path: "fluid",
                                                color: "#d76f2c"
                                            }
                                            );
                                        }, 300)
                                    }
                                    const calcName = suggestedCard.querySelector("h2 span").textContent;
                                    targetcalc.style.display = "block";
                                    targetcalc.querySelector(".calculator-name").textContent = calcName;
                                    suggestedPackage.classList.remove("active");
                                    minError.classList.remove("active");

                                })
                            }
                        } else {
                            suggestedPackage.style.display = "none";
                        }
                    }

                    if (salery > 85999) {
                        maxError.classList.add("active");
                    }

                    if (salery < 86000) {
                        maxError.classList.remove("active");
                    }
                    if (salery > Number(lowAmountValue)) {
                        minError.classList.remove("active");
                        suggestedPackage.classList.remove("active");
                        setTimeout(function () {
                            getSalery(salery);
                            if (!isActive) {
                                isActive = true;
                                loadSpinne();
                            }
                        }, 700);
                    }
                }

                function handleTelephone(e) {
                    e.target.value = e.target.value.replace(/([^0-9|\+])/g, "");
                }

                function getSalery(goal) {
                    fetch(myRequest)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (data) {
                            var closest = data.reduce(function (prev, curr) {
                                return goal < curr.loen ? prev : curr;
                            });
                            setSlider(closest, data);
                        });
                }

                function getPrice(salery) {
                    const calculatorRadios = calc.querySelector(".calculator-radio");
                    const selectedRadio = calculatorRadios.querySelector("input[checked=checked]");
                    fetch(myRequest)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (dataset) {

                            var price = dataset.filter(data => data.dagpenge === Number(salery));

                            threeMonth.value = price[0].three_mnd;
                            sixMonth.value = price[0].six_mnd;
                            twelveMonth.value = price[0].twelve_mnd;

                            if (price[0].three_mnd == null) {
                                threeMonth.closest(".radio-group").style.display = "none";
                            }
                            if (price[0].six_mnd == null) {
                                sixMonth.closest(".radio-group").style.display = "none";
                            }
                            if (price[0].twelve_mnd == null) {
                                twelveMonth.closest(".radio-group").style.display = "none";
                            }
                            amount.innerHTML = new Intl.NumberFormat("da-dk").format(
                                selectedRadio.value
                            );
                        });
                }

                function setSlider(closest, data) {
                    let lastStep = data[data.length - 1];
                    let maxDagpenge = lastStep.dagpenge;
                    let minDagPenge = data[0].dagpenge;

                    let maxdagpenge =
                        closest.dagpenge <= maxDagpenge ? closest.dagpenge : maxDagpenge;

                    slider.max = maxdagpenge;
                    slider.min = minDagPenge;
                    slider.value = minDagPenge;
                    maxValue.innerHTML = new Intl.NumberFormat("da-dk").format(
                        maxdagpenge
                    );
                    minValue.innerHTML = new Intl.NumberFormat("da-dk").format(minDagPenge);
                    slidenumber.innerHTML = new Intl.NumberFormat("da-dk").format(
                        minDagPenge
                    );
                    security.innerHTML = new Intl.NumberFormat("da-dk").format(
                        minDagPenge - dagpenge
                    );
                    getPrice(minDagPenge);
                }

                function openInfo(e, popdata, button) {
                    const thisPop = calculator.querySelector(".info-text[data-info='" + popdata + "']");
                    const icon = button.querySelector(".icon");

                    if (thisPop) {
                        if (!thisPop.classList.contains("show")) {
                            thisPop.classList.add("show");
                            icon.classList.add("close");
                            icon.innerHTML = "x";
                        } else {
                            thisPop.classList.remove("show");
                            icon.classList.remove("close");
                            icon.innerHTML = "i";
                        }
                    }
                }
                function loadSpinne() {
                    showSpinner.classList.add("show");
                    saleryInput.blur();
                    function loadData() {
                        showSpinner.classList.remove("show");
                        showData.classList.add("show");
                        window.scrollTo({ top: 0 });
                        scrollToTargetAdjusted(showData);
                    }
                    var timerId = setTimeout(loadData, 2000);
                }

                function scrollToTargetAdjusted(element) {
                    var headerOffset = 80;
                    var elementPosition = element.getBoundingClientRect().top;

                    var offsetPosition = elementPosition - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                    window.scrollTo(0, offsetPosition);
                }
            })

        }
    }
}


