import styled from "styled-components";

export const Backdrop = styled.div`
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(50px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.36);
  background-color: white;
  border-radius: 5px;
  padding: var(--grid-gutter);
  max-width: 650px;
  text-transform: initial;
  margin: var(--grid-gutter);
  max-height: 80vh;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  background-color: var(--color-orange);

  &:hover {
    background-color: #c86728;
  }
`;
