import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    padding-left: 20px;
  }
`;

export const Wrapper = styled.button`
  height: 20px;
  min-width: 20px;
  width: 20px;
  padding: 0;
  border-radius: 50%;
  font-size: var(--font-size-large);
  border: 2px solid var(--color-coal);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-coal-5);
  }
`;

export const Inner = styled.div`
  height: 12px;
  min-width: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--color-coal);
`;

export const Label = styled.p`
  font-weight: 700;
  padding-left: 5px;
  margin: 0;
`;
