
import gsap, { Power3, Power1 } from 'gsap'
import ScrollMagic from '../../libs/scrollmagic/uncompressed/ScrollMagic'

export default class VimeoVideo {
    constructor(element) {
        this.video = element
        this.controller = new ScrollMagic.Controller({ loglevel: 2 });
        this.init()
    }

    init() {
        console.log("VIMEO")
        const elm = this.video
        const playButton = elm.querySelectorAll('.video-container__play');
        const videoID = elm.dataset.videoid;
        const videoWrapper = elm.querySelectorAll('.videoWrapper')[0];
        let youtubeVideo;


        playButton[0].onclick = () => {
            elm.className += " open";
            youtubeVideo = this.playVideo(elm, videoID, videoWrapper, youtubeVideo);
        };

        const videoSceen = new ScrollMagic.Scene({
            triggerElement: elm,
            duration: '80%'
        })
            .on('leave', function (event) {

            })
            .addTo(this.controller);
    }

    playVideo(videoParent, videoID, videoContainer, youtubeVideo) {
        if (videoContainer.classList.contains('activevideo')) {
            this.openVideo(videoParent);
            youtubeVideo.playVideo();
            return youtubeVideo;
        } else {
            let containerID = "vimeo-" + videoID

            var options = {
                id: videoID,
                width: 640,
                loop: true
            };

            var player = new Vimeo.Player(containerID, options);
            player.play()

            player.on('play', function () {
                console.log('played the video!');
            });

            var controller = new ScrollMagic.Controller();
            var videoStartStop_scene = new ScrollMagic.Scene({
                triggerElement: videoContainer,
                triggerHook: 0, duration: 200
            }).addTo(controller);

            videoStartStop_scene.on("leave", function (e) {
                console.log('e: ', e.scrollDirection);
                if (e.scrollDirection == "FORWARD")
                    player.pause();

            });


            videoContainer.className += " activevideo";
            this.openVideo(videoParent, videoContainer);

        }
    }

    openVideo(videoParent, videoWrapper) {
        const playButton = videoParent.querySelectorAll('.video-container__play')[0];
        const closeButton = videoParent.querySelectorAll('.video-container__close')[0];
        const videoPoster = videoParent.querySelectorAll('img')[0];
        let tl = new gsap.timeline();
        tl.set(videoWrapper, {
            autoAlpha: 0
        })
            .to(playButton, {
                duration: 0.2,
                autoAlpha: 0,
                ease: Power3.easeOut
            })
            .to(videoPoster, {
                duration: 0.4,
                autoAlpha: 0,
                ease: Power3.easeOut
            }, '-=0.2')
            .to(videoWrapper, {
                duration: 0.6,
                autoAlpha: 1,
                ease: Power1.easeIn
            })
            .to(closeButton, {
                duration: 0.3,
                autoAlpha: 0,
                ease: Power3.easeIn
            });
    }

    closeVideo(videoContainer) {
        var closeButton = videoParent.querySelectorAll('.video-container__close')[0],
            playButton = videoParent.querySelectorAll('.video-container__play')[0],
            videoPoster = videoParent.querySelectorAll('img')[0],
            videoWrapper = videoParent.querySelectorAll('.videoWrapper')[0],
            tl = gsap.timeline();

        tl
            .to(closeButton, 0.2, {
                autoAlpha: 0,
                ease: Power3.easeOut
            })
            .to(videoWrapper, 0.4, {
                autoAlpha: 0,
                ease: Power3.easeOut
            })
            .to(videoPoster, 0.6, {
                autoAlpha: 1,
                ease: Power3.easeIn
            })
            .to(playButton, 0.2, {
                autoAlpha: 1,
                ease: Power3.easeIn
            });

        if (videoContainer.hasClass('header')) {
            var headerTl = gsap.timeline(),
                breadcrumb = document.querySelector('.breadcrumb-container'),
                headline = document.querySelector('.header-container, .header-cta-banner');

            headerTl
                .to(breadcrumb, 0.2, {
                    autoAlpha: 1,
                    ease: Power3.easeIn
                })
                .to(headline, 0.2, {
                    autoAlpha: 1,
                    ease: Power3.easeIn
                }, '-=0.2');
        }
    }
}