import styled from "styled-components";

export const Wrapper = styled.div<{ height: number }>`
  transition: height 0.2s ease;
  will-transform: height;
  height: ${(p) => p.height}px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
`;
