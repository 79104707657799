import styled from "styled-components";

export const Wrapper = styled.div``;

export const Inner = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  max-width: var(--content-max-width);
  padding: 5rem var(--grid-gutter);
  margin: 0 auto;
  max-width: 824px;

  h1 {
    text-transform: initial;
  }

  h2 {
    margin: 0;
    text-transform: initial;
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
`;

export const FormItem = styled.div`
  border-bottom: 1px solid var(--color-petrol);
  padding-bottom: 30px;
`;

export const FormValidation = styled.div`
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const FormError = styled.div`
  color: var(--color-error);
  text-transform: initial;
  padding-top: 12px;
`;

export const FormAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
`;

export const FormActionButton = styled.button`
  width: 200px;
  padding: 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.75em 0;
`;

export const HeaderTitle = styled.h2`
  text-transform: initial;
  margin: 0;
`;

export const HeaderValidation = styled.span`
  display: none;
  margin-left: 5px;
  vertical-align: middle;

  @media (max-width: 600px) {
    display: inline-block;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const DeleteButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;

  &:hover {
    background-color: var(--color-petrol-25);
  }
`;

export const SubmitButton = styled.button`
  height: 51px;
`;
