import { ConsentCheck, Actions, ConsentWrapper, Description } from "./ConsentScreen.styles";
import { useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useStore } from "../../../../../../store/store";
import { UnionFlowRouteEnum } from "../../../../../../types/UnionFlowRouteEnum";
import AnimatedSlideContainer, { AnimatedSlidePosition } from "../../../../../../components/AnimatedSlideContainer";
import Button from "../../../../../../components/Button";
import Analytics from "../../../../../../utils/analytics";
import { PayloadModel } from "../../../../../../types/PayloadModel";
import { TrackingFlowTypeEnum } from "../../../../../../types/TrackingFlowTypeEnum";

interface UnionMemberScreenProps {
  onHeightChange: (height: number) => void;
}

export default function ConsentScreen({ onHeightChange }: UnionMemberScreenProps) {
  const route = useStore.useUnionFlowRoute();
  const setUnionFlowVisible = useStore.useSetUnionFlowVisible();
  const setUnionFlowRoute = useStore.useSetUnionFlowRoute();
  const setUnionFlowPayload = useStore.useSetUnionFlowPayload();
  const payload = useStore.useUnionFlowPayload();
  const [productConsent, setProductConsent] = useState(false);
  const [partnerConsent, setPartnerConsent] = useState(false);

  const animationPosition = useMemo(() => {
    switch (route) {
      case UnionFlowRouteEnum.CONSENT:
        return AnimatedSlidePosition.MIDDLE;
      case UnionFlowRouteEnum.CONFIRM:
        return AnimatedSlidePosition.LEFT;
      case UnionFlowRouteEnum.MEMBER:
        return AnimatedSlidePosition.RIGHT;
      default:
        return AnimatedSlidePosition.RIGHT_NO_ANIMATION;
    }
  }, [route]);

  const onNext = () => {
    const consent = {
      consents: {
        union: {
          aseCooperation: (payload.wantsFamilyUnion || payload.wantsUnion) && productConsent,
          member: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
          partner: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
        },
        unemploymentBenefits: {
          aseCooperation: (payload.wantsFamilyUnion || payload.wantsUnion) && productConsent,
          member: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
          partner: (payload.wantsFamilyUnion || payload.wantsUnion) && partnerConsent,
        },
      },
    };
    setUnionFlowPayload(consent);

    Analytics.getInstance().trackAllConsent("added", consent.consents, TrackingFlowTypeEnum.TrackAndTrace);
    setUnionFlowRoute(UnionFlowRouteEnum.CONFIRM);
  };

  return (
    <AnimatedSlideContainer onHeightChange={onHeightChange} position={animationPosition} onClose={() => setUnionFlowVisible(false)} onBack={() => setUnionFlowRoute(UnionFlowRouteEnum.MEMBER)} title="Få mere ud af din fagforening">
      <Description>
        Du kan til enhver tid trække dit samtykke tilbage på MitAse eller ved at kontakte os via e-mail: <a href="mailto:ase@ase.dk">ase@ase.dk</a>
      </Description>
      <ConsentWrapper style={{ marginBottom: 20 }} onClick={() => setProductConsent(!productConsent)}>
        <ConsentCheck active={productConsent}>{productConsent && <FaCheck color="white" />}</ConsentCheck>
        <p>
          For at hjælpe mig bedst muligt, accepterer jeg, at fagforeningen må dele oplysninger om mit medlemskab med a-kasse - hvis jeg har begge hos Ase.{" "}
          <a href="https://www.ase.dk/cookie-privatliv-og-persondata" style={{ fontWeight: 700, textDecoration: "underline" }}>
            Læs mere her.
          </a>
        </p>
      </ConsentWrapper>
      <ConsentWrapper onClick={() => setPartnerConsent(!partnerConsent)}>
        <ConsentCheck active={partnerConsent}>{partnerConsent && <FaCheck color="white" />}</ConsentCheck>
        <p>
          Jeg accepterer at blive kontaktet om nyheder og medlemstilbud fra Ase og vores samartbejdspartnere. Se listen over vores samarbejdspartnere her. Det vil altid være Ase, der kontakter mig.
          <a style={{ fontWeight: 700, textDecoration: "underline" }} href="https://www.ase.dk/cookie-privatliv-og-persondata">
            Læs mere her.
          </a>
        </p>
      </ConsentWrapper>
      <Actions>
        <Button onClick={onNext}>Fortsæt</Button>
      </Actions>
    </AnimatedSlideContainer>
  );
}
