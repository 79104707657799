import 'regenerator-runtime'
import './index.css'
import { EventBus, EventTypes } from './consts/const-event-types'

/*
To add new components, import them here and add them to the 'components'-array below.
Add an appropriate selectorName for use in DOM (fx: <div data-component="header"></div>):
*/
import App from './components/app'
import Header from './components/header/header'
import BenefitModule from './components/benefit-module/benefit-module'
import SituationLink from './components/situation-link-module/situation-link-module'
import Subnavigation from './components/subnavigation/subnavigation'
import AccordionModule from './components/accordion-module/accordion-module'
import EqualHeight from './components/equal-height/equal-height'
import YoutubeVideo from './components/video-module/youtube-video'
import VimeoVideo from './components/video-module/vimeo-video'
import InspirationList from './components/inspiration-list/inspiration-list'
import NewsletterContainer from './components/newsletter-container/newsletter-container'
import SalaryModule from './components/salary-module/salary-module'
import inView from './libs/inview'
import imageLazy from './libs/image-lazy'
import SalaryCalculatorModule from './components/salary-calculator-module/salary-calculator-module'
import SalaryInsuranceCalculatorModule from './components/salary-insurance/salary-insurance'
import BenefitLinkListModule from './components/benefit-linklist-filter/benefit-linklist-filter'
import FormSlider from './components/form-slider/form-slider'
import FormAdtraction from './components/form-adtraction/form-adtraction'
import Help from "./components/help/help"
import EnrollmentForm from './components/enrollment-form/enrollment-module'
import ProductCards from './components/product-cards/product-cards'
import * as Sentry from "@sentry/react";

const env = window.document.body.dataset.environment

Sentry.init({
  dsn: "https://5cc071ddbcaf19190ed81014712b3c74@o4505316924784640.ingest.sentry.io/4506041914621952",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: env,
  enabled: env !== "local"
});

const components = [
  { Class: App, selectorName: 'app' },
  { Class: Header, selectorName: 'header' },
  { Class: BenefitModule, selectorName: 'benefit-module' },
  { Class: SituationLink, selectorName: 'situation-list-more' },
  { Class: Subnavigation, selectorName: 'subnavigation' },
  { Class: AccordionModule, selectorName: 'accordion-module' },
  { Class: EqualHeight, selectorName: 'equal-height' },
  { Class: YoutubeVideo, selectorName: 'youtube-video' },
  { Class: VimeoVideo, selectorName: 'vimeo-video' },
  { Class: InspirationList, selectorName: 'inspiration-list' },
  { Class: NewsletterContainer, selectorName: 'newsletter-container' },
  { Class: SalaryModule, selectorName: 'salary' },
  { Class: SalaryCalculatorModule, selectorName: 'salary-calculator' },
  { Class: SalaryInsuranceCalculatorModule, selectorName: 'salary-insurance' },
  { Class: BenefitLinkListModule, selectorName: 'benefit-linklist-filter' },
  { Class: FormSlider, selectorName: 'form-slider' },
  { Class: FormAdtraction, selectorName: 'form-adtraction' },
  { Class: Help, selectorName: 'help' },
  { Class: EnrollmentForm, selectorName: 'enrollment-form' },
  { Class: ProductCards, selectorName: 'product-cards' }
]

/*
* ComponentInstantiator traverses the DOM-tree and looks for [data-component='] attributes.
*/
class ComponentInstantiator {
  constructor() {
    this.createComponents()
    this.dispatchReady()
    inView()
    imageLazy.init()
    return this
  }

  createComponents() {
    this.componentsInstantiated = {}

    components.map(component => {
      const nodes = []
      // select all DOM-nodes with the data-component attribute:
      const nodeItems = [...document.querySelectorAll("[data-component='" + component.selectorName + "']")]

      nodeItems.map(nodeItem => {
        // instantiate component with nodeItem as param:
        nodes.push(new component.Class(nodeItem))
      })

      // add to componentsList object:
      if (nodes.length) this.componentsInstantiated[component.selectorName] = nodes
    })
  }

  dispatchReady() {
    const event = new CustomEvent(EventTypes.ALL_COMPONENTS_READY)
    EventBus.dispatchEvent(event)
  }

  getComponents(selectorName) {
    return selectorName ? this.componentsInstantiated[selectorName] : this.componentsInstantiated
  }
}

/* Make your App accessible via window.App */
window.App = new ComponentInstantiator()

/* Access components list */
// console.log('components on this page', window.App.getComponents())
