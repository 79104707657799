import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  height: 50vh;
`;

export const Header = styled.div`
  width: 100%;
  padding: 36px;
  border-radius: 16px;
  background-color: #f6f1ee;
  color: #4f4a45;
`;

export const HeaderTitle = styled.h2`
  padding-bottom: 1rem;
  font-size: 2rem;
  color: #4f4a45;
  text-transform: initial;
`;

export const ErrorTitle = styled.h2`
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--color-error);
  text-transform: initial;
  text-align: center;
`;

export const HeaderSubtitle = styled.p``;

export const TraceWrapper = styled.div`
  width: 100%;
  padding: 50px 40px;
`;
