import styled from "styled-components";

export const Wrapper = styled.div<{ isCentered: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 77px;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isCentered }) =>
    isCentered &&
    `
  top: calc(50% - 38.5px);
    right: calc(50% - 38.5px);
  `}

  svg {
    animation: ASERotating 0.8s linear infinite;
  }
`;
