import styled from "styled-components";

export const SalaryInsurance = styled.div`
  display: flex;

  svg {
    margin-right: 8px;
  }
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  width: 100%;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Subtitle = styled.h3`
  text-transform: initial;
  margin: 10px 0;
`;
