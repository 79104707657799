import { gsap } from 'gsap'

export default class Subnavigation {
  constructor(element) {
    this.el = element
    this.init()

  }

  init() {
    console.log("gsap:", gsap);
    let isTweenRunning = false;

    const elm = this.el;

    if (!elm.querySelectorAll(".column").length) {
      elm.style.display = "none";
    }

    function startAnimation() {

      if (isTweenRunning == false) {
        isTweenRunning = true;

        var subnavigationLinks = elm.querySelectorAll(".column");

        gsap.to(subnavigationLinks, {
          duration: 0.7, delay: 0.2, top: 0, opacity: 1, ease: "power2".easeOut, force3D: true, onComplete: () => {
            isTweenRunning = false;
          },
        }, 0.075)
      }
    }


    startAnimation();
  }
}