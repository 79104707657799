import { animated } from "@react-spring/web";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

export const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 36px;
  }
`;

export const Label = styled.label`
  color: var(--color-coal-75);
  text-transform: initial;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const TextInputWrapper = styled.div`
  position: relative;
`;

export const TextInput = styled.input`
  height: 50px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 2px solid var(--color-coal-25);
  padding: 12px;
  margin-bottom: 16px;

  &.error {
    border: 2px solid var(--color-error);
  }
`;

export const Title = styled.p`
  text-transform: initial;
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
`;

export const ValidIcon = styled(FaCheck)`
  position: absolute;
  right: 14px;
  top: 17px;
  color: var(--color-green);
`;

export const Summary = styled.div`
  padding: var(--grid-gutter);
  border-radius: 5px;
  background-color: var(--color-coal-5);
  margin-bottom: var(--grid-gutter);
`;

export const SummaryEntry = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  ${(p) =>
    p.withBorder &&
    `
    border-bottom: 1px solid black; 
    border-top: 1px solid black; 
  `}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SummaryValue = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-coal-25);
  height: 38px;
  width: 80px;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--color-coal-5);
  }
`;
