import React from "react";
import RadioButton from "../../../../components/RadioButton";
import StepContainer from "../../../../components/StepContainer";
import StepRoutes from "../../../../constants/Routes";
import { useStore } from "../../../../store/store";
import { AseButtonActions, AseFieldset } from "../../../../utils/global.styles";

export default function ReceivingUnemploymentBenefitsStep() {
  const t = useStore.useTranslations();
  const setPayload = useStore.useSetPayload();
  const payload = useStore.usePayload();
  const setRouteStack = useStore.useSetRouteStack();

  return (
    <StepContainer
      stepTitle={t?.receivingUnemploymentBenefitsStep.title}
      progress={0.4}
      buttonText={t?.receivingUnemploymentBenefitsStep.buttonLabel}
      subtitle="Velkommen til familiefagforeningen!"
      helpText={t?.receivingUnemploymentBenefitsStep.helpText}
      onNext={() => {
        if (payload.areYouCurrentlyReceivingUnemploymentBenefits) {
          setRouteStack(StepRoutes.ConsentStep);
        } else {
          setRouteStack(StepRoutes.NeedToContactYouStep);
        }
      }}
      nextDisabled={payload.areYouCurrentlyReceivingUnemploymentBenefits === undefined}
    >
      <AseFieldset>
        <AseButtonActions>
          <RadioButton onChange={() => setPayload({ areYouCurrentlyReceivingUnemploymentBenefits: true })} id="receiving_unemployment_support_yes" checked={payload.areYouCurrentlyReceivingUnemploymentBenefits === undefined ? false : payload.areYouCurrentlyReceivingUnemploymentBenefits}>
            {t?.receivingUnemploymentBenefitsStep.answerYes}
          </RadioButton>
          <RadioButton
            onChange={() =>
              setPayload({
                areYouCurrentlyReceivingUnemploymentBenefits: false,
              })
            }
            id="receiving_unemployment_support_no"
            checked={payload.areYouCurrentlyReceivingUnemploymentBenefits === undefined ? false : !payload.areYouCurrentlyReceivingUnemploymentBenefits}
          >
            {t?.receivingUnemploymentBenefitsStep.answerNo}
          </RadioButton>
        </AseButtonActions>
        <p>{t?.receivingUnemploymentBenefitsStep.description}</p>
      </AseFieldset>
    </StepContainer>
  );
}
