import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const CheckboxInput = styled.input<{ isTransparent: boolean }>`
  position: absolute;
  appearance: none;
  border: none;
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;

  &:checked + label {
    border: 5px solid var(--color-petrol);

    &:before {
      background-color: var(--color-petrol);
      box-shadow: 0px 0px 4px 3px rgba(0, 142, 159, 0.25);
    }
  }
  &:focus + label {
    border: 5px solid var(--color-petrol-40);
  }

  ${({ isTransparent }) =>
    isTransparent &&
    `
    &:checked + label {
        border: none;
    }
    &:focus + label {
        border: none;
    }
  `}
`;

export const Label = styled.label<{ offerEnabled: boolean; isTransparent: boolean; isOutlined: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  color: var(--color-black);
  width: 100%;
  font-size: var(--font-size-xlarge);
  cursor: pointer;
  padding: 1.4rem;
  padding-left: 5.2rem;
  padding-right: 3rem;
  position: relative;
  border: 5px solid var(--color-white);
  transition: border-color 0.3s ease;

  a {
    text-decoration: underline;
  }

  &:before {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    top: 1.2rem;
    left: 1.4rem;
    border-radius: 3px;
    border: 3px solid var(--color-petrol);
    background-color: var(--color-white);
    transition: border-color 0.3s ease;
  }

  &:after {
    content: "";
    position: absolute;
    top: 24px;
    left: 35px;
    height: 20px;
    width: 11px;
    transform: rotate(45deg);
    border-bottom: 4px solid var(--color-white);
    border-right: 4px solid var(--color-white);
  }

  ${({ offerEnabled }) =>
    offerEnabled &&
    `
        padding-top: calc(1.4rem + 28px);

        &:before {
            top: calc(1.2rem + 28px);
        }

        &:after {
            top: 52px;
        }
    `}

  ${({ isTransparent }) =>
    isTransparent &&
    `
    border: none;
    background-color: transparent;
  `}

  ${({ isOutlined }) =>
    isOutlined &&
    `
    background-color: transparent;
`}
`;

export const NotificationBar = styled.div`
  height: 28px;
  position: absolute;
  background-color: var(--color-orange);
  top: 0;
  left: 0;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-size: 14px;
  padding: 0 1.4rem;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  text-transform: initial;
`;

export const Row = styled.span`
  display: flex;
  justify-content: space-between;
  text-transform: initial;

  &:first-child {
    padding-bottom: 0.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const RowDescription = styled.span`
  font-size: 14px;

  a {
    color: var(--color-petrol);
  }
`;

export const Help = styled.span`
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    height: 22px;
    min-width: 22px;
    padding: 0;
    width: 22px;
    transform: scale(1);
    transition: transform 0.2s, background-color 0.2s;
    border: 1px solid var(--color-black);
    font-size: var(--font-size-body);
    color: var(--color-black);
    font-weight: 700;

    &:hover {
      transform: scale(1.1);
    }

    &:focus {
      border-width: 3px;
    }
  }
`;

export const OfferText = styled.p`
  font-size: 14px;
  margin: 0;
  padding-top: 0.6rem;
  color: var(--color-orange);
  font-weight: 700;
`;
